import React from 'react';

export default class YoutubeThumbnail extends React.Component {
  render() {
    return (
      <div className='p-box-16by9'>
        <img className='p-box-ch' src={`https://img.youtube.com/vi/${this.props.videoId}/0.jpg`}
             style={{objectFit: 'cover'}} alt='youtube thumbnail'/>
      </div>
    );
  }
}
