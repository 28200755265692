import React, {Component} from "react";
import YoutubeCard from "../Youtube/youtube.card";
import {Link} from "react-router-dom";

export default class ChannelVideo extends Component {
  render() {
    return (
      <>
        {
          this.props.videos.length === 0 && <p className='mv-7 color-gray has-text-centered'>등록된 영상이 없습니다.</p>
        }
        <div className='auto-grid pt-3' style={{padding: 0}}>
          {
            this.props.videos.map((video, idx) => (
              <Link to={`/detail?v=${video.uuid}`}>
                <YoutubeCard key={idx} video={video}/>
              </Link>
            ))
          }
        </div>
      </>
    )
  }
}