import React, {Component} from "react";
import {Container, Tab} from "semantic-ui-react";
import {CitizenList} from "./tabs/citizen.list";
import axios from "axios";

class CitizenPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: []
    };
    this.getTypeList()
  }

  async getTypeList() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/type/all`, {withCredentials: true});
      const panes = [
        {
          menuItem: '전체',
          render: () => <CitizenList/>,
        },
      ]

      for (const type of res.data) {
        panes.push({
          menuItem: type.title,
          render: () => <CitizenList type={type.uuid}/>,
        })
      }

      this.setState({
        panes: panes
      })
    } catch (e) {
      alert("문제가 발생했습니다. 잠시 뒤 다시 시도하거나 개발자에게 문의하세요.");
    }
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          <h1 className>기업 시민 활동</h1>
          <Tab menu={{color: 'blue', secondary: true}} panes={this.state.panes}/>
        </Container>
      </div>
    )
  }
}

export default CitizenPage;
