import {
  Card,
  Divider,
  Placeholder,
  Pagination,
  Modal,
  Image,
  Header,
  Button,
  Icon,
} from "semantic-ui-react";
import { Component, createRef, React } from "react";
import axios from "axios";
import moment from "moment";
import NoticeItemDetail from "./notice.item.detail";
import NoticeModal from "./notice.modal";

export default class NoticeCard extends Component {
  total = 0;

  constructor(props) {
    super(props);
    this.child = createRef();
    this.state = {
      isLoading: true,
      board: [],
      activePage: 1,
      activeItem: false,
      clickPost: { notice_types: [], notice_keywords: []},
    };
  }

  getData() {
    this.setState({ activeItem: false, isLoading: true });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/board/notice?type=${
          this.props.type
        }&skip=${(this.state.activePage - 1) * this.props.itemNum}&take=${
          this.props.itemNum
        }`
      )
      .then((res) => {
        setTimeout(
          function () {
            //Start the timer
            console.log("board", res.data);

            for (const [idx, notice] of res.data.entries()) {
              const newSubtypes = notice.notice_subtypes.length ? notice.notice_subtypes.map((type) => {
                return `${type.name}`;
              }) : null

              res.data[idx].notice_subtypes = newSubtypes;
              
            }
            console.log("after preprocess", res.data);
            this.setState({
              isLoading: false,
              board: res.data,
            });
          }.bind(this),
          500
        );
      });
  }

  getCount() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/board/notice/${this.props.type}/item_num`
      )
      .then((res) => {
        this.total = parseInt(res.data);
        this.getData(this.props.type);
      });
  }

  componentDidMount() {
    this.getCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.total = 0;
      this.state.activePage = 1;
      this.getCount();
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.state.activePage = activePage;
    this.getData();
  };

  handleModalData = (post) => {
    this.child.current.handleGetData(post.uuid);
  };

  render() {
    return (
      <div>
        <NoticeModal ref={this.child} notice={this.state.clickPost}>
          {(this.state.activeItem = false)}
        </NoticeModal>

        <div className="p-card">
          {!this.state.isLoading ? (
            <div>
              {this.state.board.map((post, idx) => (
                <div>
                  <div
                    className="hover-gray pv-5 ph-6"
                    style={{minHeight: "103px", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ activeItem: true, clickPost: post });
                      return this.handleModalData(post);
                    }}
                  >
                    {post.title}
                    <div className="color-gray fs-m1 mt-1">
                      {moment(post.created_at).format("YYYY년 MM월 DD일 HH:mm")}
                    </div>
                    <div className="fs-m1 mt-1">
                      <Icon className='color-main mr-2' name="tags"/>
                      {console.log("post", post)}
                      {post.notice_types
                        ? post.notice_types.map((label) => {
                            return <span className="ui label fs-m1 mt-2">{label}</span>;
                          })
                        : null}
                      {post.notice_subtypes
                        ? post.notice_subtypes.map((label) => {
                            return <span className="ui label fs-m1 mt-1">{label}</span>;
                          })
                        : null}
                      {post.notice_keywords
                        ? post.notice_keywords.map((label) => {
                            return <span className="ui label fs-m1 mt-1">{label}</span>;
                          })
                        : null}
                        {!(post.notice_types.length || post.notice_subtypes || post.notice_keywords.length) && <span className="ui label fs-m1 mt-1">전체</span>}
                    </div>
                  </div>

                  <Divider fitted style={{ borderBottom: "none" }} />
                </div>
              ))}

              <div className="has-item-centered">
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={this.state.activePage}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={2}
                  totalPages={parseInt(
                    (this.total - 1) / this.props.itemNum + 1
                  )}
                  pointing
                  secondary
                  onPageChange={this.handlePaginationChange}
                />
              </div>
            </div>
          ) : (
            <div>
              {[...Array(Math.min(this.total - ((this.state.activePage-1) * this.props.itemNum), this.props.itemNum))].map((n, index) => {
                return (
                  <div>
                    <div className="pv-6 ph-6" style={{ height: "103px" }}>
                      <Placeholder>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </div>
                    <Divider fitted style={{ borderBottom: "none" }} />
                    {/*{ index !== this.props.itemNum - 1 ? <Divider fitted style={{ borderBottom: "none" }} /> : null }*/}
                  </div>
                );
              })}
              <div
                style={{ height: 40 }}
                className="has-item-centered is-vcentered is-flex"
              >
                <span>⟨</span>
                <Placeholder
                  style={{ height: 20, width: 20 }}
                  className="ml-7 mr-5"
                >
                  <Placeholder.Image />
                </Placeholder>
                <span> </span>
                <Placeholder style={{ height: 20, width: 20 }} className="mr-7">
                  <Placeholder.Image />
                </Placeholder>
                <span>⟩</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
