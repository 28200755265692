import React, {Component} from "react";
import axios from "axios";
import {Container, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import YoutubeCard from "../Youtube/youtube.card";
import UserContext from "../UserContext";

class VideoLikes extends Component {
  state = {videos: []}
  static contextType = UserContext;

  async componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/like/video`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      this.setState({
        videos: res.data
      })
    });
  }

  render() {
    const user = this.context;

    return (
      <div className='p-container'>
        <Container>
          <h1><Icon className='mr-5' name='thumbs up'/>좋아요 누른 영상</h1>
          {
            user ? null : <p className='color-gray pl-1 pt-3'>로그인 후 이용 가능</p>
          }
          <div className='auto-grid mt-7'>
            {this.state.videos.map((video, idx) => (
              <Link to={`/detail?v=${video.video.uuid}`}>
                <YoutubeCard video={video.video}/>
              </Link>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default VideoLikes