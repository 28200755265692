import React, {Component} from "react";
import {Container, Grid, Icon} from "semantic-ui-react";
import axios from "axios";
// import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Viewer} from '@toast-ui/react-editor';
import './viewer.css';
import ProgramYoutubeCard from "../program.youtube.card";

class ProgramPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      isChatOpen: false,
      video: []
    }
  }

  async componentDidMount() {
    try {
      // 소개
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`, {withCredentials: true})
      const existProgram = response.data
      this.setState({
        isLoading: false,
        name: existProgram.title,
        intro: existProgram.intro,
        website: existProgram.website_url,
        tel: JSON.parse(existProgram.contact_json).tel,
        email: JSON.parse(existProgram.contact_json).email,
        // gitpleId: JSON.parse(existProgram.contact_json).gitple_id,
        group_uuid: JSON.parse(existProgram.contact_json).group_uuid,
      })
      // 홍보 영상
      const video = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}/video`, {withCredentials: true})
      this.setState({
        video: video.data
      })
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  // gitple = () => {
  //   window.GitpleConfig = {
  //     appCode: this.state.gitpleId
  //   }
  //   window.Gitple('update');
  //   window.Gitple('open')
  //
  //   window.Gitple('onClose', function () {
  //     console.log('onClose event');
  //     window.Gitple('hide');
  //   });
  // }

  openChat = () => {
    window.open(`/chat/group/${this.state.group_uuid}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          <h1 className>{this.state.name}</h1>
          {
            (!this.state.isLoading) ?
              <div className='mt-8'>
                <Viewer
                  referenceDefinition={true}
                  initialValue={this.state.intro}
                />
                {
                  this.state.website ?
                    <div>
                      <h3 className='mt-8'>홈페이지</h3>
                      <a href={this.state.website}>
                        <div className='p-card pd-1 hover-zoom'>
                          <p style={{color: '#000000DE'}}><span style={{color: '#005386'}}><Icon
                            name='globe'/></span> {this.state.website}</p>
                        </div>
                      </a>
                    </div>
                    : null
                }
                <h3 className='mt-8'>담당자 연락처</h3>
                <div className='p-card is-flex is-vcentered'>
                  <div className='pd-1'>
                    <p><span style={{color: '#005386'}}><Icon name='phone'/></span> {this.state.tel}</p>
                    <p><span style={{color: '#005386'}}><Icon name='mail'/></span> {this.state.email}</p>
                  </div>
                  {
                    this.state.group_uuid ?
                      <div className='pd-1' style={{marginLeft: 'auto', flexShrink: 0}}>
                        <div className='p-chat-btn' onClick={this.openChat}><Icon name='commenting'/></div>
                      </div>
                      : null
                  }
                </div>
              </div>
              : null
          }

          {
            this.state.video.length > 0 &&
              <>
                <h3 className='mt-8'>영상</h3>
                <Grid doubling columns={4}>
                  {
                    this.state.video.map((v, idx) =>
                      <Grid.Column key={idx}>
                        <ProgramYoutubeCard video={v}/>
                      </Grid.Column>
                    )
                  }
                </Grid>
              </>
          }
        </Container>
      </div>
    )
  }
}

export default ProgramPage;
