import {Component} from "react";
import SurveyCard from './widget/survey.card';

export default class Survey extends Component {
  render() {
    return (
      <div className='mt-6'>
        <h2 className='mt-4'> 설문조사 </h2>
        {/* <div className='p-card full-h full-w is-col' style={{cursor: 'pointer'}} > */}
        <SurveyCard itemNum={5}/>
      </div>
      // </div>
    );
  }
}
