import React, {Component} from 'react'
import {Modal, Button, Icon} from "semantic-ui-react";
import {Viewer} from "@toast-ui/react-editor";
import moment from "moment";

export default class EtcModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.state.open = true;
  }

  render() {
    return (
      <Modal
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={this.state.open}
      >
        <Modal.Header>
            {this.props.notice.title}
          <div className='color-gray fs-m3 fw-n mt-2'>{moment(this.props.notice.created_at).format("YYYY년 MM월 DD일 HH:mm")}</div>
          <p className='fs-m3 color-main fw-n mt-2'><Icon name='user'/> {this.props.notice.author}</p>
          
        </Modal.Header>
        <Modal.Content scrolling>
          <Viewer
            referenceDefinition={true}
            initialValue={this.props.notice.content}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({open: false})}>
            확인
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
