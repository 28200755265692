import React, {Component} from "react";
import {Divider, Grid, Icon, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import RoundProfile from "../utills/profile";
import moment from "moment";
import InvestMatchedCard from "./widgets/invest.matched.card";
import InvestRequestedCard from "./widgets/invest.requested.card";

export default class VCMyInvestTab extends Component {
  state = {isLoaded: false, matched: [], requested: []}

  async componentDidMount() {
    try {
      const matched = await axios.get(`${process.env.REACT_APP_API_URL}/invest/matched/needs`, {withCredentials: true});
      const requested = await axios.get(`${process.env.REACT_APP_API_URL}/invest/requested/needs`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        matched: matched.data,
        requested: requested.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <h3 className='mt-7'>매칭된 투자 니즈</h3>
        {
          this.state.isLoaded && this.state.matched.length === 0 && <p className='color-gray pd-1'>매칭된 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.matched.map((matched) =>
              <Grid.Column>
                <InvestMatchedCard matched={matched}/>
              </Grid.Column>
            )
          }
        </Grid>

        <h3 className='mt-7'>요청한 투자 니즈</h3>
        {
          this.state.isLoaded && this.state.requested.length === 0 && <p className='color-gray pd-1'>요청한 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.requested.map((requested) =>
              <Grid.Column>
                <InvestRequestedCard requested={requested}/>
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    );
  }
}