import React, {Component} from 'react'
import {displayedAt} from "../utills/date";
import RoundProfile from "../utills/profile";

export default class RoomCard extends Component {
  openChat = () => {
    window.open(`/chat/group/${this.props.room.room.group.uuid}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  render() {
    return (
      <div onClick={this.openChat}>
        <div className='p-card pd-1 mb-5 hover-zoom pointer'>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <RoundProfile size={80} src={this.props.room.room.group.profile_url}/>
            <div className='ml-5' style={{flexGrow: '1'}}>
              <p className='fs-1 fw-b mb-1 color-black'>{this.props.room.room.group.name}</p>
              <p className='mb-1 color-black text-ellipsis2L' style={{whiteSpace: 'pre-wrap'}}>{this.props.room.last_chat.body}</p>
              <p className='color-gray'
                 style={{textAlign: 'right'}}>{displayedAt(this.props.room.last_chat.created_at)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}