import React, {Component} from "react";
import {Icon} from 'semantic-ui-react'
import YoutubePlayer from "./youtube-player";
import moment from "moment";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import ArchiveModal from "../VideoInfo/archive.modal";
import UserContext from "../UserContext";

export default class YoutubeDetail extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      like_num: this.props.video.like_num,
      openArchive: false,
    }
    this.handleArchive = this.handleArchive.bind(this)
  }

  async componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/like/video/${this.props.video.uuid}`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      if (res.data) this.setState({isLiked: true});
    });
  }

  like = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/like/video`, {uuid: this.props.video.uuid}, {withCredentials: true}).then((res) => {
      console.log(res);
      if (res.data === "Liked") {
        this.setState({
          isLiked: true,
          like_num: this.state.like_num + 1
        })
      } else {
        this.setState({
          isLiked: false,
          like_num: this.state.like_num - 1
        })
      }
    }).catch((e) => {
      console.log(e);
      alert("로그인 후 이용 가능합니다.");
    })
  }

  handleArchive(isOpen) {
    this.setState({openArchive:isOpen})
  }

  render() {
    const user = this.context;

    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <ArchiveModal open={this.state.openArchive} handleModal={this.handleArchive} videoUuid={this.props.video.uuid}/>

        <div style={{width: '100%'}} className='p-card'>
          <YoutubePlayer videoId={this.props.video.youtube_id}/>
          <div className='has-text-centered pd-1' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            <span className={`hover-gray mr-1 no-drag ${this.state.isLiked ? 'color-main' : 'color-gray'}`}
                  style={{borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}} onClick={this.like}>
              <Icon name='thumbs up'/> {this.state.like_num}
            </span>
            <span className='color-gray hover-gray mr-1 no-drag' onClick={() => {
              if (user) {
                this.setState({openArchive:true})
              } else {
                alert("로그인 후 이용 가능합니다.");
              }
            }}
                  style={{borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}}>
              <Icon name='archive'/> 보관
            </span>
            <CopyToClipboard text={window.location.href} onCopy={() => alert('URL 주소가 복사 되었습니다!')}>
              <span className='color-gray hover-gray mr-1 no-drag'
                    style={{borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}}>
                <Icon name='share'/> 공유
              </span>
            </CopyToClipboard>
            <span className='color-gray hover-gray no-drag' onClick={() => alert("준비 중 입니다...😢")}
                  style={{borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}}>
              <Icon name='horizontal ellipsis'/> 더보기
            </span>
          </div>
        </div>

        <div className='mt-5 ph-5 is-flex' style={{flexWrap: 'wrap'}}>
          {
            this.props.video.tags.map((tag, i) => {
              return (<a style={{color: '#005386', marginRight: '0.5rem'}} href={`/search?search_query=${window.encodeURIComponent(tag.name)}`} key={i}>#{tag.name}</a>);
            })
          }
        </div>
        <div className='ph-5'>
          <h2 className='mt-0 mb-0'>{this.props.video.title}</h2>
          <p className='color-gray'>{moment(this.props.video.created_at).format('YYYY.MM.DD')} · 조회수 {this.props.video.view_num}회</p>
        </div>
      </div>
    );
  }
}
