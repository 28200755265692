import React, {Component} from "react";
import {Grid, Icon, Label, Loader, Modal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import NeedsCard from "../../needs.card";

export default class MyNeedsTab extends Component {
  state = {open: false, isLoaded: false, needs: []}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.bizId}/needs`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        needs: res.data,
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isLoaded && this.state.needs.length === 0 && <p className='color-gray pd-1'>새로운 사업을 제안해주세요.</p>
        }
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((needs) =>
              <Grid.Column>
                <NeedsCard needs={needs}/>
              </Grid.Column>)
          }
        </Grid>

        {
          this.props.status &&
            <Link to={`/bizday/${this.props.slug}/${this.props.bizId}/needs`}>
              <div className='mv-8'>
                <span className='p-button fw-b'><Icon name='add'/> 새로운 사업 제안하기</span>
              </div>
            </Link>
        }
      </div>
    );
  }
}