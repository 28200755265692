import React, { Component } from 'react'
import {Route, Switch} from "react-router-dom";
import InvestmentPage from "./investment.page";
import InvestFestivalPage from "./invest.festival.page";
import CreateInvestNeedsPage from "./create.invest.needs";
import VCInvestFestivalPage from "./vc.invest.festival.page";
import VCInvestAlwaysPage from "./vc.invest.always.page";
import InvestDetailPage from "./invest.detail.page";

class InvestModule extends Component {

  render () {
    return (
      <Switch>
        <Route exact path="/investment" component={InvestmentPage}/>
        <Route exact path="/investment/festival/:uuid" component={InvestFestivalPage}/>
        <Route exact path="/investment/vc/festival/always" component={VCInvestAlwaysPage}/>
        <Route exact path="/investment/vc/festival/:uuid" component={VCInvestFestivalPage}/>
        <Route exact path="/investment/new-needs" component={CreateInvestNeedsPage}/>
        <Route exact path={"/investment/progress/:matchId"} component={InvestDetailPage}/>
      </Switch>
    )
  }
}

export default InvestModule;
