import React, {Component} from "react";
import {Card} from "semantic-ui-react";
import {Link} from "react-router-dom";
import moment from "moment";

export default class CitizenCard extends Component {
  render() {
    return (
      <Link to={`/info/citizen/${this.props.post.uuid}`}>
        <div className='p-card hover-zoom'>
          <div className='p-box-16by9'>
            <img className='p-box-ch' style={{objectFit: 'cover'}} src={this.props.post.preview_img_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'}/>
          </div>
          <div className='pd-1'>
            <Card.Meta className='color-main'>
              {this.props.post.tags.map((tag, idx) =>
                `#${tag.name} `
              )}
            </Card.Meta>
            <p className='color-black fs-1 fw-b mv-1'>{this.props.post.title}</p>
            <p className='color-gray'>{moment(this.props.post.created_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
          </div>
        </div>
      </Link>
    );
  }
}