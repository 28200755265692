import {Component} from "react";
import {Container, Tab} from "semantic-ui-react";
import Terms from "./terms";
import Privacy from "./privacy";

export default class PolicyPage extends Component {
  state = {isLoaded: true, activeIndex: 0};

  handleTabChange = (e, {activeIndex}) => {
    this.setState({activeIndex});
    this.props.history.push(`${activeIndex === 0 ? '#terms' : '#privacy'}`);
  }

  render() {
    this.state.activeIndex = this.props.location.hash === '#privacy' ? 1 : 0

    const panes = [
      {
        menuItem: '이용약관',
        render: () => <Terms/>
      },
      {
        menuItem: '개인정보처리방침',
        render: () => <Privacy/>
      }
    ];

    return <div className='p-container'>
      <Container>
        <h1 className='mb-7'>이용약관 및 개인정보처리방침</h1>

        <Tab id='notice-tab' menu={{secondary: true}} panes={panes}
             onTabChange={this.handleTabChange} activeIndex={this.state.activeIndex}/>

      </Container>
    </div>
  }
}