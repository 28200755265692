import React, {Component} from "react";
import {
  Button,
  CardContent, Container, Form, Icon, Input, TextArea
} from "semantic-ui-react";
import StepperTitle, {StepperContent} from "../Stepper";

import axios from "axios";
import moment from "moment";
import DetailMatchedCard from "../detail.matched.card";
import DetailMsgAdmin from "../detail.msg.admin";
import UserContext from "../../UserContext";
import {S3UploadFile} from "../../utills/s3-file-upload";
import RoundProfile from "../../utills/profile";
import FileUploadButton from "../../utills/file_upload_button";


class BizdayDetailPage extends Component {
  static contextType = UserContext;
  state = {progress: [], comment: '', proposal: ''}

  async componentDidMount() {
    this.getProgress();
  }

  async getProgress() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/progress/${this.props.match.params.matchId}`, {withCredentials: true});
      this.setState({
        progress: res.data,
        comment: ''
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async handleProgress(msg, statusCode) {
    if (msg.trim() === '') {
      alert("내용이 없습니다.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/progress/comment`, {
        'matched_id': this.props.match.params.matchId,
        'msg': msg,
        'status_code': statusCode,
      }, {withCredentials: true});
      await this.getProgress();
    } catch (e) {
      alert("메세지 작성에 실패했습니다.");
    }
  }

  async handleProposal(pId, desc) {
    if (desc.trim() === '') {
      alert("사업 이름을 입력해주세요.");
      return;
    }
    if (this.state.file == null) {
      alert("PDF 파일을 선택해 주세요.");
      return;
    }

    const { url } = await S3UploadFile(this.state.file, `bizday/proposal/${this.props.match.params.matchId}`);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/progress/proposal`, {
        'matched_id': this.props.match.params.matchId,
        'progress_id': pId,
        'description': desc,
        'file': url,
      }, {withCredentials: true});
      await this.getProgress();
    } catch (e) {
      alert("제안서 제출에 실패했습니다.");
    }
  }

  handleUpload = async (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.setState({
        file: file,
        file_name: file.name
      });
    }
  }

  render() {
    const user = this.context;

    return (
      <div className='p-container'>
        <Container>
          {
            user ?
              <div>
                <h1 className='mt-5'>진행상황 자세히보기</h1>

                <div className='pt-5'>

                  {
                    this.state.progress.map((p, idx) =>
                      <div key={idx}>
                        <StepperTitle title={p.title} step={p.step}/>
                        <StepperContent>
                          {
                            p.progress.map((m, idx) => {
                              if (m.message_type === 'MATCHED_NEEDS') {
                                return (
                                  <div>
                                    <div className='p-card pd-1 mb-5'>
                                      <p>{JSON.parse(m.message).msg}</p>
                                    </div>

                                    <DetailMatchedCard vNeeds={m.matched_needs.venture_needs} pNeeds={m.matched_needs.partner_needs}/>
                                  </div>
                                );
                              }
                              else if (m.message_type === 'FILE_UPLOAD') {
                                return (
                                  <div>
                                  {
                                    user.group.type === 'VENTURE' ? <div className='p-card pd-1 mb-5'>
                                      <p className='mb-5'>{JSON.parse(m.message).title}</p>
                                      <Form>
                                        <div className='ui field required'>
                                          <label>Biz. 제목</label>
                                          <Input className='mb-5' fluid placeholder={JSON.parse(m.message).desc} name='proposal' onChange={this.handleChange}/>
                                        </div>
                                        <div className='ui field required'>
                                          <label>PDF 파일 업로드</label>
                                          {
                                            this.state.file == null ?
                                              <FileUploadButton
                                                label="파일 추가"
                                                accept=".pdf"
                                                name="attachments"
                                                handleChange={this.handleUpload}/>
                                            : <div className='is-flex is-vcentered'>
                                                <p className='color-main mb-0' style={{flexGrow: 1}}><Icon name='file'/> {this.state.file_name}</p>
                                              <Button basic icon='close' onClick={() => this.setState({file: null})}></Button>
                                              </div>
                                          }
                                          {/*<Form.Input type='file' name='file' onChange={this.handleUpload}/>*/}
                                        </div>
                                      </Form>
                                      <div className='pv-5 has-text-centered mt-5'>
                                        <span className='p-button fw-b' onClick={this.handleProposal.bind(this, m.uuid, this.state.proposal)}><Icon name='send'/> 제출하기</span>
                                      </div>
                                    </div>
                                    : null
                                  }
                                  </div>
                                )
                              }
                              else if (m.message_type === 'FILE_DOWNLOAD') {
                                return (
                                  <div className='p-card pd-1 mb-5'>
                                    <p className='mb-5'>{JSON.parse(m.message).title}</p>
                                    <p className='mb-5 fs-1 fw-b'>{JSON.parse(m.message).description}</p>
                                    <div className='is-flex' style={{justifyContent: 'center', flexWrap: 'wrap'}}>
                                      <a href={JSON.parse(m.message).file} className='has-text-centered hover-gray no-drag mh-1 mb-1'
                                         style={{color: 'gray', borderRadius: '10px', padding: '0.5rem 1rem', cursor: 'pointer'}}>
                                        <Icon name='cloud download'/> PDF 다운로드
                                      </a>
                                    </div>
                                  </div>
                                )
                              }
                              else { // Default
                                if (m.creator_type === 'BOT') {
                                  return (
                                    <div className='p-card pd-1 mb-5'>
                                      <p>{JSON.parse(m.message).msg}</p>
                                    </div>
                                  )
                                } else if (m.creator_type === 'ADMIN') {
                                  return (
                                    <DetailMsgAdmin content={JSON.parse(m.message).msg} date={moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}/>
                                  )
                                } else {
                                  return (
                                    <div style={{display: 'flex'}} className='mv-5'>
                                      <div style={{flexShrink: 0}}>
                                        <RoundProfile src={m.profile.profile_url} size={40}/>
                                      </div>
                                      <div className='p-card pd-1 ml-5' style={{marginTop: 0, borderRadius: '20px'}}>
                                        <p>{JSON.parse(m.message).msg}</p>
                                        <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                                      </div>
                                    </div>
                                  )
                                }
                              }
                            })
                          }
                          {
                            this.state.progress.length === idx + 1 && <div style={{display: 'flex'}} className='mv-5'>
                              <div style={{flexShrink: 0}}>
                                <RoundProfile src={user.group.profile_url} size={40}/>
                              </div>
                              <div style={{width: '100%'}} className='ml-5'>
                                <div className='mb-5'>
                                  <CardContent>
                                    <Form className=' mb-0'>
                                      <TextArea style={{borderRadius: '20px'}} fluid placeholder='메세지를 입력하세요.' name='comment' onChange={this.handleChange} value={this.state.comment}/>
                                    </Form>
                                  </CardContent>
                                </div>
                                <div className='pv-5' style={{float: 'right'}}>
                                    <span className='p-button fw-b' onClick={this.handleProgress.bind(this, this.state.comment, idx + 1)}><Icon name='send'/> 전송하기</span>
                                </div>
                              </div>
                            </div>
                          }
                        </StepperContent>
                      </div>
                    )
                  }
                </div>
              </div>
              : null
          }
        </Container>
      </div>
    );
  }
}

export default BizdayDetailPage;