import React, {Component} from "react";
import {Button, Form, Grid, Image, Modal} from "semantic-ui-react";
import axios from "axios";
import PartnerCard from "./partner.card";

class PartnerSection extends Component {
  state = { partner: [] }

  async componentDidMount () {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/program/partner?type=${this.props.programType}`);
    this.setState({
      partner: response.data
    })
  }

  render() {
    return(
      <div>
        <Grid doubling stackable columns={2} verticalAlign='middle'>
          {
            this.state.partner.map((p, idx) =>
              <Grid.Column key={idx}>
                <PartnerCard partner={p}/>
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    )
  }
}

export default PartnerSection