import {Component} from "react";
import YoutubeCard from "../Youtube/youtube.card";
import axios from "axios";
import {Link} from "react-router-dom";
import {Button, Divider} from "semantic-ui-react";
import YoutubeList from "./widget/YoutubeList";
import VideoLoadingList from "./widget/video.loading.list";

export default class MainVideoList extends Component {
  state = {clicked: -1, ventureList: [{name: '전체', id: -1}], videoUrl: `${process.env.REACT_APP_API_URL}/video?`}

  async componentDidMount() {
    const scrollContainer = document.getElementById('horizontal_scroll');

    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/group/subtype`, {withCredentials: true});
      const venList = res.data.filter(el => el.parent_type == 'VENTURE')
      this.setState({
        videoUrl: `${process.env.REACT_APP_API_URL}/video?`,
        ventureList: this.state.ventureList.concat(venList),
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleClick = async (item) => {
    this.setState({clicked: item.id})
    try {
      if (item.id == -1) {
        this.setState({
          videoUrl: `${process.env.REACT_APP_API_URL}/video?`,
        })
      } else {
        this.setState({
          videoUrl: `${process.env.REACT_APP_API_URL}/video/subtype?id=${item.id}&`,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
      <div className={'is-centered'}>
        <h1 className='mt-9 ph-4'>최근 등록된 영상</h1>
        <div className={'mb-7'}>
          <span id='horizontal_scroll' className={'pv-4 is-flex mobile-scroll'}>
            {this.state.ventureList.map((item, idx) => {

              return (<div
                className={`p-round-btn pv-1 ph-4 pointer mh-2`}
                style={{
                  whiteSpace: 'nowrap',
                  backgroundColor: this.state.clicked == item.id ? '#005386' : '#dddddd',
                  color: this.state.clicked == item.id ? 'white' : 'black'
                }}
                onClick={() => this.handleClick(item)}>{item.name}</div>)
            })}
          </span>
        </div>
        <VideoLoadingList url={this.state.videoUrl} take={50} placeholder={16}/>
      </div>
    );
  }
}