import React, {Component} from 'react';
import'./youtube.css';
import YoutubeThumbnail from "./youtube-thumbnail";
import RoundProfile from "../utills/profile";
import {displayedAt} from "../utills/date";

export default class YoutubeCard extends Component {
  render() {
    return (
      <div className='p-card hover-zoom'>
        <YoutubeThumbnail videoId={this.props.video.youtube_id}/>
        <div className='pd-1'>
          <div style={{display: 'flex'}}>
            <div style={this.props.style}>
              <RoundProfile slug={this.props.video.channel.slug} size={40} src={this.props.video.channel.profile_url}/>
            </div>
            <div className='ml-5'>
              <p className='fw-b mb-0 color-black text-ellipsis'>{this.props.video.title}</p>
              <p className='color-gray'>{this.props.video.channel.name} · {displayedAt(this.props.video.created_at)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}