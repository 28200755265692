import React, {Component} from 'react';

/**
 * Improved File Upload Component
 * - Hide upload instruction text
 * @author: HSY
 */

class FileUploadButton extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <label className='ui button basic' htmlFor={this.props.name}>{this.props.label}</label>
        <input
          style={{display: "none"}}
          accept={this.props.accept}
          id={this.props.name}
          type='file' name={this.props.name} onChange={this.props.handleChange}/>
      </div>
    );
  }
}

export default FileUploadButton;