import {Component} from "react";
import YoutubeCard from "../../Youtube/youtube.card";
import axios from "axios";
import {Link} from "react-router-dom";
import {Placeholder} from "semantic-ui-react";


export default class VideoLoadingList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoList: [],
            isLoading: true,
            prevY: 0,
        }
        this.skip = 0;
        this.videoEnd = false; // 모든 영상을 가져왔는지 확인
        this.startTime = 0; // 마지막으로 스크롤이 맨 끝에 닿은 시간
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    trackScrolling = () => {
        if (new Date() - this.startTime < 700) return

        const wrappedElement = document.getElementById('videoListContainer');
        if (this.isBottom(wrappedElement)) {
            console.log('is bottom!')
            this.startTime = new Date();
            this.getData()
        }
    };

    componentDidMount() {

        this.initialize()

        if (this.props.moreBtn) return
        document.addEventListener('scroll', this.trackScrolling);
    }

    initialize() {
        this.startTime = new Date();
        this.videoEnd = false;
        this.skip = 0;
        this.setState({
            videoList: [],
            isLoading: true,
            prevY: 0,
        }, () => this.getData())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.url == this.props.url) return
        this.initialize()
    }

    componentWillUnmount() {
        if (this.props.moreBtn) return
        document.removeEventListener('scroll', this.trackScrolling);
    }

    getData = async () => {
        if (this.videoEnd) return;

        this.setState({
            isLoading: true,
        })
        const res = await axios.get(`${this.props.url}take=${this.props.take}&skip=${this.skip}`)
        if (res.data.length < this.props.take) this.videoEnd = true
        this.skip = this.skip + this.props.take;
        setTimeout(
            function () {
                //Start the timer
                this.setState({
                    videoList: this.state.videoList.concat(res.data),
                    isLoading: false,
                })
            }.bind(this),
            500
        );

    }

    render() {
        const placeholder = () => {
            return <div className='auto-grid'>
                {[...Array(this.props.placeholder)].map(i =>
                    <div className={'p-card'}>
                        <div className={'p-box-16by9'}>
                            <Placeholder className='p-box-ch' style={{maxWidth: '100%'}}>
                                <Placeholder.Image rectangular style={{maxWidth: '100%'}}/>
                            </Placeholder>
                        </div>
                        <div className={'pd-1'}>
                            <div className={'is-row'}>
                                <Placeholder style={{ height: 40, width: 40, borderRadius: 20}}>
                                    <Placeholder.Image />
                                </Placeholder>
                                <div style={{flexGrow: 1, marginTop: '-5px'}} className='ml-5'>
                                    <Placeholder fluid>
                                        <Placeholder.Line length='full'/>
                                        <Placeholder.Line length='medium'/>
                                    </Placeholder>
                                </div>
                            </div>
                        </div>

                    </div>)}
            </div>
        }
        return (
            <div id={'videoListContainer'}>
                {
                    this.state.videoList.length > 0 ?
                        <div>
                            <div className='auto-grid'>
                                {
                                    this.state.videoList.map((video, idx) => (
                                        <Link to={`/detail?v=${video.uuid}`}>
                                            <div className={''}>
                                                <YoutubeCard video={video} style={{height: '40px'}}/>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                            {this.props.moreBtn && !this.videoEnd &&
                            <div className="color-main has-text-right fs-1 pv-6 pr-3">
                                <span className="color-main ph-3 pv-1 hover-gray p-round pointer"
                                      onClick={this.getData}>
                                    {this.props.title} 더보기
                                </span>
                            </div>
                            }
                        </div>
                        : this.state.isLoading && this.props.placeholder ? placeholder() :
                            <p className='color-gray'>등록된 컨텐츠가 없습니다.</p>
                }
            </div>
        );
    }

}