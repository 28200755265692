import {Component} from "react";
import {
  Divider,
  Container,
  Icon,
  Rating,
} from "semantic-ui-react";
import RatingForm from "./rating.form";
import ReviewModal from "./review.modal";
import ReviewCard from "./review.card";
import axios from "axios";
import "./market.css";

export default class ReviewPage extends Component {
  state = {isOpen: false, isLoaded: false}

  async componentDidMount() {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/market/review?item=${this.props.match.params.id}`);
    console.log(response.data);
    this.setState({
      isLoaded: true,
      reviews: response.data,
    })

  }

  componentDidUpdate() {
    this.state.isOpen = false;
  }

  render() {
    return (
      <div className="p-container">
        <Container>
          <h1>평가 및 리뷰 </h1>
          {
            this.state.isLoaded ?
              <div>
                <RatingForm score={this.state.reviews.total} total={this.state.reviews.reviews.length} star={this.state.reviews.star}/>
                <div>
                  {this.state.isOpen ? (<ReviewModal itemId={this.props.match.params.id}/>) : null}
                  <div>
                    <div className='color-main has-text-centered fs-1 pt-7 pb-5'>
                      <a className='color-main ph-3 pv-2 hover-gray p-round pointer'
                         onClick={() => this.setState({isOpen: !this.state.isOpen})}><Icon name='edit'/> 리뷰 작성하기</a>
                    </div>

                    {this.state.reviews.reviews.map((review, idx) => (
                      <ReviewCard key={idx} review={review}/>
                    ))}
                  </div>

                </div>

              </div>
              : null
          }
        </Container>
      </div>
    );
  }
}
