import {Component} from "react";

export default class MarketItemCard extends Component {

  render() {
    const item = this.props.itemInfo;
    return (
      <div className='p-card hover-zoom' style={{margin: '0'}}>

        <div className='is-flex is-row pd-1 is-vcentered'>
          <div className='mr-4'>
            <div style={{width: '80px'}}>
              <div className='p-box-1by1'
                   style={{borderRadius: '30%', overflow: "hidden", border: '1px solid rgba(0,0,0,.1)'}}>
                <img className='p-box-ch' style={{objectFit: 'cover'}}
                     src={item.icon_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}/>
              </div>
            </div>
          </div>
          <div>
            <div className='fs-1 fw-b' style={{color: '#000000DE'}}>{item.title}</div>
            <div className='color-gray'>{item.subtitle}</div>
          </div>
        </div>

        {/*<Divider fitted/>*/}

        <div className='p-box-16by9'>
          <img className='p-box-ch' style={{objectFit: 'cover'}}
               src={item.preview_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}/>
        </div>

        {/*<Divider fitted/>*/}
        <div className='pd-1'>
          <div className='color-gray has-text-right'>
            <del>{item.original_price}</del>
          </div>
          <div className='color-main fs-1 fw-b has-text-right'>
            {item.price}
          </div>
        </div>
      </div>
    );
  }
}
