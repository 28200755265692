
import {Component} from "react";
import YoutubeCard from "../../Youtube/youtube.card";
import axios from "axios";
import {Link} from "react-router-dom";
import {Button, Divider} from "semantic-ui-react";

export default class YoutubeList extends Component {
    render() {
        return (
            <div className='auto-grid'>
                {this.props.videos.map((video, idx) => (
                    <Link to={`/detail?v=${video.uuid}`}>
                        <YoutubeCard video={video}/>
                    </Link>
                ))}
            </div>
        );
    }
}
