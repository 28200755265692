import YoutubePage from "../Main/widget/YoutubeList";
import {Container, Icon} from "semantic-ui-react";
import SubscribeList from "../subscribe/subscribe.list";
import React, {Component} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import YoutubeThumbnail from "../Youtube/youtube-thumbnail";
import moment from "moment";
import MediaQuery from "react-responsive/src";
import UserContext from "../UserContext";

class VideoHistory extends Component {
  state = {isLoading: true, history: []}
  static contextType = UserContext;

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/video/history?take=${50}&skip=${0}`, {withCredentials: true});

      this.setState({
        isLoading: false,
        history: res.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const user = this.context;
    var beforeDate = '19700101';

    return (
      <div className='p-container'>
        <Container>
          <h1><Icon className='mr-5' name='history'/>시청 기록</h1>
          {
            user ? null : <p className='color-gray pl-1 pt-3'>로그인 후 이용 가능</p>
          }
          <div className='mt-7'>
            {this.state.history.map((history, idx) => {
              const isDateChanged = moment(history.updated_at).format('YYYYMMDD') !== beforeDate
              beforeDate = moment(history.updated_at).format('YYYYMMDD');
              return (
                <>
                  {isDateChanged && <p className='`fw-b fs-1'>{moment(history.updated_at).format('MM월 DD일')}</p>}
                  <Link to={`/detail?v=${history.video.uuid}`}>
                    <div className='p-card hover-zoom mv-6'>
                      <MediaQuery maxWidth={512}>
                        <div>
                          <YoutubeThumbnail videoId={history.video.youtube_id}/>
                          <div className='pd-1'>
                            <p className='fw-b fs-1 mb-0 color-black text-ellipsis'>{history.video.title}</p>
                            <p className='color-gray'>{history.video.channel.name} · 조회수 {history.video.view_num}회</p>
                            <p className='color-main'>{history.count}회 시청함</p>
                          </div>
                        </div>
                      </MediaQuery>

                      <MediaQuery minWidth={512}>
                        <div className='is-flex'>
                          <div style={{width: '250px', flexShrink: 0}}>
                            <YoutubeThumbnail videoId={history.video.youtube_id}/>
                          </div>
                          <div className='pd-1 is-flex is-vcentered'>
                            <div className='mh-5'>
                              <p className='fw-b fs-1 mb-0 color-black text-ellipsis2L'>{history.video.title}</p>
                              <p className='color-gray'>{history.video.channel.name} · 조회수 {history.video.view_num}회</p>
                              <p className='color-main'>{history.count}회 시청함</p>
                            </div>
                            {/*<div className='is-flex is-vcentered'>*/}
                            {/*  <RoundProfile slug={history.video.channel.slug} size={35} src={history.video.channel.profile_url}/>*/}
                            {/*  <p className='ml-5 color-black'>{history.video.channel.name}</p>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </MediaQuery>
                    </div>
                  </Link>
                </>
              )
            })}
          </div>
        </Container>
      </div>
    );
  }
}

export default VideoHistory