import React, {Component} from "react";
import {Accordion, Grid, Icon, Label} from "semantic-ui-react";
import RoundProfile from "../utills/profile";
import {Viewer} from "@toast-ui/react-editor";

export default class DetailMatchedCard extends Component {
  state = {vActive: -1, pActive: -1}

  handleClick = (e, props) => {
    const { index } = props
    if (index === 1) this.setState({
      vActive: this.state.vActive * -1
    })
    if (index === 2) this.setState({
      pActive: this.state.pActive * -1
    })
  }

  render() {
    const { vActive, pActive } = this.state

    return (
      <div className='p-card pd-1 mb-5'>
        <Grid columns={2} stackable divided className=''>
          <Grid.Column>
            <div className='is-flex is-vcentered has-item-centered'>
              <RoundProfile src={this.props.vNeeds.group.profile_url} size={50}/>
              <p className='color-black mb-0 ml-5 fw-b'>{this.props.vNeeds.group.name}</p>
            </div>
            <div className='mt-5 is-flex has-item-centered'>
              <Label className='mb-1'>{this.props.vNeeds.venture_type}</Label>
              <Label className='mb-1'>{this.props.vNeeds.invest_phase}</Label>
              <Label className='mb-1'>{this.props.vNeeds.category_1}</Label>
              <Label className='mb-1'>{this.props.vNeeds.category_2}</Label>
            </div>

            <Accordion className='mt-5'>
              <Accordion.Title
                active={vActive === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <span className='fs-1 fw-b'>{this.props.vNeeds.title}</span>
              </Accordion.Title>
              <Accordion.Content active={vActive === 1}>
                <Viewer
                  referenceDefinition={true}
                  initialValue={this.props.vNeeds.description}
                />
                <h3>첨부 파일</h3>
                {
                  this.props.vNeeds.attachments && this.props.vNeeds.attachments.map((file) =>
                    <div className='mt-5'>
                      <a href={file} className='color-main'><Icon name='file'/> {window.decodeURIComponent(file.split('/')[file.split('/').length - 1])}</a>
                    </div>
                  )
                }
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
          <Grid.Column>
            <div className='is-flex is-vcentered has-item-centered'>
              <RoundProfile src={this.props.pNeeds.group.profile_url} size={50}/>
              <p className='color-black mb-0 ml-5 fw-b'>{this.props.pNeeds.group.name}</p>
            </div>
            <div className='mt-5 is-flex has-item-centered'>
              <Label className='mb-1'>{this.props.pNeeds.category_1}</Label>
              <Label className='mb-1'>{this.props.pNeeds.category_2}</Label>
            </div>
            <Accordion className='mt-5'>
              <Accordion.Title
                active={pActive === 1}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <span className='fs-1 fw-b'>{this.props.pNeeds.title}</span>
              </Accordion.Title>
              <Accordion.Content active={pActive === 1}>
                <Viewer
                  referenceDefinition={true}
                  initialValue={this.props.pNeeds.description}
                />
                <h3>첨부 파일</h3>
                {
                  this.props.pNeeds.attachments && this.props.pNeeds.attachments.map((file) =>
                    <div className='mt-5'>
                      <a href={file} className='color-main'><Icon name='file'/> {window.decodeURIComponent(file.split('/')[file.split('/').length - 1])}</a>
                    </div>
                  )
                }
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}