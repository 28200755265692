import axios from "axios";
import React, {Component} from "react";
import {Editor, Viewer} from "@toast-ui/react-editor";
import {Button, Form, Container} from "semantic-ui-react";

export default class ActivityDetailPage extends Component {
  editorRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isRegistered: false,
    };
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/${id}`
      );
      console.log("res", res);
      this.setState({
        uuid: res.data.uuid,
        title: res.data.title,
        desc: res.data.description,
        poster_url: res.data.poster_url,
        status: res.data.status,
      });

      const regi = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/group/regist/${id}`,
        {withCredentials: true}
      );

      console.log(regi.data);
      this.setState({
        isRegistered: regi.data,
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      isLoaded: true,
    });
  }

  handleRegist = async () => {
    if (this.state.isRegistered) {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_API_URL}/activity/participate/${this.props.match.params.id}`,
          {withCredentials: true}
        );
        console.log("res", res);
        alert("액티비티 참여 취소하였습니다.");
      } catch (e) {
        alert("액티비티 참여 취소하지 못했습니다.");
        console.log(e);
      }
    } else {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/activity/participate/${this.props.match.params.id}`,
          {withCredentials: true}
        );
        console.log("res", res);
        alert("액티비티에 참여하였습니다.");
      } catch (e) {
        alert("액티비티에 참여하지 못했습니다.");
        console.log(e);
      }
    }
  };

  render() {
    if (!this.state.isLoaded) return null;

    return (
      <Container>
        <div className="pd-4">
          <div className="is-row is-vcentered pb-6">
            <p className="fs-5 fw-b mg-0">{this.state.title}</p>
            <span className="bg-lmain p-round-little pv-1 ph-3 color-white ml-5">
              {this.state.status}
            </span>
          </div>
          <div>
            <img
              src={
                this.state.poster_url ??
                "https://react.semantic-ui.com/images/wireframe/square-image.png"
              }
              style={{
                width: "315px",
                height: "450px",
                objectFit: "cover",
              }}
            />
          </div>
          <div className="pv-4">
            <Viewer referenceDefinition={true} initialValue={this.state.desc}/>
          </div>
          <Button
            className="mv-7 mr-4"
            color={this.state.isRegistered ? "red" : "blue"}
            onClick={this.handleRegist}
          >
            {this.state.isRegistered ? "참여 취소하기" : "참여하기"}
          </Button>
        </div>
      </Container>
    );
  }
}
