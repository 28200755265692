import {Component} from "react";
import {Container, Icon, Tab, Placeholder, Popup} from 'semantic-ui-react'
import "./channel.css";
import axios from "axios";
import RoundProfile from "../utills/profile";
import ChannelIntro from "./channel.intro";
import ChannelInfo from "./channel.info";
import ChannelCommunity from "./channel.community";
import ChannelVideo from "./channel.video";
import MarketList from "../Market/market.list";
import ChannelInfoPartner from "./channel.info-partner";


export default class ChannelPage extends Component {

  constructor(props) {
    super(props)
    this.state = {isLoading: true, panes: [], isSubscribed: false, subscribers: 0}
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    try {
      const resIntro = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${nextProps.match.params.slug}`, {withCredentials: true})
      axios.get(`${process.env.REACT_APP_API_URL}/subscribe/channel/${resIntro.data.uuid}`, {withCredentials: true}).then((res) => {
        if (res.data) this.setState({isSubscribed: true});
      });
      const resVideo = await axios.get(`${process.env.REACT_APP_API_URL}/video/channel/${resIntro.data.uuid}`)
      const resCommunity = await axios.get(`${process.env.REACT_APP_API_URL}/community/channel/${resIntro.data.uuid}`)
      let resInfo = null;
      if (resIntro.data.group.type === 'VENTURE') {
        resInfo = await axios.get(`${process.env.REACT_APP_API_URL}/group/venture/info/${resIntro.data.group.uuid}`)
      }
      // const resInfo = await axios.get(`${process.env.REACT_APP_API_URL}/group/venture/info/${resIntro.data.group.uuid}`)
      const resMarket = await axios.get(`${process.env.REACT_APP_API_URL}/market/item/group/${resIntro.data.group.uuid}`)

      console.log('response', resIntro)
      this.setState({
        isLoading: false,
        channel: resIntro.data,
        groupId: resIntro.data.group.uuid,
        subscribers: resIntro.data.subscriber_num,
        panes: [
          {
            menuItem: '소개',
            render: () => <ChannelIntro intro={resIntro.data.intro} description={resIntro.data.description} attachments={resIntro.data.attachments} group={resIntro.data.group}/>
          },
          {
            menuItem: '동영상',
            render: () => <ChannelVideo videos={resVideo.data}/>
          },
          {
            menuItem: '커뮤니티',
            render: () => <ChannelCommunity posts={resCommunity.data} slug={this.state.channel.slug}/>
          },
          {
            menuItem: '정보',
            render: () => resInfo ? <ChannelInfo group={resIntro.data.group} info={resInfo.data}/> : <ChannelInfoPartner group={resIntro.data.group}/>,
          },
          {
            menuItem: '상품',
            render: () => <MarketList marketItems={resMarket.data}/>,
          },
        ]
      })
    } catch (e) {
      console.log(e)
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/channel/${this.props.match.params.slug}`, {withCredentials: true})
      axios.get(`${process.env.REACT_APP_API_URL}/subscribe/channel/${response.data.uuid}`, {withCredentials: true}).then((res) => {
        if (res.data) this.setState({isSubscribed: true});
      });

      console.log('channel info', response.data)

      const resVideo = await axios.get(`${process.env.REACT_APP_API_URL}/video/channel/${response.data.uuid}`)
      const resCommunity = await axios.get(`${process.env.REACT_APP_API_URL}/community/channel/${response.data.uuid}`)
      let resInfo = null;
      if (response.data.group.type === 'VENTURE') {
        resInfo = await axios.get(`${process.env.REACT_APP_API_URL}/group/venture/info/${response.data.group.uuid}`)
      }
      const resMarket = await axios.get(`${process.env.REACT_APP_API_URL}/market/item/group/${response.data.group.uuid}`)

      this.setState({
        isLoading: false,
        channel: response.data,
        groupId: response.data.group.uuid,
        subscribers: response.data.subscriber_num,
        panes: [
          {
            menuItem: '소개',
            render: () => <ChannelIntro group={response.data.group} intro={response.data.intro} description={response.data.description} attachments={response.data.attachments}/>
          },
          {
            menuItem: '동영상',
            render: () => <ChannelVideo videos={resVideo.data}/>
          },
          {
            menuItem: '커뮤니티',
            render: () => <ChannelCommunity posts={resCommunity.data} slug={this.state.channel.slug}/>
          },
          {
            menuItem: '정보',
            render: () => resInfo ? <ChannelInfo group={response.data.group} info={resInfo.data}/> : <ChannelInfoPartner group={response.data.group}/>,
          },
          {
            menuItem: '상품',
            render: () => <MarketList marketItems={resMarket.data}/>,
          },
        ]
      })
    } catch (e) {
      console.log(e)
    }
  }

  openChat = () => {
    window.open(`/chat/group/${this.state.groupId}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  subscribe = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, {channelId: this.state.channel.uuid}, {withCredentials: true}).then((res) => {
      if (res.data === "Subscribed") {
        this.setState({
          isSubscribed: true,
          subscribers: this.state.subscribers + 1
        })
      } else {
        this.setState({
          isSubscribed: false,
          subscribers: this.state.subscribers - 1
        })
      }
    }).catch((e) => {
      console.log(e);
      alert("로그인 후 이용 가능합니다.");
    })
  }

  render() {
    return (
      <div>
        <div className="p-box-6by1">
          {
            !this.state.isLoading ?
              <img style={{objectFit: 'cover'}} className="p-box-ch" src={this.state.channel.background_url ?? 'https://react.semantic-ui.com/images/wireframe/image.png'}/>
              : <div className='p-box-ch'>
                <Placeholder style={{maxWidth: 'none', height: '100%'}}>
                  <Placeholder.Image/>
                </Placeholder>
              </div>
          }
        </div>
        <div className='p-container'
             style={{background: '#fff', paddingBottom: '60px', borderBottom: '2px solid #eaeaea'}}>
          <Container>
            {
              !this.state.isLoading ?
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <RoundProfile slug={this.state.channel.slug} size={80} src={this.state.channel.profile_url}/>
                      <div className='pl-5'>
                          <p className='fs-2 fw-b mb-0'>{this.state.channel.name} {this.state.channel.group.is_certified &&
                          <Popup
                            inverted
                            trigger={<Icon className='color-main fs-m2' name={'check circle'}/>}
                            content='인증됨'
                            position='top center'
                          />}</p>
                        <p className='color-gray'>{this.state.subscribers}명 구독 중</p>
                      </div>
                    </div>
                  </div>

                  <div className='p-subscribe-btn fw-b' style={{
                    flexShrink: 0,
                    backgroundColor: this.state.isSubscribed ? '#eeeeee' : '#00a4e5',
                    color: this.state.isSubscribed ? '#005386' : '#fff',
                  }} onClick={this.subscribe}>
                    <Icon name='youtube play'/> {this.state.isSubscribed ? '구독 중' : '구독하기'}
                  </div>
                </div>
                : null
            }
          </Container>

        </div>
        <div className='p-container' style={{paddingTop: 0, marginTop: '-40px'}}>
          <Container>
            <Tab menu={{inverted: false, secondary: true, pointing: true}} panes={this.state.panes}/>
          </Container>
        </div>

        <div className='fab-channel-chat hover-zoom' onClick={this.openChat}>
          <Icon style={{margin: '3.5px 0 0 -5px'}} name='commenting' size='large'/>
        </div>
      </div>
    );
  }
}