import {Component} from "react";
import SubscribeItem from "./subscribe.item";
import axios from "axios";

export default class SubscribeList extends Component {
  state = {videos: []}

  async componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/subscribe/videos`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      this.setState({
        videos: res.data
      });
    });
  }

  render() {
    return (
      <div style={{display: 'grid', gridGap: '1.5rem'}}>
         {this.state.videos.map((video, idx) => (
            <SubscribeItem key={idx} video={video}/>
         ))}
      </div>
    );
  }
}