import {Component} from "react";
import {Grid} from 'semantic-ui-react'
import YoutubeThumbnail from "../Youtube/youtube-thumbnail";
import MediaQuery from "react-responsive/src";
import RoundProfile from "../utills/profile";
import {displayedAt} from "../utills/date";

export default class SubscribeItem extends Component {
  render() {
    return (
      <a href={`/detail?v=${this.props.video.uuid}`}>
        <div className='p-card hover-zoom'>
          <MediaQuery maxWidth={767}>
            <YoutubeThumbnail videoId={this.props.video.youtube_id}/>
            <div className='pd-1'>
              <p className='fs-1 text-ellipsis2L color-black'>{this.props.video.title}</p>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <RoundProfile slug={this.props.video.channel.slug} size={35} src={this.props.video.channel.profile_url}/>
                <span className='ml-5 color-gray'>{this.props.video.channel.name} · {displayedAt(this.props.video.created_at)}</span>
              </div>
            </div>
          </MediaQuery>

          <MediaQuery minWidth={767}>
            <div className=''>
              <Grid verticalAlign='middle' columns='equal'>
                <Grid.Column style={{maxWidth: '350px'}}>
                  <YoutubeThumbnail videoId={this.props.video.youtube_id}/>
                </Grid.Column>
                <Grid.Column>
                  <div>
                    <p className='fs-1 text-ellipsis2L color-black'>{this.props.video.title}</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <RoundProfile slug={this.props.video.channel.slug} size={35} src={this.props.video.channel.profile_url}/>
                      <span className='ml-5 color-gray'>{this.props.video.channel.name} · {displayedAt(this.props.video.created_at)}</span>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </MediaQuery>
        </div>
      </a>
    );
  }
}

