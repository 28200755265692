import axios from "axios";
import { Component } from "react";

export default class NoticeItemDetail extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            itemInfo: []
        }
    }

    getData() {
        this.setState({isLoading:true});
        axios.get(`${process.env.REACT_APP_API_URL}/board/notice/${this.props.uuid}`).then((res) => {
            this.setState({isLoading: false, itemInfo:res.data});
        })
    }   
    componentDidMount() {
      this.getData();
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (this.props.uuid !== prevProps.uuid) {
        this.total = 0;
        this.state.activePage = 1;
        this.getData();
      }
    }
    
    render() {
        return (
            <div>
                {this.state.itemInfo}
            </div>
        );
    }
}