import {Component} from "react";
import {Container, Divider, Icon, Placeholder} from "semantic-ui-react";
import axios from "axios";
import YoutubeList from "./widget/YoutubeList";
import GroupList from "./widget/group.list";
import GroupLoadingList from "./widget/group.loading.list";
import VideoLoadingList from "./widget/video.loading.list";

export default class KeywordPage extends Component {
    constructor(props) {
        super(props);
        this.query = new URLSearchParams(this.props.location.search)
        this.state = {
            keyword: '',
            groupUrl: `${process.env.REACT_APP_API_URL}/group/search/?name=${(this.query.get('name'))}&`,
            videoUrl: `${process.env.REACT_APP_API_URL}/video/keyword/?name=${(this.query.get('name'))}&`,
            tagList: [{name: '전체', id: -1}],
            isLoaded: false,
            clicked: -1,
        }
    }

    handleClick = async (item) => {
        this.setState({clicked: item.id, isLoaded: false})
        try {
            if (item.id == -1) {
                this.setState({
                    groupUrl: `${process.env.REACT_APP_API_URL}/group/search/?name=${(this.query.get('name'))}&`,
                    videoUrl: `${process.env.REACT_APP_API_URL}/video/keyword/?name=${(this.query.get('name'))}&`,
                })
            } else {
                this.setState({
                    groupUrl: `${process.env.REACT_APP_API_URL}/group/search?name=${window.encodeURIComponent(item.name)}&`,
                    videoUrl: `${process.env.REACT_APP_API_URL}/video/tag?name=${window.encodeURIComponent(item.name)}&`,
                })
           }

            this.setState({
                isLoaded: true
            })

        } catch (e) {
            console.log(e)
        }
    }


    async componentDidMount() {
        const scrollContainer = document.getElementById('tag_scroll');
        if (scrollContainer) {
            scrollContainer.addEventListener("wheel", (evt) => {
                evt.preventDefault();
                scrollContainer.scrollLeft += evt.deltaY;
            });
        }

        const resKeyword = await axios.get(`${process.env.REACT_APP_API_URL}/keyword/?name=${(this.query.get('name'))}`
            , {withCredentials: true});

        this.setState({
            tagList: this.state.tagList.concat(resKeyword.data.tags),
            keyword: resKeyword.data,
        })

    }

    render() {

        const keyword = this.state.keyword

        return (
            <>
                <div className="is-centered" style={{maxWidth: '1200px'}}>
                    <div className="p-box-2by1 round-bottom is-centered">
                        <img style={{objectFit: 'cover'}} className="p-box-ch" src={keyword.banner_url}/>
                    </div>
                </div>
                <Container>

                    <div className={''}>
                        <div className={'has-text-centered fw-b fs-4 mt-6 mb-6'}>{keyword.name}</div>
                        <p className={'has-text-centered'} style={{color: 'gray'}}>{keyword.description}</p>
                        <div className={'has-item-centered'}>
                        <span id='tag_scroll' className={'pv-4 is-flex mobile-scroll'}>
                             {this.state.tagList.map((item, idx) => {
                                 return (<div
                                     className={`p-round-btn pv-1 ph-4 p-round-big pointer mh-2 color-white`}
                                     style={{
                                         flexShrink: 0,
                                         backgroundColor: this.state.clicked == item.id ? '#005386' : '#dddddd',
                                         color: this.state.clicked == item.id ? 'white' : 'black'
                                     }}
                                     onClick={() => this.handleClick(item)}>{item.name}</div>)
                             })
                             }
                        </span>
                        </div>
                    </div>
                    <Divider/>
                    <div className={'pd-1'}>
                        <h2>인기 벤처 기업</h2>
                        <div>
                            <GroupLoadingList take={6} moreBtn url={this.state.groupUrl} />
                        </div>
                        <h2>인기 IR 영상</h2>
                        <div>
                            <VideoLoadingList take={6} moreBtn url={this.state.videoUrl} />
                        </div>
                    </div>
                    <br/>
                </Container>
            </>
        )
    }
}