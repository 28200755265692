import {Component} from "react";

export default class ProgramYoutubeCard extends Component {
  render() {
    return (
      <a href={`https://www.youtube.com/watch?v=${this.props.video.youtube_id}`}>
        <div className='p-card hover-zoom'>
          <div className='p-box-16by9'>
            <img className='p-box-ch' src={`https://img.youtube.com/vi/${this.props.video.youtube_id}/0.jpg`} style={{objectFit:'cover'}}/>
          </div>
          <div className='pd-1'>
            <p style={{color: '#000000DE', fontWeight: 'bold'}}>{this.props.video.title}</p>
          </div>
        </div>
      </a>
    );
  }
}