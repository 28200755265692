import {Component} from "react";
import {Container, Divider, Dropdown, Grid} from "semantic-ui-react";
import axios from "axios";
import SectionTitle from "../Info/SectionTitle";
import GroupLoadingList from "../Main/widget/group.loading.list";

export default class SearchGroupPage extends Component {
    state = {
        clickedList: [[true, ...Array(20).fill(false)], [true, ...Array(10).fill(false)]]
        , ventureList: [{name: '전체', id: -1}], keywordList: [{name: '전체', id: -1}]
        , groupUrl : `${process.env.REACT_APP_API_URL}/group/search/detail?`
    }

    async componentDidMount() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/group/subtype`, {withCredentials: true});
            const venList = res.data.filter(el => el.parent_type == 'VENTURE')

            const keywordRes = await axios.get(`${process.env.REACT_APP_API_URL}/keyword`, {withCredentials: true});

            console.log('VENTURE', venList)
            console.log('KEYWORD', keywordRes.data)

            this.setState({
                ventureList: this.state.ventureList.concat(venList),
                keywordList: this.state.keywordList.concat(keywordRes.data),
            })
        } catch (e) {
            console.log(e);
        }
    }

    makeTagList(list, num) {
        return (
            <div className={'pv-4 is-row'} style={{flexWrap: 'wrap'}}>
                {list.map((item, idx) => {
                    return (<div
                        className={`p-round-btn pv-1 ph-4 pointer mr-3 fs-m1 mb-4`}
                        style={{
                            backgroundColor: this.state.clickedList[num][idx] ? '#005386' : '#dddddd',
                            color: this.state.clickedList[num][idx] ? 'white' : 'black'
                        }}
                        onClick={() => {
                            this.clickTag(num, idx)
                        }}>{item.name}</div>)
                })}
            </div>
        )
    }

    clickTag(num, idx) {
        const tempClickedList = this.state.clickedList;

        if (idx != 0) tempClickedList[num][0] = false
        if (idx == 0 && !tempClickedList[num][0]) tempClickedList[num].fill(false)
        if (idx == 0 && tempClickedList[num][0]) return

        tempClickedList[num][idx] = !tempClickedList[num][idx]

        // 하나도 클릭 안되어있으면 [전체] 클릭
        if(!tempClickedList[num].includes(true)) tempClickedList[num][0] = true

        this.setState({clickedList: tempClickedList}, () => this.makeGroupUrl())
    }

    makeGroupUrl()  {
        let groupUrl = `${process.env.REACT_APP_API_URL}/group/search/detail?`;

        let clickedVenture = '';
        for(let i=1; i<this.state.clickedList[0].length; i++) {
            if(this.state.clickedList[0][i])
                clickedVenture += 'v[]=' + this.state.ventureList[i].id + '&'
                //clickedVenture += Math.pow(2, this.state.ventureList[i].id.replace(/[^0-9]/g,'')-1);
        }

        let clickedKeyword = '';
        if(clickedVenture != '') {
            groupUrl += clickedVenture.substring(0, clickedVenture.length-1)
            clickedKeyword = '&';
        }

        for(let i=1; i<this.state.clickedList[1].length; i++) {
            if(this.state.clickedList[1][i])
                clickedKeyword += 'k[]=' + window.encodeURIComponent(this.state.keywordList[i].name) + '&';

        }
        if(clickedKeyword != '') groupUrl += clickedKeyword.substring(0, clickedKeyword.length-1)

        if(groupUrl != `${process.env.REACT_APP_API_URL}/group/search/detail?`) groupUrl += '&'
        this.setState({
            groupUrl : groupUrl
        })
    }

    render() {
        return (
            <div className={'p-container'}>
                <Container>
                    <SectionTitle
                        title={'기업 검색'}
                        subtitle={'원하는 기업들을 간편하게 찾아보세요! (프로필이 작성된 기업들이 우선 노출됩니다)'}
                    />
                    <div className={'p-round'}>
                        <h3>조건 설정</h3>
                        <Grid>
                            <Grid.Column>
                                <div>
                                    <div className={'fw-b'}> 기업 소속</div>
                                    {this.makeTagList(this.state.ventureList, 0)}
                                </div>
                                <div>
                                    <div className={'fw-b mt-3'}> 기업 분야</div>
                                    {this.makeTagList(this.state.keywordList, 1)}
                                </div>
                            </Grid.Column>
                        </Grid>
                        <section className={'mt-7'}>
                            <GroupLoadingList url={this.state.groupUrl} take={24} />
                        </section>
                    </div>
                </Container>
            </div>
        )
    }

}