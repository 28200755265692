import React, {Component} from "react";
import axios from "axios";
import {CommunityPost} from "./community.post";

export default class ChannelCommunity extends Component {

  render() {
    return (
      <>
        {
          this.props.posts.length === 0 && <p className='mv-7 color-gray has-text-centered'>등록된 게시물이 없습니다.</p>
        }
        {
          this.props.posts.map((post, idx) => (
            <CommunityPost key={idx} post={post} slug={this.props.slug}/>
          ))
        }
      </>
    )
  }
}