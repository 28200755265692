import React, {Component} from "react";
import {
  Container, Divider, Form, Icon, Label, TextArea
} from "semantic-ui-react";

import axios from "axios";
import moment from "moment";
import RoundProfile from "../utills/profile";
import UserContext from "../UserContext";
import StepperTitle, {StepperContent} from "../bizday/Stepper";
import DetailMsgAdmin from "../bizday/detail.msg.admin";
import InvestMatchedDetailCard from "./widgets/invest.matched.detail.card";


class InvestDetailPage extends Component {
  static contextType = UserContext;
  state = {progress: [], comment: '', proposal: ''}

  async componentDidMount() {
    this.getProgress();
  }

  async getProgress() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/progress/${this.props.match.params.matchId}`, {withCredentials: true});
      this.setState({
        progress: res.data,
        comment: ''
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async handleProgress(msg, statusCode) {
    if (msg.trim() === '') {
      alert("내용이 없습니다.");
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/progress/comment`, {
        'matched_id': this.props.match.params.matchId,
        'msg': msg,
        'status_code': statusCode,
      }, {withCredentials: true});
      await this.getProgress();
    } catch (e) {
      alert("메세지 작성에 실패했습니다.");
    }
  }

  render() {
    const user = this.context;
    return (
      <div className='p-container'>
        <Container>
          {
            user ?
              <div>
                <h1 className='mt-5'>진행상황 자세히보기</h1>

                <div className='pt-5'>

                  {
                    this.state.progress.map((p, idx) =>
                      <div key={idx}>
                        <StepperTitle title={p.title} step={p.step}/>
                        <StepperContent>
                          {
                            p.progress.map((m, idx) => {
                              if (m.message_type === 'MATCHED_NEEDS') {
                                let opinion = JSON.parse(m.matched_needs.vc_opinion);

                                return (
                                  <div>
                                    <div className='p-card pd-1 mb-5'>
                                      <p>{JSON.parse(m.message).msg}</p>
                                      <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                                    </div>
                                    <InvestMatchedDetailCard matched={m.matched_needs} opinion={opinion}/>

                                  </div>
                                );
                              }
                              else { // Default
                                if (m.creator_type === 'BOT') {
                                  return (
                                    <div className='p-card pd-1 mb-5'>
                                      <p>{JSON.parse(m.message).msg}</p>
                                      <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                                    </div>
                                  )
                                } else if (m.creator_type === 'ADMIN') {
                                  return (
                                    <DetailMsgAdmin content={JSON.parse(m.message).msg} date={moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}/>
                                  )
                                } else {
                                  return (
                                    <div style={{display: 'flex'}} className='mv-5'>
                                      <RoundProfile src={m.profile.profile_url} size={40}/>
                                      <div className='p-card pd-1 ml-5' style={{marginTop: 0, borderRadius: '20px'}}>
                                        <p>{JSON.parse(m.message).msg}</p>
                                        <p style={{color: 'gray', fontSize: '0.9em'}}>{moment(m.updated_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                                      </div>
                                    </div>
                                  )
                                }
                              }
                            })
                          }
                          {
                            this.state.progress.length === idx + 1 && <div style={{display: 'flex'}} className='mv-5'>
                              <RoundProfile src={user.group.profile_url} size={40}/>
                              <div style={{width: '100%'}} className='ml-5'>
                                <div className='mb-5'>
                                  <Form className=' mb-0'>
                                    <TextArea style={{borderRadius: '20px'}} fluid placeholder='메세지를 입력하세요.' name='comment' onChange={this.handleChange} value={this.state.comment}/>
                                  </Form>
                                </div>
                                <div className='pv-5' style={{float: 'right'}}>
                                    <span className='p-button fw-b' onClick={this.handleProgress.bind(this, this.state.comment, idx + 1)}><Icon name='send'/> 전송하기</span>
                                </div>
                              </div>
                            </div>
                          }
                        </StepperContent>
                      </div>
                    )
                  }
                </div>
              </div>
              : null
          }
        </Container>
      </div>
    );
  }
}

export default InvestDetailPage;