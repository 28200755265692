
import { Container } from "semantic-ui-react";
import Etcetera from "../More/etc";
import NoticeList from "../More/notice.list";
import Survey from "../More/survey";

function MorePage() {
  return (
    <div className='p-container'>
      <Container>
          <NoticeList/>
          <Survey/>
          <Etcetera/>
      </Container>
    </div>
  );
}

export default MorePage;