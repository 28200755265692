import axios from "axios";
import React, {Component, createRef} from "react";
import {Link} from "react-router-dom";
import {Button, Icon} from "semantic-ui-react";
import RoundProfile from "../utills/profile";
import {displayedAt} from "../utills/date";

export class CommunityPost extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      likes: this.props.post.like_num
    }
  }

  async componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/like/community/${this.props.post.uuid}`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      if (res.data) this.setState({isLiked: true});
    });
  }

  like = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/like/community`, {uuid: this.props.post.uuid}, {withCredentials: true}).then((res) => {
      console.log(res);
      if (res.data === "Liked") {
        this.setState({
          isLiked: true,
          likes: this.state.likes + 1
        })
      } else {
        this.setState({
          isLiked: false,
          likes: this.state.likes - 1
        })
      }
    }).catch((e) => {
      console.log(e);
      alert("로그인 후 이용 가능합니다.");
    })
  }

  render() {
    return (
      <div className={"mv-6"}>
        <div
          className="p-card pd-1"
          style={{overflow: "hidden", maxWidth: "700px"}}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            <div className="mr-5">
              <RoundProfile
                slug={this.props.slug}
                size={35}
                src={this.props.post.channel.profile_url}
              />
            </div>
            <div>
              <p className="fw-b mb-0">{this.props.post.channel.name}</p>
              <p className="color-gray">
                {displayedAt(this.props.post.created_at)}
              </p>
            </div>
          </div>

          <div className="pd-1" style={{wordBreak: 'keep-all', whiteSpace: 'pre-wrap'}}>{this.props.post.content}</div>

          {this.props.post.img_url &&
            <div
              className="pd-1 pt-0"
              style={{width: "100%", maxWidth: "500px"}}
            >
              <div className="p-box-1by1">
                <div className="p-box-ch">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                    src={this.props.post.img_url}
                  />
                </div>
              </div>
            </div>
          }

          <span
            className={`color-gray hover-gray mr-1 ${this.state.isLiked ? 'color-main' : 'color-gray'}`}
            style={{
              borderRadius: "10px",
              padding: "0.5rem 1rem",
              cursor: "pointer",
            }}
            onClick={this.like}
          >

            <Icon name={this.state.isLiked ? 'heart' : 'heart empty'}/>
            {this.state.likes}
          </span>

          <Link to={`post/${this.props.post.uuid}`}>
          <span
            className="color-gray hover-gray"
            style={{
              borderRadius: "10px",
              padding: "0.5rem 1rem",
              cursor: "pointer",
            }}
          >
            <Icon name="comment"/>
            {this.props.post.comment_num}
          </span>
          </Link>
        </div>
      </div>
    );
  }
}
