import React, {Component} from "react";
import {Card, Container, Divider, Tab} from "semantic-ui-react";
import axios from "axios";
// import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import './viewer.css';
import moment from "moment";
import {Viewer} from "@toast-ui/react-editor";

class CitizenContentPage extends Component {
  state = {isLoading: true}

  async componentDidMount() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/board/corpoCitizen/post/${this.props.match.params.id}`)
      this.setState({
        isLoading: false,
        post: response.data
      });
      console.log(response.data);

    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          {
            (!this.state.isLoading) ?
              <div>
                <div className='p-card pd-1 mv-5'>
                  <p className='color-main mb-2'>
                    {this.state.post.tags.map((tag, idx) => `#${tag.name} `)}
                  </p>
                  <h2 className='mt-0'>{this.state.post.title}</h2>
                  <p className='color-gray'>{moment(this.state.post.created_at).format('YYYY년 MM월 DD일 HH:mm')}</p>
                </div>
                <div className='pd-1'>
                  <Viewer
                    referenceDefinition={true}
                    initialValue={this.state.post.content}
                  />
                </div>
              </div>
              : null
          }
        </Container>

      </div>
    )
  }
}

export default CitizenContentPage;
