import React, { Component } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Button, Checkbox, Divider, Form, Icon, Input } from "semantic-ui-react";
import axios from "axios";

class NoticeSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voted: this.props.voted,
    }
  }

  handleVoted (votedIdx) {
    this.setState({voted: votedIdx})
  }

  onVote = async(idx) => {
    this.state.voted = idx;
    this.setState({
      voted : idx,
    })
    
    try {
      if(this.state.voted == null) {     
        await axios.delete(`${process.env.REACT_APP_API_URL}/board/notice/vote/${this.props.noticeUuid}`, {withCredentials: true});
      }
      else {
        await axios.post(`${process.env.REACT_APP_API_URL}/board/notice/survey/vote`, {
          'uuid':  this.props.checklist[this.state.voted].uuid
        }, {withCredentials: true});
      }
    } catch (e) {
    }
  }


  render() {
    return (
      this.props.title ?
      <div className='ph-6 pb-5'>
        <div>
          <p className='fs-1'> {this.props.title} </p>
          {
            this.props.checklist.map((val,idx) =>  (
              <div className={`p-round-little border-a pd-1 mb-3 ${idx == this.state.voted ? 'bg-lgray' : ''}`} onClick={()=>this.onVote(this.state.voted == idx ? null : idx)}>
                {idx == this.state.voted ? <Icon name='check'/> : null}
                {val.option}
              </div>
            ))}
        </div>
      </div> : null
    );
  }
}

export default NoticeSurvey;
