import React, {Component} from "react";
import {Button, Container, Form, Grid, Icon, Label, Modal} from "semantic-ui-react";
import axios from "axios";
import RoundProfile from "../utills/profile";
import moment from "moment";
import InvestNeedsCard from "./widgets/invest.needs.card";

export default class VCInvestFestivalPage extends Component {
  state = {
    isLoaded: false,
    isLoaded2: false,
    needs: [],
    opinion: '',
    selectedNeeds: {uuid: '', inv_needs: {group: {profile: '', name: ''}, invest_phase: '', invest_amount: ''}}
  };

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.match.params.uuid}`, {withCredentials: true});
    this.getVentureNeeds()
    console.log(res.data);
    this.setState({
      festival: res.data,
      isLoaded: true,
    });
  }

  getVentureNeeds() {
    axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.match.params.uuid}/needs`, {withCredentials: true}).then((res) => {
      this.setState({
        isLoaded2: true,
        needs: res.data
      })
    });
  }

  openMatching(needs) {
    this.state.opinion = ''
    this.state.selectedNeeds = needs;
    this.open();
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  requestMatching = async () => {
    if (this.state.opinion === '') {
      alert('의견을 입력해주세요.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/matching-request/festival`, {
        festival_id: this.props.match.params.uuid,
        needs_id: this.state.selectedNeeds.uuid,
        opinion: this.state.opinion
      }, {withCredentials: true});
      alert('투자 매칭을 요청했습니다.')
      this.close();
    } catch (e) {
      alert(e.response.data.message);
    }
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          {
            this.state.isLoaded &&
            <>
              <h1>{this.state.festival.title}</h1>
              {
                this.state.festival.status ?
                  <>
                    <p className='color-main mb-3'><Icon name='group'/> 참여 VC
                      - {this.state.festival.host_groups.map((h, idx) =>
                        <span>{h.name}{idx + 1 < this.state.festival.host_groups.length ? ', ' : ''}</span>)}</p>
                    <p className='color-main'><Icon name='time'/> 신청기간
                      - {moment(this.state.festival.start_at).format('MM월 DD일 HH:mm')} ~ {moment(this.state.festival.end_at).format('MM월 DD일 HH:mm')}
                    </p>
                  </>
                  :
                  <>
                    <p className='color-gray mb-3'><Icon name='group'/> 참여 VC
                      - {this.state.festival.host_groups.map((h, idx) =>
                        <span>{h.name}{idx + 1 < this.state.festival.host_groups.length ? ', ' : ''}</span>)}</p>
                    <p className='color-gray'><Icon name='time'/> 신청기간
                      - {moment(this.state.festival.start_at).format('YYYY년 MM월 DD일')} ~ {moment(this.state.festival.end_at).format('YYYY년 MM월 DD일')}
                    </p>
                  </>
              }
              <p className='color-gray mb-6'>
                {
                  this.state.festival.description.split('\n').map((str) => {
                    return (<div>{str}</div>)
                  })
                }
              </p>

              <h3>벤처 투자 니즈</h3>
              {
                this.state.isLoaded2 && this.state.needs.length === 0 ?
                  <p className='color-gray pd-1'>참여 신청한 투자 니즈가 없습니다.</p> : null
              }
              <Grid columns={3} doubling stackable>
                {
                  this.state.needs.map((needs) => <Grid.Column>
                    <InvestNeedsCard needs={needs.inv_needs} opinion={needs.venture_opinion} isFestivalOpen={this.state.festival.status} isVC={true} festivalId={this.props.match.params.uuid} needsId={needs.uuid}/>
                  </Grid.Column>)
                }
              </Grid>
            </>
          }
        </Container>
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>매칭 요청하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <div className='is-flex is-vcentered'>
                  <img style={{
                    border: '1px solid #eaeaea',
                    objectFit: 'cover',
                    width: `60px`,
                    height: `60px`,
                    borderRadius: `30px`
                  }} src={this.state.selectedNeeds.inv_needs.group.profile}
                       alt='group profile'/>
                  <p className='color-black mb-0 ml-5 fw-b'>{this.state.selectedNeeds.inv_needs.group.name}</p>
                </div>
                <div className='mt-5 mb-5'>
                  <Label className='mb-1'>{this.state.selectedNeeds.inv_needs.invest_phase}</Label>
                  <Label className='mb-1'>{this.state.selectedNeeds.inv_needs.invest_amount}억원 이상</Label>
                </div>
                <Form.TextArea
                  required label='매칭 요청 의견' name='opinion'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  row={2}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="요청하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.requestMatching}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}