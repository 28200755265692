import React, {Component} from "react";
import {
  Button,
  Container, Divider, Dropdown, Form, Grid, Icon, Message, MessageContent, Modal,
} from "semantic-ui-react";
import NeedsCard from "../needs.card";
import axios from "axios";
import {Link} from "react-router-dom";
import MatchedNeedsCard from "../matched.needs.card";

class BizdayPageOld extends Component{
  state = { bizIdx: 0, bizDays: [], needs: [], matched: [] }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    }, () => {
      this.getMyNeeds();
    })
  }

  getMyNeeds() {
    if (!this.state.bizDays[this.state.bizIdx]) return;

    axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.state.bizDays[this.state.bizIdx].uuid}/matched/needs`, {withCredentials: true}).then((res => {
      this.setState({
        needs: res.data.needs,
        matched: res.data.matched
      })
    }));
  }

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/posco`, {withCredentials: true});

      const bizCount = [];
      for (let i = 0; i < res.data.length; i++) {
        bizCount.push({
          key: res.data[i].uuid,
          text: res.data[i].title,
          value: i
        });
      }

      this.setState({
        bizCount: bizCount,
        bizDays: res.data,
      }, () => {
        this.getMyNeeds()
      })
    } catch (e) {
      console.log(e);
    }
  }


  render() {

    return (
      <div className='p-container'>
          <Container>
            <h1>내 포스코그룹 Biz Day</h1>

            {
              this.state.bizDays.length > 0 ?
                <div>
                  <Dropdown
                    className='mt-5'
                    name='bizIdx'
                    placeholder='지난 비즈데이 보기'
                    selection
                    options={this.state.bizCount}
                    onChange={this.handleChange}
                  />

                  <Message className='mt-7'>
                    <MessageContent>
                      <Message.Header><Icon name='bullhorn'/> {this.state.bizDays[this.state.bizIdx].title ?? ''}</Message.Header>
                      <p className='mt-5'>
                        {this.state.bizDays[this.state.bizIdx].notice ?? ''}
                      </p>
                    </MessageContent>
                  </Message>

                  <Message>
                    <Message.Content>
                      <Message.Header>참고사항</Message.Header>
                      <p className='mt-5'>
                        {
                          (this.state.bizDays[this.state.bizIdx].notes ?? '').toString().split('\n').map((str) => {
                            return (<div>{str}</div>)
                          })
                        }
                      </p>
                    </Message.Content>
                  </Message>

                  <h3>매칭된 니즈</h3>
                  <Divider/>
                  {
                    this.state.matched.length < 1 &&
                    <p style={{color: 'gray'}}>매칭된 니즈가 없습니다.</p>
                  }
                  <Grid doubling stackable columns={2} verticalAlign='middle'>
                    {
                      this.state.matched.map((e, idx) =>
                        <Grid.Column key={idx}>
                          <MatchedNeedsCard
                            matchId={e.matchInfo.uuid}
                            vNeeds={e.ventureNeeds}
                            pNeeds={e.needs}
                            bizStatus={'벤처 사업계획 제안'}
                          />
                        </Grid.Column>
                      )
                    }
                  </Grid>

                  <h3 className='mt-8'>제안 니즈</h3>
                  <Divider/>
                  {
                    this.state.needs.length < 1 &&
                    <p style={{color: 'gray'}}>새로운 니즈를 제안해주세요.</p>
                  }

                  <Grid doubling stackable columns={2} verticalAlign='middle'>
                    {
                      this.state.needs.map((needs, idx) =>
                        <Grid.Column key={idx}>
                          <NeedsCard
                            needs={needs.needs}
                            profile={needs.profile}
                          />
                        </Grid.Column>
                      )
                    }
                  </Grid>

                  <Link to={{
                    pathname: '/bizday/needs',
                    state: this.state.bizDays ? this.state.bizDays[this.state.bizIdx] : ''
                  }}>
                    <Button color='blue' icon labelPosition='left' className='mt-8 mb-5'>
                      새로운 니즈 제안하기
                      <Icon name='add'/>
                    </Button>
                  </Link>
                </div>
                :
                <div className='mt-5 color-gray'>
                  생성된 비즈데이가 없습니다.
                </div>
            }
          </Container>
      </div>
    );
  }
}

export default BizdayPageOld;