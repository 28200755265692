import React, {Component} from 'react';
import {Accordion, Icon} from 'semantic-ui-react';
import ChannelItem from '../channel/ChannelItem';
import axios from "axios";
import UserContext from "../UserContext";

export default class SidebarSubs extends Component {
  static contextType = UserContext;
  state = {activeIndex: 1, channels: []}

  handleClick = () => {
    this.setState({activeIndex: 0})
  }

  Simplify = () => {
    this.setState({activeIndex: 1})
  }

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/subscribe`, {withCredentials: true});
    this.setState({
      channels: res.data
    })
  }

  render() {
    const user = this.context;

    const {activeIndex} = this.state
    return (
      <div>
        {
          user ?
            <p className='pl-1 color-gray mb-4' style={{fontSize: '1.1em'}}> 구독 </p>
            : null
        }

        {this.state.channels.map((sub, idx) => {
          return idx < 5 ? (<ChannelItem key={idx} channel={sub.channel}/>) : null
        })}

        {
          this.state.channels.length > 5 ?
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
                style={{display: activeIndex === 0 ? 'none' : 'block'}}
              >
                <div className='pl-3'>
                  <Icon name='dropdown'/>
                  &nbsp;&nbsp; {this.state.channels.length - 5}개 더보기
                </div>
              </Accordion.Title>
              <Accordion.Content className='pt-0' active={activeIndex === 0}>
                {this.state.channels.map((sub, idx) => {
                  return idx > 4 ? (<ChannelItem key={idx} channel={sub.channel}/>) : null
                })}
              </Accordion.Content>
              <div className='pl-3' style={{display: activeIndex === 0 ? 'block' : 'none', cursor: 'pointer'}}
                   onClick={this.Simplify}>
                <Icon name='triangle up'/>
                &nbsp;&nbsp; 간략히 보기
              </div>
            </Accordion>
            : null
        }
      </div>
    );
  }
}