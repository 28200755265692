import React, {Component} from "react";
import moment from "moment";
import RoundProfile from "../utills/profile";
import Linkify from 'react-linkify';

export default class ChatBox extends Component {
  render() {
    return (
      <div>
        {
          this.props.isDateChanged && <div className='has-text-centered color-gray fw-b pv-5'>{moment(this.props.chat.created_at).format('MM월 DD일')}</div>
        }
        {
          this.props.isMyChat ? <div style={{display: 'flex', justifyContent: 'flex-end'}} className='mt-2 mb-2'>
              <div style={{display: "flex", maxWidth: '70%', alignItems: 'flex-end'}}>
                <p style={{flexShrink: 0}}
                   className='color-gray fs-m1 mr-4'>{moment(this.props.chat.created_at).format('HH:mm')}</p>
                <div className='p-card pv-4 ph-5' style={{borderRadius: '20px', background: '#00a4e5'}}>
                  <p className='mb-0 fw-b color-white'>{this.props.chat.sender.name}</p>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a className='color-main' target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    <p style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}} className='color-white'>{this.props.chat.body}</p>
                  </Linkify>
                </div>
              </div>
            </div>
            : <div style={{display: 'flex'}} className='mt-2 mb-2'>
              <div className='is-flex'>
                <RoundProfile size={40} src={this.props.chat.sender.profile_url}/>
                {
                  this.props.chat.sender.group && <div style={{marginLeft: '-10px', marginTop: '16px'}}>
                    <RoundProfile size={24} src={this.props.chat.sender.group.profile_url}/>
                  </div>
                }
              </div>
                <div style={{display: "flex", maxWidth: '70%', alignItems: 'flex-end'}}>
                <div className='p-card ml-5 pv-4 ph-5' style={{borderRadius: '20px'}}>
                  <p className='mb-0 fw-b'>{this.props.chat.sender.name}</p>

                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a className='color-main' target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    <p style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>{this.props.chat.body}</p>
                  </Linkify>
                </div>
                <p style={{flexShrink: 0}}
                   className='color-gray fs-m1 ml-4'>{moment(this.props.chat.created_at).format('HH:mm')}</p>
              </div>
            </div>
        }
      </div>
    )
  }
}