import React, { Component, createRef } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { Viewer } from "@toast-ui/react-editor";
import moment, { relativeTimeThreshold } from "moment";
import NoticeSurvey from "./notice.survey";
import axios from "axios";

let votedIdx = null;

export default class NoticeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      surveyTitle: "",
      surveyChecklist: [],
      attachments: []
    };
    this.child = createRef();
  }

  async handleGetData(noticeId) {
    this.setState({ open: true });
    votedIdx = null;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/board/notice/${noticeId}`,
        { withCredentials: true }
      );
      const existNotice = response.data;

      for (let i = 0; i < existNotice.surveys.length; i++) {
        if (existNotice.surveys[i].voted) {
          votedIdx = i;
          break;
        }
      }

      this.setState({
        attachments: existNotice.notice.attachments ?? [],
        surveyTitle: existNotice.notice.survey_title,
        surveyChecklist: existNotice.surveys.map((value) => {
          return { option: value.option, uuid: value.uuid };
        }),
        noticeUuid: existNotice.notice.uuid,
      });
    } catch (e) {}

    this.child.current.handleVoted(votedIdx);
  }
  render() {
    return (
      <Modal
        onClose={() => {
          this.setState({ open: false });
        }}
        onOpen={() => {
          this.setState({ open: true });
        }}
        open={this.state.open}
      >
        <Modal.Header>
          {this.props.notice.title}
          <p className="color-gray fs-m3 fw-n mt-2">
            {moment(this.props.notice.created_at).format("YYYY년 MM월 DD일 HH:mm")}
          </p>
          <div className="fs-m4 mt-4">
            <Icon className='color-main mr-2' name="tags"/>
            {this.props.notice.notice_types
              ? this.props.notice.notice_types.map((label) => {
                  return <span className="ui label fs-m1">{label}</span>;
                })
              : null}
            {this.props.notice.notice_subtypes
              ? this.props.notice.notice_subtypes.map((label) => {
                  return <span className="ui label fs-m1">{label}</span>;
                })
              : null}
            {this.props.notice.notice_keywords
              ? this.props.notice.notice_keywords.map((label) => {
                  return <span className="ui label fs-m1">{label}</span>;
                })
              : null}
            {!(this.props.notice.notice_types.length ||
            this.props.notice.notice_subtypes ||
            this.props.notice.notice_keywords.length) &&
              <span className="ui label fs-m1">전체</span>
            }
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Viewer
            referenceDefinition={true}
            initialValue={this.props.notice.content}
          />

          { this.state.attachments.length > 0 && <p className='fw-b mt-7'>첨부 파일</p> }
          {
            this.state.attachments.map((file) =>
              <div className='mv-3'>
                <a href={file} className='color-main'><Icon name='file'/> {window.decodeURI(file.split('/')[file.split('/').length - 1])}</a>
              </div>
            )
          }

        </Modal.Content>
        <NoticeSurvey
          ref={this.child}
          noticeUuid={this.state.noticeUuid}
          title={this.state.surveyTitle}
          checklist={this.state.surveyChecklist}
          voted={votedIdx}
        />

        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ open: false });
            }}
          >
            확인
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
