import React, {Component} from "react";
import {Container, Grid} from "semantic-ui-react";
import axios from "axios";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Viewer} from '@toast-ui/react-editor';
import './viewer.css';
import ProgramYoutubeCard from "../program.youtube.card";

class OfficePage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      video: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`, {withCredentials: true})
      this.setState({
        isLoading: false,
        office: response.data
      })

      const video = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}/video`)
      this.setState({
        video: video.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          {
            (!this.state.isLoading) ?
              <>
                <h1>{this.state.office.title}</h1>
                <p className='color-gray'>{this.state.office.section}</p>
                <div className='mt-8'>
                  <Viewer
                    referenceDefinition={true}
                    initialValue={this.state.office.intro}
                  />
                </div>
              </>
              : null
          }
          {
            this.state.video.length > 0 &&
              <>
                <h3 className='mt-8'>홍보 영상</h3>
                <Grid doubling columns={4}>
                  {
                    this.state.video.map((v, idx) =>
                      <Grid.Column key={idx}>
                        <ProgramYoutubeCard video={v}/>
                      </Grid.Column>
                    )
                  }
                </Grid>
              </>
          }
        </Container>
      </div>
    )
  }
}

export default OfficePage;
