import {Component} from "react";
import {Rating} from "semantic-ui-react";
import RoundProfile from "../utills/profile";
import {displayedAt} from "../utills/date";

export default class ReviewCard extends Component {
  state = {isOpen: false}


  render() {
    return (
      <div className="p-card bg-white mg-1 ph-8 pv-6 pointer"
           onClick={() => this.setState({isOpen: !this.state.isOpen})}>
        <div>
          <div className='mb-3' style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center'}}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <div className='mr-5'>
                <RoundProfile size={45} src={this.props.review.user.profile_url} subProfile={this.props.review.user.group != null} subSrc={this.props.review.user.group != null ? this.props.review.user.group.profile_url : null}/>
              </div>
              <div>
                <Rating className='mv-2' defaultRating={this.props.review.score} maxRating={5} disabled icon="star" size="large"/>
                <p className='fs-1 fw-b mb-3'>{this.props.review.user.name}<span className='fs-m2 color-gray fw-n'> · {this.props.review.user.group != null ? this.props.review.user.group.name : ''}</span></p>
              </div>
            </div>
            <span className='float-r color-gray mb-3'>{displayedAt(this.props.review.created_at)}</span>
          </div>
          <div className='fw-b fs-1'>{this.props.review.title}</div>
          <div className={'mt-3 ' + (this.state.isOpen ? '' : 'text-ellipsis3L')}>
            {this.props.review.content ?? <span className='color-gray'>내용 없음</span>}
          </div>
        </div>

      </div>
    );
  }
}
