import {Component} from "react";
import {Divider} from "semantic-ui-react";

export default class Privacy extends Component {
  render() {
    return (
      <div className='mt-7'>
        <h3>1. 개인정보 처리방침이란?</h3>
        <Divider/>

        <p>주식회사 포애퍼(이하 "회사")는 이용자의 '동의를 기반으로 개인정보를 수집·이용 및 제공'하고 있으며, '이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장'합니다.
          회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.
          "개인정보처리방침"이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다.
        </p>
        <p>본 개인정보처리방침은 회사가 제공하는 SSP 플랫폼 서비스(이하 '서비스'라 함)에 적용됩니다.</p>

        <h3>2. 개인정보 수집</h3>
        <Divider/>
        <h4>서비스 제공을 위한 필요 최소한의 개인정보를 수집하고 있습니다.</h4>
        <p>회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 어플리케이션 등을 통해 아래와 같은 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.</p>

        <h4>[SSP 계정]</h4>
        <p><b>필수</b></p>
        <p>이메일, 비밀번호, 이름(닉네임), 프로필사진, 서비스 이용 내역</p>

        <h4>개인정보를 수집하는 방법은 다음과 같습니다.</h4>
        <li>회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우</li>
        <li>제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우</li>
        <li>온·오프라인에서 진행되는 이벤트/행사 등 참여</li>
        <li>본 시스템 관리자들을 통한 문의, 상담 과정에서의 채팅, 이메일, 전화 등</li>

        <h4>서비스 이용 과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.</h4>
        <p>PC웹, 모바일 웹/앱 이용 과정에서 단말기정보(OS, 화면사이즈, 디바이스 아이디, 폰기종, 단말기 모델명), IP주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집될 수 있습니다.</p>

        <h3>3. 개인정보 이용 </h3>
        <Divider/>
        <h4>회원관리, 서비스 제공·개선, 신규 서비스 개발 등을 위해 이용합니다.</h4>
        <h4>회원 가입 시 또는 서비스 이용 과정에서 홈페이지 또는 개별 어플리케이션이나 프로그램 등을 통해 아래와 같이 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.</h4>
        <li>회원 식별, 부정이용 방지</li>
        <li>이용자간 메시지 전송, 이용자 검색, 게시물 등록 등의 기능 제공</li>
        <li>신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달</li>
        <li>유료서비스 이용 시 컨텐츠 등의 전송이나 배송·요금 정산</li>
        <li>서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재</li>
        <li>인구통계학적 특성과 이용자의 관심, 기호, 성향의 추정을 통한 맞춤형 컨텐츠 추천 및 마케팅에 활용</li>
        <li>서비스 이용 기록, 접속 빈도 및 서비스 이용에 대한 통계, 프라이버시 보호 측면의 서비스 환경 구축, 서비스 개선에 활용</li>

        <h3>4. 개인정보 제공 </h3>
        <Divider/>
        <h4>회사는 이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.</h4>

        <h3>5. 개인정보 파기 </h3>
        <Divider/>
        <h4>개인정보는 수집 및 이용목적이 달성되면 지체없이 파기하며, 절차 및 방법은 아래와 같습니다.</h4>
        <p>전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다.</p>

        <h3>6. 기타</h3>
        <Divider/>

        <h4>회사는 여러분의 권리를 보호합니다.</h4>
        <p>일반 이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 수집・이용에 대한 동의 철회 또는 가입 해지를 요청할 수 있습니다.</p>
        <p>보다 구체적으로는 서비스 내 설정을 통한 회원정보 수정 기능이나 회원탈퇴 기능을 이용할 수 있고, 고객센터를 통해 서면, 전화 또는 이메일로 요청하시면 지체 없이 조치하겠습니다.</p>
        <p>개인정보의 오류에 대한 정정을 요청하신 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.</p>

        <h4>웹기반 서비스의 제공을 위하여 쿠키를 이용하는 경우가 있습니다.</h4>
        <p>쿠키는 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 사용됩니다.</p>

        <h4>쿠키란?</h4>
        <p>웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자 컴퓨터에 저장됩니다.</p>

        <h4>사용목적</h4>
        <p>개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.
          이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다.
          쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다.
          또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.</p>

        <h4>개인정보침해 신고센터</h4>
        <p>(국번없이)118</p>
        <h4>대검찰청 사이버수사과</h4>
        <p>(국번없이)1301</p>
        <h4>경찰청 사이버안전국</h4>
        <p>(국번없이)182</p>

        <h4>개인정보처리방침이 변경되는 경우</h4>
        <p>법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다.
          개인정보처리방침이 변경되는 경우 회사는 변경 사항을 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다.</p>
        <p>다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다.</p>

          <ul>
              <li>공고일자: 2021년 08월 26일</li>
              <li>시행일자: 2021년 09월 02일</li>
          </ul>

        <p className='color-main fw-b'>포애퍼는 이용자 여러분의 정보를 소중히 생각하며, 이용자가 더욱 안심하고 서비스를 이용할 수 있도록 최선의 노력을 다할 것을 약속드립니다.</p>
      </div>
    );
  }
}