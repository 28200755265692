import React, {Component, useEffect, useState} from "react";
import Slider from "react-slick";
import {Icon} from "semantic-ui-react";
import './carousel.css'
import {Link} from "react-router-dom";

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={'prev-btn pd-m2 hover-zoom'}
            style={{display: "block", float: 'left'}}
            onClick={onClick}
        >
            <Icon name={'chevron circle left'} fitted size='big'/>
        </div>
    );
}

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={'next-btn pd-m2 hover-zoom '}
            style={{display: "block", float: 'right'}}
            onClick={onClick}
        >
            <Icon name={'chevron circle right'} fitted size='big'/>
        </div>
    );
}

const ResizedCarousel = ({keywords}) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => { // cleanup
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const settings = {
        className: "center pb-0",
        infinite: false,
        centerPadding: "60px",
        slidesToShow: Math.min(parseInt(Math.min(windowSize.width, 2000) / 300), keywords.length),
        swipeToSlide: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>
    };

    return (
        <div className={''} style={{overflowX:"hidden"}}>
            <div className={'ph-6'} style={{overflowX: 'visible'}}>
                <h1 className={'has-text- mb-7'}>다양한 분야의 벤처들을 살펴보세요!</h1>
                <Slider {...settings} >
                    {keywords.map((keyword, ind) => (
                        <div className='ph-3'>
                            <Link to={`/keyword/?name=${window.encodeURIComponent(keyword.name)}`}>
                                <div className='p-card pd-1 hover-zoom' style={{background: keyword.color}}>
                                    <p className='fs-1 fw-b' style={{color: '#000'}}>{keyword.name}</p>
                                    <img alt={keyword.name} style={{maxHeight: '80px', float: 'right'}}
                                         src={keyword.icon_url}/>
                                </div>
                            </Link>
                        </div>)
                    )}
                </Slider>
            </div>
        </div>
    );
}
export default ResizedCarousel;