import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import MarketItem from "./market.item";

export default class MarketList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mb-5">
          {
              !this.props.title ? null :
              <h1 className="mb-7"> {this.props.title} </h1>

          }
        {
          this.props.marketItems.length === 0 && <p className={`mv-7 color-gray ${this.props.title == null && 'has-text-centered'}`}>등록된 상품이 없습니다.</p>
        }
        <div className="auto-grid pt-3" style={{ padding: 0 }}>
          {this.props.marketItems.map((item, idx) => (
            <Link to={`/market/item/${item.uuid}`}>
              <MarketItem itemInfo={item} />
            </Link>
          ))}
        </div>
      </div>
    );
  }
}
