import {Component} from "react";
import {Card, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default class ProgramCard extends Component {
  render() {
    return (
      <Link to={{
        pathname: `/program/detail/${this.props.pId}`,
        state: {name: this.props.name}
      }}>
        <div className='p-card hover-zoom pd-1'>
          <p style={{color: '#000000DE'}}>{this.props.name} <span style={{color: '#005386', float: 'right'}}><Icon
            name='arrow circle right'/></span></p>
        </div>
      </Link>
    );
  }
}