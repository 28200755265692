import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Container, Icon} from "semantic-ui-react";
import SubscribeItem from "../subscribe/subscribe.item";

export default function ArchiveDetail(props) {
    const archiveUuid = props.match.params.id;
    const [archiveInfo, setArchiveInfo] = useState({})
    const [videoList, setVideoList] = useState([])

    useEffect(() => {
        try {
            getData();
        } catch (e) {
            console.log(e)
        }
    }, [props.match.params.id])

    async function getData() {
        const archiveRes = await axios.get(`${process.env.REACT_APP_API_URL}/archive/${archiveUuid}`, {withCredentials: true});
        setArchiveInfo(archiveRes.data)
        if(!archiveRes.data) {
            alert('삭제된 보관함이거나, 잘못된 경로입니다.')
            window.location.href = '/video/library'
        }
        console.log(archiveRes);
        setVideoList(archiveRes.data.video_archives);
    }

    function deleteArchive() {
        try {
            axios.delete(`${process.env.REACT_APP_API_URL}/archive/${archiveUuid}`, {withCredentials: true})
            alert(`${archiveInfo.name} 보관함이 삭제되었습니다.`)
            window.location.href = '/video/library'
        } catch (e) {
            console.log(e)
        }
    }

    return (
      <div className={'p-container'}>
          <Container>
              {archiveInfo.uuid &&
              <div>
                  <div className={'is-row mb-7'} style={{justifyContent: 'space-between'}}>
                      <div>
                          <h1>보관함<Icon name='angle right'/>{archiveInfo.name}</h1>
                          <p className='color-gray'>{archiveInfo.name}의 영상들을 살펴보세요</p>
                      </div>
                      <div className={'has-item-centered'}>
                          <Button basic color='red' onClick={deleteArchive}>{archiveInfo.name} 보관함 삭제하기</Button>
                      </div>
                  </div>

                  <div>
                      <div style={{display: 'grid', gridGap: '1.5rem'}}>
                          {videoList.map((item, idx) =>
                            <SubscribeItem key={idx} video={item.video}/>
                          )}
                      </div>
                  </div>
              </div>
              }
          </Container>
      </div>
    )
}