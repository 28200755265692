import React, {Component} from "react";
import axios from "axios";
import ChatBox from "./chat.box";
import UserContext from "../UserContext";
import RoundProfile from "../utills/profile";
import {Loader} from "semantic-ui-react";
import moment from "moment";

class ChatRoom extends Component {
  static contextType = UserContext;
  state = {chats: [], isLoading: true, newMsg: ''}

  handleChange = (event) => {
    this.setState({
      newMsg: event.target.value
    });
  }

  async componentDidMount() {
    try {
      // Context 유저로 가져오기
      // const user = this.context;
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {withCredentials: true});
      const user = result.data;
      console.log(user);

      // 그룹 ID로 방 생성
      if (this.props.type === 'group') {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat?id=${this.props.id}`, {withCredentials: true});
        this.setState({
          userId: user.uuid,
          user: user,
          group: response.data.group,
          roomId: response.data.room.uuid
        }, () => {
          this.getHistory(this.state.roomId);
        })
      } else if (this.props.type === 'room') { // 이미 방이 있는 경우
        this.setState({
          userId: user.uuid,
          user: user,
          group: user.group,
          roomId: this.props.id
        }, () => {
          this.getHistory(this.state.roomId);
        })
      } else {
        alert('잘못된 접근입니다.');
      }
    } catch (e) {
      console.log(e);
      alert('문제가 발생했습니다. 로그인 상태를 확인해주세요.');
    }
  }

  async getHistory(id) {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/history/${id}`, {withCredentials: true});
    this.setState({
      isLoading: false,
      chats: res.data
    })

    // 소켓 방 설정
    this.props.socket.on(this.state.roomId, (msg) => {
      console.log(msg);
      this.setState({
        chats: this.state.chats.concat(JSON.parse(msg))
      });
    })
  }

  // 채팅 보내기
  send = () => {
    if (this.state.newMsg === '') {
      alert('메시지를 입력해주세요.');
      return;
    }

    this.props.socket.emit('send', {
      sender: this.state.user,
      room_id: this.state.roomId,
      body: this.state.newMsg
    });

    this.setState({
      newMsg: ''
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({behavior: "smooth"});
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey){
        e.preventDefault();
        this.send();
      }
    }
  }

  render() {
    const user = this.context;
    var beforeDate = '19700101';

    return (
      <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
        <div style={{
          background: '#fff',
          borderBottom: '1px #eaeaea solid',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }} className='pd-1'>
          <h1 className='mb-0'>채팅</h1>
          {
            !this.state.isLoading && <RoundProfile size={60} src={this.state.group.profile_url}/>
          }
        </div>

        <div id='chat' style={{flex: 1, overflowY: 'scroll', padding: '0 1rem'}}>
          {
            !this.state.isLoading ?
              <>
                {
                  this.state.chats.map((chat, idx) =>
                    {
                      const isDateChanged = moment(chat.created_at).format('YYYYMMDD') !== beforeDate
                      beforeDate = moment(chat.created_at).format('YYYYMMDD');
                      return (
                        <div key={idx}>
                          <ChatBox isDateChanged={isDateChanged} chat={chat} isMyChat={this.state.userId === chat.sender.uuid}/>
                        </div>
                      )
                    }
                  )
                }
              </> : <div className='pv-5'>
                <Loader active inline='centered'/>
              </div>
          }
          <div style={{float: "left", clear: "both"}}
               ref={(el) => {
                 this.messagesEnd = el;
               }}>
          </div>
        </div>

        {
          user ? <div style={{background: '#fff', borderTop: '1px #eaeaea solid', display: 'flex'}} className='pd-1'>
              <textarea
                onKeyPress={this.onKeyPress}
                style={{border: 'none', flexGrow: '1', resize: 'none', lineHeight: '1.5em'}}
                className='ph-3 no-border'
                placeholder='여기에 메시지를 입력해주세요.&#13;&#10;줄바꿈을 하려면 Shift + Enter를 눌러주세요.'
                onChange={this.handleChange}
                value={this.state.newMsg}
              />
              <div className='p-button ml-3 fw-b' onClick={this.send}>전송</div>
            </div>
            : <p className='color-gray pd-1 has-text-centered'
                 style={{background: '#fff', borderTop: '1px #eaeaea solid'}}>로그인 후 이용 가능</p>
        }

      </div>
    )
  }
}

export default ChatRoom;