import { Card, Divider, Placeholder, Pagination, Modal, Image, Header, Button, Icon } from "semantic-ui-react";
import { Component, React } from "react";
import axios from "axios";
import moment from "moment";
import EtcModal from "./etc.modal";

export default class EtcCard extends Component {
      
  total = 0;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      board: [],
      activePage: 1,
      activeItem: false,
      clickPost: {}
    };
    
  }

  getData() {
    this.setState({ activeItem:false, isLoading: true });
    axios.get(`${process.env.REACT_APP_API_URL}/system/notice?skip=${(this.state.activePage - 1) * this.props.itemNum}&take=${this.props.itemNum}`)
      .then((res) => {
        setTimeout(
          function () {
            //Start the timer
            this.setState({
              isLoading: false,
              board: res.data,
            });
          }.bind(this),
          500
        );
      });

  }

  getCount() {
    axios.get(`${process.env.REACT_APP_API_URL}/system/notice/item_num`).then((res) => {
      this.total = parseInt(res.data);
      // console.log(this.total);
      this.getData();
    })
  }

  componentDidMount() {
    this.getCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.total = 0;
      this.state.activePage = 1;
      // console.log(this.props.type);
      this.getCount();
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.state.activePage = activePage;
    this.getData();
  }

  render() {

    return (
      <div>
        {this.state.activeItem ? (<EtcModal notice={this.state.clickPost}>{this.state.activeItem=false}</EtcModal>) : null}

        <div className='p-card'>
          {!this.state.isLoading ? (
            <div>
              {this.state.board.map((post, idx) => (
                <div>
                  {/*{console.log(idx)}*/}
                  <div
                    className="hover-gray pv-5 ph-6"
                    style={{ height: "", cursor: 'pointer' }}
                    onClick={()=>this.setState({activeItem: true, clickPost: post}) }
                  >
                    <div className='mb-2 fs-m1 color-main'>
                      <Icon name='user'/>
                      <span>{post.author}</span>
                    </div>
                    {post.title}
                    <div className="color-gray fs-m1 mt-1">
                      {moment(post.created_at).format("YYYY년 MM월 DD일 HH:mm")}
                    </div>
                    
                  </div>

                  <Divider fitted style={{ borderBottom: "none" }} />
                </div>
              ))}

              <div className="has-item-centered">
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={this.state.activePage}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={2}
                  totalPages={parseInt((this.total - 1) / this.props.itemNum + 1)}
                  pointing
                  secondary
                  onPageChange={this.handlePaginationChange}
                />
                
              </div>
            </div>
          ) : (
            <div>
              {[...Array(this.props.itemNum)].map((n, index) => {
                return (
                  <div>
                    <div className="pv-6 ph-6" style={{ height: "67px" }}>
                      <Placeholder>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </div>
                    <Divider fitted style={{ borderBottom: "none" }} />
                    {/*{ index !== this.props.itemNum - 1 ? <Divider fitted style={{ borderBottom: "none" }} /> : null }*/}
                  </div>
                )
              })}
              <div style={{height: 40}} className='has-item-centered is-vcentered is-flex'>
                <span>⟨</span>
                <Placeholder style={{ height: 20, width: 20 }} className='ml-7 mr-5'>
                  <Placeholder.Image />
                </Placeholder>
                <span> </span>
                <Placeholder style={{ height: 20, width: 20 }} className='mr-7'>
                  <Placeholder.Image />
                </Placeholder>
                <span>⟩</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
