import React, {Component} from 'react';
import './mobile.css';
import axios from "axios";
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import {Viewer} from '@toast-ui/react-editor';
import {Card, CardContent, Container, Divider, Grid, Icon} from "semantic-ui-react";
import ProgramYoutubeCard from "../Info/program.youtube.card";

export class MobileProgramViewer extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      intro: '',
      video: []
    }
  }

  async componentDidMount () {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}`)
      const existProgram = response.data;
      this.setState({
        isLoading: false,
        name: existProgram.title,
        intro: existProgram.intro,
        website: existProgram.website,
        tel: JSON.parse(existProgram.contact).tel,
        email: JSON.parse(existProgram.contact).email
      })
      // 홍보 영상
      const video = await axios.get(
        `${process.env.REACT_APP_API_URL}/program/${this.props.match.params.id}/video`)
      this.setState({
        video: video.data
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        intro: "네트워크 문제가 발생했습니다. 잠시 뒤 다시 시도하세요."
      });
    }
  }

  render () {
    return (
      <div style={{background: '', padding: '1rem'}}>
        {
          (!this.state.isLoading) ?
            <div>
              <Viewer
                referenceDefinition={true}
                initialValue={this.state.intro}
              />
              {
                this.state.website ?
                  <div>
                    <h3 className='mt-8'>홈페이지</h3>
                    <Divider/>
                    <a href={this.state.website}>
                      <Card fluid link>
                        <CardContent>
                          <p style={{color: '#000000DE'}}>{this.state.website} <span style={{color: '#005386'}}><Icon name='external'/></span></p>
                        </CardContent>
                      </Card>
                    </a>
                  </div>
                  : null
              }
              <h3 className='mt-8'>담당자 연락처</h3>
              <Divider/>
              <p><span style={{color: '#005386'}}><Icon name='phone'/></span> Tel. {this.state.tel}</p>
              <p><span style={{color: '#005386'}}><Icon name='mail'/></span> Email. {this.state.email}</p>
            </div>
            : null
        }
        <h3 className='mt-8'>홍보 영상</h3>
        <Divider/>
        <Grid doubling columns={4}>
          {
            this.state.video.map((v, idx) =>
              <Grid.Column key={idx}>
                <ProgramYoutubeCard video={v}/>
              </Grid.Column>
            )
          }
        </Grid>
      </div>
    );
  }
}