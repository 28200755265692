import React, {Component} from "react";
import {Container, Grid} from "semantic-ui-react";
import ProgramCard from "../program.card";
import PartnerSection from "../partner.section";
import {Link} from "react-router-dom";
import axios from "axios";

class InfoIncubatorPage extends Component {
  state = {program: [], prevSection: '', office: [], isLoaded: false};

  async componentDidMount() {
    const program = await axios.get(`${process.env.REACT_APP_API_URL}/program?type=INCU`);
    const office = await axios.get(`${process.env.REACT_APP_API_URL}/program?type=OFFI`);
    this.setState({
      prevSection: '',
      isLoaded: true,
      program: program.data,
      office: office.data
    });
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          <h1>창업 보육</h1>

          <Grid doubling stackable columns={2} verticalAlign='middle'>
            {
              this.state.program.map((p) => {
                if (this.state.prevSection !== p.section) {
                  this.state.prevSection = p.section;
                  return <>
                    <Grid.Column width={16}>
                      <h3 className='mt-8'>{p.section}</h3>
                    </Grid.Column>
                    <Grid.Column>
                      <ProgramCard name={p.title} pId={p.uuid}/>
                    </Grid.Column>
                  </>
                } else {
                  return <Grid.Column>
                    <ProgramCard name={p.title} pId={p.uuid}/>
                  </Grid.Column>
                }
              })
            }
          </Grid>

          <h3 className='mt-8 mb-7'>공간 지원</h3>
          <Grid doubling stackable columns={3}>
            {
              this.state.office.map((o) => <Grid.Column>
                <Link to={{
                  pathname: `/program/office/${o.uuid}`,
                  state: {name: o.title, pId: o.uuid}
                }}>
                  <div className='p-card hover-zoom pointer'>
                    <div className='p-box-16by9'>
                      <div className='p-box-ch'>
                        <img alt='office img' style={{width: '100%', height: '100%', objectFit: 'cover'}}
                             src={o.img_url ?? 'https://react.semantic-ui.com/images/wireframe/square-image.png'}/>
                      </div>
                    </div>
                    <div className='pd-1'>
                      <p className='color-black mb-2'>{o.title}</p>
                      <p className='color-gray'>{o.section}</p>
                    </div>
                  </div>
                </Link>
              </Grid.Column>)
            }
          </Grid>

          <h3 className='mt-8 mb-7'>협업 파트너</h3>
          <PartnerSection programType={'INCU'}/>

        </Container>
      </div>
    );
  }
}

export default InfoIncubatorPage;