import React, {Component} from 'react';
import {Image, Icon, Input, Button, Dropdown} from 'semantic-ui-react';
import "./navbar.css";
import {Link, withRouter} from "react-router-dom";
import axios from "axios";
import UserContext from "../UserContext";
import RoundProfile from "../utills/profile";
import MediaQuery from "react-responsive/src";

class Navbar extends Component {
  static contextType = UserContext;
  state = {search_query : '', notificationCount: 0}

  goto = (loc) => {
    this.props.history.push(loc);
  }

  login = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login?redirect=${window.location.href}`;
  }

  logout = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {withCredentials: true});
  }

  search = () => {
    if(this.state.search_query.length < 1) return
    window.location.href = `/search/?search_query=${this.state.search_query}`
  }

  onEnterPress = (e) => {
    if(e.key == 'Enter') this.search();
  }

  handleSearch = (e, data) => {
    this.setState({
      [data.name]: window.encodeURIComponent(data.value)
    })
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/notification/count`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      this.setState({
        notificationCount: res.data
      })
    });
  }

  render() {
    const user = this.context;

    return (
      <div className="p-navbar">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{flexShrink: 0}}>
            <a href={'/'} className='is-flex p-item-vcentered'>
              <Image size='tiny' src='/img/ssp-logo-blue.svg'/>
              <MediaQuery minWidth={900}><p className='ml-5 fs-m1 mb-0 fw-b color-main'>Startup Support Program</p></MediaQuery>
            </a>
          </div>
            <div style={{flexShrink: 1, width: '600px', padding: '0 1.5rem'}}>
              <Input name='search_query' fluid centered icon placeholder='검색어를 입력해주세요' onKeyPress={this.onEnterPress} onChange={this.handleSearch}>
                <input/>
                  <Icon link={true} name='search' onClick={this.search}/>

              </Input>
            </div>
          <div style={{flexShrink: 0}}>
            {
              user ?
                <div className='is-flex is-vcentered'>
                  <Dropdown
                    trigger={
                      <span>
                      <RoundProfile size={35} src={user.profile_url}/>
                    </span>
                    }
                    pointing='top right'
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item text='프로필' icon='user' onClick={this.goto.bind(this, '/account/profile')} />
                      <Dropdown.Item text={`알림 (${this.state.notificationCount > 99 ? `99+` : this.state.notificationCount})`} icon='bell' onClick={this.goto.bind(this, '/notification')} />
                      <Dropdown.Item text='채팅' icon='chat' onClick={this.goto.bind(this, '/chat')}/>
                      {
                        user.group && <>
                          {
                            user.group.type === 'ADMIN' ? <Dropdown.Item text='SSP 관리자' icon='shield' onClick={() => window.location.href='https://admin.poscossp.com'} />
                              : <Dropdown.Item text='SSP Studio' icon='video' onClick={() => window.location.href='https://studio.poscossp.com'} />
                          }
                        </>
                      }
                      <Dropdown.Item text='로그아웃' icon='log out' onClick={this.logout} href={"/"}/>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className='fw-b fs-m2 color-white' style={{zIndex: 1, backgroundColor: '#005386', marginLeft: '-8px', marginTop: '-18px', padding: '0.02rem 0.3rem', borderRadius: '20px'}}>
                    {
                      this.state.notificationCount > 99 ? `99+` : this.state.notificationCount
                    }
                  </div>
                </div>
                :
                <>
                  <MediaQuery minWidth={600}>
                    <Button className='mr-5' basic onClick={this.login}><Icon name='key'/>로그인</Button>
                    <Button basic onClick={this.goto.bind(this, '/signup')}><Icon name='user'/>회원가입</Button>
                  </MediaQuery>
                  <MediaQuery maxWidth={599}>
                    <Button className='mr-5' basic onClick={this.login}><Icon name='key' className={'mr-0'}/></Button>
                    <Button basic onClick={this.goto.bind(this, '/signup')}><Icon name='user' className={'mr-0'}/></Button>
                  </MediaQuery>
                </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar)