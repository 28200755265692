import React, {Component} from "react";
import {Accordion, Grid, Icon, Label, Progress, Statistic} from "semantic-ui-react";
import RoundProfile from "../../utills/profile";

export default class InvestMatchedDetailCard extends Component {
  state = {vActive: -1, pActive: -1}

  handleClick = (e, props) => {
    const { index } = props
    if (index === 1) this.setState({
      vActive: this.state.vActive * -1
    })
    if (index === 2) this.setState({
      pActive: this.state.pActive * -1
    })
  }

  render() {
    const { vActive, pActive } = this.state

    return (
      <div className='p-card pd-1 mb-5'>
        <Grid columns={2} stackable divided className=''>
          <Grid.Column>
            <div className='is-flex is-vcentered mb-5'>
              <RoundProfile size={60} src={this.props.matched.inv_needs.group.profile_url} slug={this.props.matched.inv_needs.group.channel_info.slug}/>
              <div className='ml-5'>
                <p className='fw-b fs-1 mb-0 color-black'>{this.props.matched.inv_needs.group.name}</p>
              </div>

            </div>

            <Accordion className='mt-5'>
              <Accordion.Title
                active={vActive === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <span className='fs-1 fw-b'>투자 유치 정보</span>
              </Accordion.Title>
              <Accordion.Content active={vActive === 1}>
                <Grid columns={1} stackable>
                  <Grid.Column>
                    <div className='p-card pd-1'>
                      <h3 className={'color-main has-text-centered'}><Icon name={'pie graph'}/> 직전 투자</h3>
                      <div className={'ph-5'}>
                        <Statistic.Group size={'mini'} horizontal>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{this.props.matched.inv_needs.prev_invest_phase}</Statistic.Value>
                            <Statistic.Label>단계</Statistic.Label>
                          </Statistic>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{this.props.matched.inv_needs.prev_invest_amount}억원</Statistic.Value>
                            <Statistic.Label>금액</Statistic.Label>
                          </Statistic>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{this.props.matched.inv_needs.prev_post_value}억원</Statistic.Value>
                            <Statistic.Label>밸류(Post)</Statistic.Label>
                          </Statistic>
                        </Statistic.Group>
                      </div>
                    </div>
                  </Grid.Column>

                  <Grid.Column>
                    <div className='p-card pd-1'>
                      <h3 className={'color-main has-text-centered'}><Icon name={'line graph'}/> 투자 유치</h3>
                      <div className={'ph-5'}>
                        <Statistic.Group size={'mini'} horizontal>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{this.props.matched.inv_needs.invest_phase}</Statistic.Value>
                            <Statistic.Label>단계</Statistic.Label>
                          </Statistic>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{this.props.matched.inv_needs.invest_amount}억원 이상</Statistic.Value>
                            <Statistic.Label>금액</Statistic.Label>
                          </Statistic>
                          <Statistic>
                            <Statistic.Value className={'fw-b'}>{(this.props.matched.inv_needs.invest_time / 100).toFixed(0)}년 {this.props.matched.inv_needs.invest_time % 100}월</Statistic.Value>
                            <Statistic.Label>자금 필요 시기</Statistic.Label>
                          </Statistic>
                        </Statistic.Group>
                      </div>
                    </div>
                  </Grid.Column>

                  <Grid.Column>
                    <div className='p-card pd-1'>
                      <h3 className={'color-main has-text-centered'}><Icon name={'users'}/> 주요 주주 현황 </h3>
                      {
                        JSON.parse(this.props.matched.inv_needs.shareholder).map((e) => {
                          return <div className='ph-5'>
                            <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                            <Progress className='mb-5' color='yellow' size='tiny' percent={e.percent}/>
                          </div>
                        })
                      }
                    </div>
                  </Grid.Column>

                  <Grid.Column>
                    <div className='p-card pd-1'>
                      <h3 className={'color-main has-text-centered'}><Icon name={'money'}/> 투자 자금 소요 내역</h3>
                      {
                        JSON.parse(this.props.matched.inv_needs.expense_list).map((e, idx) => {
                          let color = ''
                          switch (idx) {
                            case 0: color = 'teal'; break;
                            case 1: color = 'blue'; break;
                            case 2: color = 'violet'; break;
                          }

                          return <div className='mb-5 ph-5'>
                            <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                            <p className='color-gray mb-3' style={{whiteSpace: 'pre-wrap'}}>{e.content}</p>
                            <Progress className='mb-0' color={color} size='tiny' percent={e.percent} />
                          </div>
                        })
                      }
                    </div>
                  </Grid.Column>
                  {
                    this.props.matched.inv_needs.opinion && <Grid.Column>
                      <div className='p-card pd-1'>
                        <h3 className={'color-main has-text-centered'}><Icon name={'commenting'}/> 벤처 요청 의견</h3>
                        <div className='ph-5 mb-5'>
                          <p>{this.props.opinion}</p>
                        </div>
                      </div>
                    </Grid.Column>
                  }
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
          <Grid.Column>
            <div className='is-flex is-vcentered'>
              <RoundProfile size={60}
                            src={this.props.matched.vc_group.profile_url}/>
              <div className='ml-5'>
                <p className='fw-b fs-1 mb-0 color-black'>{this.props.matched.vc_group.name}</p>
              </div>
            </div>

            <Accordion className='mt-5'>
              <Accordion.Title
                active={pActive === 1}
                index={2}
                onClick={this.handleClick}
              >
                <Icon name='dropdown' />
                <span className='fs-1 fw-b'>투자 매칭 배경</span>
              </Accordion.Title>
              <Accordion.Content active={pActive === 1}>
                <div className='p-card pd-1'>
                  <h4>1. 사업 아이디어</h4>
                  <p>{this.props.opinion.idea}</p>
                  <h4>2. 기술력</h4>
                  <p>{this.props.opinion.tech}</p>
                  <h4>3. 시장 규모/속도</h4>
                  <p>{this.props.opinion.market}</p>
                  <h4>4. 수익성</h4>
                  <p>{this.props.opinion.profit}</p>
                  <h4>5. 기업 지배력</h4>
                  <p>{this.props.opinion.control}</p>
                  <h4>6. CEO 자질</h4>
                  <p>{this.props.opinion.ceo}</p>
                  <h4>7. 미래 시장 재편 (Game Changer)</h4>
                  <p>{this.props.opinion.future}</p>
                  <h4>8. 기타</h4>
                  <p>{this.props.opinion.etc}</p>
                </div>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}