import React, {Component} from 'react';
import MediaQuery from "react-responsive/src";
import {Link} from "react-router-dom";
import SidebarMobileItem from "./sidebar.mobile.item";

export default class MobileSidebar extends Component {
  render() {
    return(
      <MediaQuery maxWidth={767}>
        <div className="p-sidebar-mobile">
          <div style={{padding: '0px 30px', display: 'flex', justifyContent: 'center'}}>
            <Link to="/">
              <SidebarMobileItem icon={'home'}/>
            </Link>
            <Link to="/subscribe">
              <SidebarMobileItem icon={'bookmark'}/>
            </Link>
            <Link to="/market">
              <SidebarMobileItem icon={'cart'}/>
            </Link>
            <Link to="/info">
              <SidebarMobileItem icon={'compass'}/>
            </Link>
            
            <Link to="/more">
              <SidebarMobileItem icon={'ellipsis horizontal'}/>
            </Link>
          </div>
        </div>
      </MediaQuery>
    )
  }
}