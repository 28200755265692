import React, {Component} from "react";
import RoundProfile from "../../utills/profile";
import {Button, Form, Grid, Icon, Label, Modal, Progress, Statistic} from "semantic-ui-react";
import moment from "moment";
import {Viewer} from "@toast-ui/react-editor";
import axios from "axios";

export default class InvestNeedsCard extends Component {
  state = {open: false, open2: false, idea: '', tech: '', market: '', profit: '', control: '', ceo: '', future: '', etc: '' }
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})
  open2 = () => this.setState({open2: true})
  close2 = () => this.setState({open2: false})
  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  requestMatching = async () => {
    if (this.state.idea === '' || this.state.tech === '' || this.state.market === '' || this.state.profit === '' ||
        this.state.control === '' || this.state.ceo === '' || this.state.future === '' || this.state.etc === '') {
      alert('의견을 입력해주세요.');
      return;
    }
    try {
      if (this.props.festivalId) {
        await axios.post(`${process.env.REACT_APP_API_URL}/invest/matching-request/festival`, {
          festival_id: this.props.festivalId,
          needs_id: this.props.needsId,
          opinion: { idea: this.state.idea, tech: this.state.tech, market: this.state.market, profit: this.state.profit,
            control: this.state.control, ceo: this.state.ceo, future: this.state.future, etc: this.state.etc }
        }, { withCredentials: true });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/invest/matching-request/always`, {
          needs_id: this.props.needsId,
          opinion: { idea: this.state.idea, tech: this.state.tech, market: this.state.market, profit: this.state.profit,
            control: this.state.control, ceo: this.state.ceo, future: this.state.future, etc: this.state.etc }
        }, { withCredentials: true });
      }
      alert('투자 매칭을 요청했습니다.')
      this.close2()
      this.close();
    } catch (e) {
      console.log(e);
      alert(e.response.data.message);
    }
  }

  render() {
    return <Modal
      dimmer={'blurring'}
      onClose={() => this.close()}
      onOpen={() => this.open()}
      open={this.state.open}
      trigger={
        <div className='p-card hover-zoom pointer'>
          {/*<div className='p-box-6by1'>*/}
          {/*  <img className='p-box-ch' style={{objectFit: 'cover'}} src={this.props.needs.group.channel_info.background_url ?? 'https://react.semantic-ui.com/images/wireframe/image.png'}/>*/}
          {/*</div>*/}
          <div className='pd-1'>
            <div className='is-flex is-vcentered mb-5'>
              <RoundProfile size={60} src={this.props.needs.group.channel_info.profile_url} slug={this.props.needs.group.channel_info.slug}/>
              <div className='ml-5'>
                <p className='fw-b mb-2 fs-1'>{this.props.needs.group.channel_info.name}</p>
                <p className='color-gray fs-m1 text-ellipsis2L'>{this.props.needs.group.channel_info.intro}</p>
              </div>

            </div>
            <div className='mb-5 has-item-centered' style={{flexWrap: 'wrap'}}>
              <Label className='mb-1'>{this.props.needs.invest_phase}</Label>
              <Label className='mb-1'>{this.props.needs.invest_amount}억원 이상</Label>
              <Label className='mb-1'>{(this.props.needs.invest_time / 100).toFixed(0)}년 {this.props.needs.invest_time % 100}월</Label>
            </div>
            {
              this.props.opinion && <div className='pv-3 ph-4 mb-5' style={{border: '1px solid #eaeaea', borderRadius: '10px'}}>
                <p>{this.props.opinion}</p>
              </div>
            }
            <p className='fs-m1 color-gray has-text-centered'>{moment(this.props.needs.created_at).format('YYYY년 MM월 DD일')} · {this.props.needs.group.name}</p>
          </div>
        </div>
      }
    >
      <Modal.Header>투자 니즈 상세 정보</Modal.Header>
      <Modal.Content>
        <div className='has-text-centered'>
          <RoundProfile src={this.props.needs.group.profile_url} size={80} slug={this.props.needs.group.channel_info.slug}/>
          <p className='fw-b fs-1 mt-5 mb-0'>{this.props.needs.group.name}</p>
        </div>

        <div className='p-card pd-1 is-flex is-vcentered mt-6 mb-8'>
          <RoundProfile src={this.props.needs.group.channel_info.profile_url} size={60} slug={this.props.needs.group.channel_info.slug}/>
          <div className='ml-6'>
            <p className='color-black fw-b fs-1 mb-2'>{this.props.needs.group.channel_info.name}</p>
            <p className='color-gray text-ellipsis2L'>{this.props.needs.group.channel_info.intro}</p>
          </div>
        </div>

        <Grid columns={2} stackable>
          <Grid.Column>
            <div className='p-card pd-1'>
              <h3 className={'color-main has-text-centered'}><Icon name={'pie graph'}/> 직전 투자</h3>
              <div className={'ph-5'}>
                <Statistic.Group size={'mini'} horizontal>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{this.props.needs.prev_invest_phase}</Statistic.Value>
                    <Statistic.Label>단계</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{this.props.needs.prev_invest_amount}억원</Statistic.Value>
                    <Statistic.Label>금액</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{this.props.needs.prev_post_value}억원</Statistic.Value>
                    <Statistic.Label>밸류(Post)</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className='p-card pd-1'>
              <h3 className={'color-main has-text-centered'}><Icon name={'line graph'}/> 투자 유치</h3>
              <div className={'ph-5'}>
                <Statistic.Group size={'mini'} horizontal>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{this.props.needs.invest_phase}</Statistic.Value>
                    <Statistic.Label>단계</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{this.props.needs.invest_amount}억원 이상</Statistic.Value>
                    <Statistic.Label>금액</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value className={'fw-b'}>{(this.props.needs.invest_time / 100).toFixed(0)}년 {this.props.needs.invest_time % 100}월</Statistic.Value>
                    <Statistic.Label>자금 필요 시기</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className='p-card pd-1'>
              <h3 className={'color-main has-text-centered'}><Icon name={'users'}/> 주요 주주 현황 </h3>
              {
                JSON.parse(this.props.needs.shareholder).map((e) => {
                  return <div className='ph-5'>
                    <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                    <Progress className='mb-5' color='yellow' size='tiny' percent={e.percent}/>
                  </div>
                })
              }
            </div>
          </Grid.Column>

          <Grid.Column>
            <div className='p-card pd-1'>
              <h3 className={'color-main has-text-centered'}><Icon name={'money'}/> 투자 자금 소요 내역</h3>
              {
                JSON.parse(this.props.needs.expense_list).map((e, idx) => {
                  let color = ''
                  switch (idx) {
                    case 0: color = 'teal'; break;
                    case 1: color = 'blue'; break;
                    case 2: color = 'violet'; break;
                  }

                  return <div className='mb-5 ph-5'>
                    <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                    <p className='color-gray mb-3' style={{whiteSpace: 'pre-wrap'}}>{e.content}</p>
                    <Progress className='mb-0' color={color} size='tiny' percent={e.percent} />
                  </div>
                })
              }
            </div>
          </Grid.Column>
          {
            this.props.opinion && <Grid.Column>
              <div className='p-card pd-1'>
                <h3 className={'color-main has-text-centered'}><Icon name={'commenting'}/> 벤처 요청 의견</h3>
                <div className='ph-5 mb-5'>
                  <p>{this.props.opinion}</p>
                </div>
              </div>
            </Grid.Column>
          }
        </Grid>

        {/*<div className='ph-5'>*/}
        {/*  <Label className='mb-1'>단계: {this.props.needs.prev_invest_phase}</Label>*/}
        {/*  <Label className='mb-1'>금액: {this.props.needs.prev_invest_phase}</Label>*/}
        {/*  <Label className='mb-1'>밸류(Post): {this.props.needs.prev_post_value}억원</Label>*/}
        {/*</div>*/}



        {/*<div className='ph-5'>*/}
        {/*  <Label className='mb-1'>단계: {this.props.needs.invest_phase}</Label>*/}
        {/*  <Label className='mb-1'>금액: {this.props.needs.invest_amount}억원 이상</Label>*/}
        {/*  <Label className='mb-1'>자금 필요 시기: {(this.props.needs.invest_time / 100).toFixed(0)}년 {this.props.needs.invest_time % 100}월</Label>*/}
        {/*</div>*/}

      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.close}>확인</Button>
        {
          this.props.isVC && this.props.isFestivalOpen && <Modal
            dimmer={'blurring'}
            onClose={() => this.close2()}
            onOpen={() => this.open2()}
            open={this.state.open2}
            trigger={
              <Button
                primary
                content="매칭 요청"
                labelPosition='right'
                icon='heart'
                onClick={() => this.open2()}
              />
            }
          >
            <Modal.Header>투자 매칭 요청하기</Modal.Header>
            <Modal.Content>
              <h3>아래 투자 매칭 요청 배경을 모두 작성해주세요.</h3>
              <Form>
                <Form.TextArea
                  required label='1. 사업 아이디어' name='idea'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='2. 기술력' name='tech'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='3. 시장 규모/속도' name='market'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='4. 수익성' name='profit'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='5. 기업 지배력' name='control'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='6. CEO 자질' name='ceo'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='7. 미래 시장 재편 (Game Changer)' name='future'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='8. 기타' name='etc'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  rows={2}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.close2()}>
                취소
              </Button>
              <Button
                primary
                content="확인"
                labelPosition='right'
                icon='checkmark'
                onClick={this.requestMatching}
              />
            </Modal.Actions>
          </Modal>
        }
      </Modal.Actions>
    </Modal>
  }
}