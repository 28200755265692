import AWS from 'aws-sdk'
import axios from "axios";

/**
 * File upload functions
 * - Put
 * - Delete
 * @author: HSY
 */

// UPLOAD RULE
// s3 upload - don't need encoding
// db save - need encoded due to comma
// so, you might use `decodeURI` to delete file using saved url in the db

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME
const REGION = process.env.REACT_APP_S3_REGION
const DIST_URL = process.env.REACT_APP_S3_CF_DIST_URL

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
})

const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION,
})

export const FileType = {
    public: "PUBLIC",
    shared: "SHARED",
    private: "PRIVATE",
};

// If you want to hashing-like feature, then use its uuid into *directory* parameter.
export const S3UploadFile = async (file, directory) => {
    const fileRefined = directory + "/" + file.name;

    const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileRefined,
    };

    try {
        await myBucket.putObject(params).promise();

        return {
            file: file.name,
            url: DIST_URL + "/" + directory + "/" + window.encodeURIComponent(file.name)
        }
    } catch (err) {
        console.log(err)
    }
}

export const uploadFile = async (file, directory = 'public', fileType, sharedUserUuids, sharedGroupUuids, sharedGroupSubtypeIds) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("directory", directory);

    if (directory && directory.split('/')[0] !== "public") {
        // Additional data for file protection
        if (fileType) formData.append("file_type", fileType);
        if (sharedUserUuids) formData.append("shared_user_uuids", sharedUserUuids);
        if (sharedGroupUuids) formData.append("shared_group_uuids", sharedGroupUuids);
        if (sharedGroupSubtypeIds) formData.append("shared_group_subtype_ids", sharedGroupSubtypeIds);
    }

    axios.post(`${process.env.REACT_APP_API_URL}/files/upload`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data; charset=UTF-8',
            },
            withCredentials: true,
        },
    ).then(res => {
        return {
            url: res.data.url,
            key: res.data.key,
        };
    }).catch((e) => {
        console.log(e);
        alert("로그인 후 이용 가능합니다.");
        return false;
    });
}

export const modifyFilePermission = async (fileKey, newFileType, addUserUuids, addGroupUuids, addGroupSubtypeIds, removeUserUuids, removeGroupUuids, removeGroupSubtypeIds) => {
    const formData = new FormData();
    formData.append("key", fileKey);
    if (newFileType) formData.append("file_type", newFileType);
    if (addUserUuids) formData.append("add_user_uuids", addUserUuids);
    if (addGroupUuids) formData.append("add_group_uuids", addGroupUuids);
    if (addGroupSubtypeIds) formData.append("add_group_subtype_ids", addGroupSubtypeIds);
    if (removeUserUuids) formData.append("remove_user_uuids", removeUserUuids);
    if (removeGroupUuids) formData.append("remove_group_uuids", removeGroupUuids);
    if (removeGroupSubtypeIds) formData.append("remove_group_subtype_ids", removeGroupSubtypeIds);

    axios.post(`${process.env.REACT_APP_API_URL}/files/permission/update`,
        formData,
        {
            withCredentials: true,
        },
    ).then(_ => {
        return true;
    }).catch((e) => {
        console.log(e);
        alert("접근 권한이 없습니다.");
        return false;
    });
}
