import './video.info.css'
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default function VideoArchiveCard({archive}) {
    const [videoInfo, setVideoInfo] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (archive.video_archives.length > 0) {
            getVideoData(archive.video_archives[0].video_uuid).then(() => setIsLoaded(true));
        }else {
            setIsLoaded(true)
        }
    }, [archive])

    async function getVideoData(video_uuid) {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/video/${video_uuid}`, {withCredentials: true});
        setVideoInfo(res.data)
    }

    return (
        <>
            {isLoaded &&
            <Link to={`/video/library/${archive.uuid}`}>
                <div className='hover-zoom pb-5' style={{overflowX: 'hidden'}}>
                    <div className='p-box-16by9 p-round-big '>
                        <img className='p-box-ch' src={archive.video_archives.length > 0 ?
                            `https://img.youtube.com/vi/${videoInfo.youtube_id}/0.jpg` :
                            'https://react.semantic-ui.com/images/wireframe/image.png'}
                             style={{objectFit: 'cover'}} alt='youtube thumbnail'/>

                        <div className={'archive-side'} style={{width: '40%'}}>
                            <Icon name={'list'}/>
                            <br/>
                            {archive.video_archives.length}
                        </div>
                    </div>
                    <div className={'mt-5 fs-2 fw-b pl-3 color-black'}> {archive.name} </div>
                </div>
            </Link>
            }
        </>
    );
}