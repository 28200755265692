import React, { Component } from "react";
import { Container, Grid, Icon } from "semantic-ui-react";
import IntroCard from "../Info/IntroCard";
import SectionTitle from "../Info/SectionTitle";
import { Link } from "react-router-dom";
import PreviewCitizen from "../Info/preview.citizen";
import UserContext from "../UserContext";
import InfoBizdayCards from "../bizday/info.bizday.cards";
// import ActvityListPage from "../activity/pages/activity.list.page";
// import ActvityList from "../activity/activity.list";

// 비즈니스 파트너
function isPar5(element) {
  if (element.id === 'par5') {
    return true;
  }
}
// VC/투자자 파트너
function isPar1(element) {
  if (element.id === 'par1') {
    return true;
  }
}

class InfoPage extends Component {
  static contextType = UserContext;

  render() {
    const user = this.context;

    return (
      <div className="p-container">
        <Container>
          <SectionTitle
            title={"SSP 프로그램을 소개합니다"}
            subtitle={"Startup Support Program - 밴처기업 지원 프로그램"}
          />
          <Grid doubling columns={4}>
            <Grid.Column>
              <Link to="/info/incubator">
                <IntroCard imgPath={"incubator"} title={"창업보육"} />
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Link to="/info/market">
                <IntroCard imgPath={"market"} title={"판로지원"} />
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Link to="/info/investment">
                <IntroCard imgPath={"investment"} title={"투자연계"} />
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Link to="/info/network">
                <IntroCard imgPath={"network"} title={"네트워크"} />
              </Link>
            </Grid.Column>
          </Grid>
          {
            user && user.group &&
            <>
              {
                (user.group.type === 'VENTURE' || user.group.subtype.find(isPar5)) &&
                  <>
                    <SectionTitle pt={'3rem'} title={'Biz Day'} subtitle={'국내 판로를 개척해보세요 - 내 진행상황 보기'}/>
                    <InfoBizdayCards/>
                  </>
              }
              {
                (user.group.type === 'VENTURE' || user.group.subtype.find(isPar1)) &&
                  <>
                    <SectionTitle pt={'3rem'} title={'투자 지원'} subtitle={'투자자를 만나보세요 - 내 투자 진행률 확인'}/>
                    <Grid doubling columns={4}>
                      <Grid.Column>
                        <Link to="/investment">
                          <IntroCard imgPath={'invest'} title={'투자유치'}/>
                        </Link>
                      </Grid.Column>
                    </Grid>
                  </>
              }


              <SectionTitle
                pt={"3rem"}
                title={"체인지업 그라운드 프로그램"}
                subtitle={
                  "준비 중..."
                  // "체인지업 그라운드 프로그램에 참여하세요 - 진행 중이거나 참여 중인 프로그램을 확인"
                }
              />

            </>
          }

          {/*<Grid doubling columns={2}>*/}
          {/*  <Grid.Column>*/}
          {/*    <ActvityList itemNum={5} emoji={'🎁'} title={'현재 진행 중인 프로그램'} url={'activity/status/open'}/>*/}
          {/*  </Grid.Column>*/}
          {/*  <Grid.Column>*/}
          {/*    <ActvityList itemNum={5} emoji={'🎈'} title={'내가 참여한 프로그램'} url = {'activity/mygroup'}/>*/}
          {/*  </Grid.Column>*/}
          {/*</Grid>*/}


          <SectionTitle
            pt={"3rem"}
            title={"기업시민 활동"}
            subtitle={"더불어 함께 발전하는 기업시민 POSCO"}
          />
          <PreviewCitizen />

          <Link to="/info/citizen">
            <div className="color-main has-text-right fs-1 pv-6 pr-3">
              <a className="color-main ph-3 pv-2 hover-gray p-round">
                모두 보기
              </a>
            </div>
          </Link>

          {/*<SectionTitle*/}
          {/*  pt={"3rem"}*/}
          {/*  title={"벤처밸리 스토리"}*/}
          {/*  subtitle={"벤처밸리에서 일어나는 우리들의 성장 스토리"}*/}
          {/*/>*/}
          {/*<Grid doubling stackable columns={3}></Grid>*/}

          {/*<Link to="/info/citizen">*/}
          {/*  <div className="color-main has-text-right fs-1 pv-6 pr-3">*/}
          {/*    <a className="color-main ph-3 pv-2 hover-gray p-round">*/}
          {/*      모두 보기*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</Link>*/}

          {user ? (
            <div>
              <SectionTitle
                pt={"3rem"}
                title={"산학연 플랫폼"}
                subtitle={
                  "포스텍, RIST의 연구성과, 포스코 창업 지원 및 사업화 정보를 한번에"
                }
              />
              <Grid doubling columns={4}>
                <Grid.Column>
                  <a href="http://ventureplatform.posco.com:8101/S23/S23A10/index.jsp">
                    <div className='p-card hover-zoom'>
                      <div className='pd-1'>
                        <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>산학연 플랫폼 <span
                          style={{color: '#005386', float: 'right'}}><Icon
                          name='external'/></span></p>
                      </div>
                      <div className='p-box-1by1'>
                        <div className='p-box-ch is-flex is-vcentered'>
                          <div className='pd-1' style={{width: '100%'}}>
                            <img style={{width: '100%', maxHeight: '300px'}} src={'/img/research.svg'}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </Grid.Column>
              </Grid>
            </div>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default InfoPage;
