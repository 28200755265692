import React, {Component} from "react";
import {Container, Tab} from 'semantic-ui-react'
import SubsChannelList from '../subscribe/subs.channel.list';
import SubscribeList from "../subscribe/subscribe.list";
import UserContext from "../UserContext";

const panes = [
  {
    menuItem: '구독 동영상',
    render: () => <SubscribeList/>,
  },
  {
    menuItem: '구독 채널',
    render: () => <SubsChannelList/>,
  },
];

class SubscribePage extends Component {
  static contextType = UserContext;

  render() {
    const user = this.context;

    return (
      <div className='p-container'>
        <Container>
          <Tab menu={{color: 'blue', secondary: true}} panes={panes}/>
          {
            user ? null : <p className='color-gray pl-1 pt-3'>로그인 후 이용 가능</p>
          }
        </Container>
      </div>
    );
  }

}

export default SubscribePage;
