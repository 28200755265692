import {Container, Icon, Loader} from "semantic-ui-react";
import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import {Link} from "react-router-dom";
import NotificationBox from "./notification.box";

class NotificationPage extends Component {
  state = {isLoading: true, notification: []}

  async componentDidMount() {
    this.getNotification();
  }

  getNotification() {
    axios.get(`${process.env.REACT_APP_API_URL}/notification`, {withCredentials: true}).then(res => {
      this.setState({
        isLoading: false,
        notification: res.data
      })
    });
  }

  openChat = (uuid) => {
    window.open(`/chat/room/${uuid}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  deleteNotification = (uuid) => {
    try {
      axios.delete(`${process.env.REACT_APP_API_URL}/notification/${uuid}`, {withCredentials: true}).then((res) => {
        this.getNotification();
      });
    } catch (e) {
      console.log(e);
    }
  }

  deleteNotificationAll = () => {
    try {
      axios.delete(`${process.env.REACT_APP_API_URL}/notification`, {withCredentials: true}).then((res) => {
        this.getNotification();
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    var beforeDate = '19700101';

    return (
      <div className='p-container'>
        <Container>
          <h1><Icon className='mr-5' name='bell'/>최근 알림 (BETA)</h1>
          <div className='mt-7'>
            {
              this.state.isLoading ? <Loader active inline='centered'/>
                :
                <>
                  {
                    this.state.notification.length === 0 && <p className='color-gray'>최근 알림이 없습니다.</p>
                  }
                  {this.state.notification.map((item, idx) => {
                    const isDateChanged = moment(item.created_at).format('YYYYMMDD') !== beforeDate
                    beforeDate = moment(item.created_at).format('YYYYMMDD');
                    return (
                      <>
                        {isDateChanged && <p className='`fw-b fs-1'>{moment(item.created_at).format('MM월 DD일')}</p>}
                        {
                          item.type === 'CHAT' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>
                            <div style={{flexGrow: 1}} className='pointer'
                                 onClick={() => this.openChat(item.content_uuid)}>
                              <p className='fw-b fs-1 mb-3 color-main'>
                                <Icon name='comment alternate'/> {item.title}
                              </p>
                              <NotificationBox item={item}/>
                            </div>
                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'VIDEO_COMMENT' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>
                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/detail?v=${item.content_uuid}`}>
                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='video'/> {item.title}
                                </p>
                                <NotificationBox item={item}/>
                              </Link>
                            </div>
                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'COMMUNITY_COMMENT' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>
                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/channel/post/${item.content_uuid}`}>

                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='comments'/> {item.title}
                                </p>
                                <NotificationBox item={item}/>
                              </Link>

                            </div>

                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'SYSTEM_NOTICE' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>

                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/more`}>

                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='server'/> {item.title}
                                </p>
                                <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis3L'>
                                  {item.content}
                                </p>
                                <p className='color-gray fs-m2 mt-1'>{moment(item.created_at).format('HH:mm')}</p>
                              </Link>

                            </div>

                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'NOTICE' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>

                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/more`}>

                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='bullhorn'/> {item.title}
                                </p>
                                <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis3L'>
                                  {item.content}
                                </p>
                                <p className='color-gray fs-m2 mt-1'>{moment(item.created_at).format('HH:mm')}</p>
                              </Link>
                            </div>

                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'BIZDAY' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>

                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/bizday/matched/detail/${item.content_uuid}`}>

                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='suitcase'/> {item.title}
                                </p>
                                <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis3L'>
                                  {item.content}
                                </p>
                                <p className='color-gray fs-m2 mt-1'>{moment(item.created_at).format('HH:mm')}</p>
                              </Link>
                            </div>

                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                        {
                          item.type === 'INVEST' &&
                          <div className='p-card ph-6 pv-5 mv-6 is-flex is-vcentered hover-zoom'>

                            <div style={{flexGrow: 1}} className='pointer'>
                              <Link to={`/investment/progress/${item.content_uuid}`}>

                                <p className='fw-b fs-1 mb-3 color-main'>
                                  <Icon name='line graph'/> {item.title}
                                </p>
                                <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis3L'>
                                  {item.content}
                                </p>
                                <p className='color-gray fs-m2 mt-1'>{moment(item.created_at).format('HH:mm')}</p>
                              </Link>
                            </div>

                            <span className='ml-5 pointer color-main'
                                  onClick={() => this.deleteNotification(item.uuid)}><Icon name='delete'/></span>
                          </div>
                        }
                      </>
                    )
                  })
                  }
                  {
                    this.state.notification.length > 0 && <p className='mv-7 has-text-centered fw-b pointer color-main'
                                                             onClick={this.deleteNotificationAll}>모든 알림 지우기</p>
                  }
                </>
            }
          </div>
        </Container>
      </div>
    );
  }
}

export default NotificationPage;