import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class RoundProfile extends Component {
  render() {
    return (
      this.props.slug ?
        <Link to={`/channel/${this.props.slug}`}>
          <img style={{
            border: '1px solid #eaeaea',
            objectFit: 'cover',
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
            borderRadius: `${this.props.size / 2}px`
          }} src={this.props.src ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile'/>
        </Link> :
        <div style={{flexShrink: 0}}>
          <img style={{
            border: '1px solid #eaeaea',
            objectFit: 'cover',
            width: `${this.props.size}px`,
            height: `${this.props.size}px`,
            borderRadius: `${this.props.size / 2}px`
          }} src={this.props.src ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile'/>
          {
            this.props.subProfile && <img style={{
              marginLeft: `-${this.props.size / 3.6}px`,
              border: '1px solid #eaeaea',
              objectFit: 'cover',
              width: `${this.props.size / 1.8}px`,
              height: `${this.props.size / 1.8}px`,
              borderRadius: `${this.props.size / 1.8}px`
            }} src={this.props.subSrc ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt='profile'/>
          }
        </div>
    )
  }
}