import React, {Component} from "react";
import {Container, Divider, Grid} from "semantic-ui-react";
import ProgramCard from "../program.card";
import PartnerSection from "../partner.section";
import axios from "axios";

class InfoMarketPage extends Component {
  state = {program: [], prevSection: ''};

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/program?type=MARK`).then((res) => {
      this.setState({
        program: res.data
      });
    });
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          <h1>판로지원</h1>

          <Grid doubling stackable columns={2} verticalAlign='middle'>
            {
              this.state.program.map((p) => {
                if (this.state.prevSection !== p.section) {
                  this.state.prevSection = p.section;
                  return <>
                    <Grid.Column width={16}>
                      <h3 className='mt-8'>{p.section}</h3>
                    </Grid.Column>
                    <Grid.Column>
                      <ProgramCard name={p.title} pId={p.uuid}/>
                    </Grid.Column>
                  </>
                } else {
                  return <Grid.Column>
                    <ProgramCard name={p.title} pId={p.uuid}/>
                  </Grid.Column>
                }
              })
            }
          </Grid>

          <h3 className='mt-8 mb-7'>협업 파트너</h3>
          <PartnerSection programType={'MARK'}/>

        </Container>
      </div>
    );
  }
}

export default InfoMarketPage;