import React, {Component} from 'react';
import "./navbar.css";
import { Link } from "react-router-dom";
import { Divider } from 'semantic-ui-react';
import SidebarItem from "./sidebar.item";
import SidebarSubs from './sidebar.subs';
import MediaQuery from "react-responsive/src";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <MediaQuery minWidth={1321}>
          <div className="p-sidebar p-sidebar-desktop">
            <div style={{flex: '1', overflowY: 'auto', padding: '20px 30px 20px 30px'}}>
              <Link to="/">
                <SidebarItem title={'홈'} icon={'home'}/>
              </Link>
              <Link to="/subscribe">
                <SidebarItem title={'구독'} icon={'bookmark'}/>
              </Link>
              <Link to="/market">
                <SidebarItem title={'마켓'} icon={'cart'}/>
              </Link>
              <Link to="/info">
                <SidebarItem title={'정보'} icon={'compass'}/>
              </Link>
              <Link to="/more">
                <SidebarItem title={'게시판'} icon={'ellipsis horizontal'}/>
              </Link>

              <Divider/>
              <Link to="/video/library">
                <SidebarItem title={'보관함'} icon={'th list'}/>
              </Link>
              
              <Link to="/video/history">
                <SidebarItem title={'시청 기록'} icon={'history'}/>
              </Link>

              <Link to="/video/likes">
                <SidebarItem title={'좋아요 누른 영상'} icon={'thumbs up'}/>
              </Link>
              <Divider/>

              <SidebarSubs/>

            </div>

            <div style={{flex: '0 0 auto'}}>
              <div className='has-text-centered pv-5'>
                <Link to={'/policy'}><span className='has-text-centered color-main'>이용약관 및 개인정보처리방침</span></Link>
              </div>
              <p style={{textAlign: 'center', padding: '0 1rem 1rem 1rem', color: '#878890', fontSize: '0.9em'}}>© POSCO SSP 2021<br/>Developed by <a href={'https://poapper.com'} style={{color: '#878890'}}>PoApper, Inc.</a></p>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={768} maxWidth={1320}>
          <div className="p-sidebar p-sidebar-tablet">
            <div style={{flex: '1', overflowY: 'auto', padding: '20px 30px 20px 30px'}}>
              <Link to="/">
                <SidebarItem title={''} icon={'home'}/>
              </Link>
              <Link to="/subscribe">
                <SidebarItem title={''} icon={'bookmark'}/>
              </Link>
              <Link to="/market">
                <SidebarItem title={''} icon={'cart'}/>
              </Link>
              <Link to="/info">
                <SidebarItem title={''} icon={'compass'}/>
              </Link>
              
              <Link to="/more">
                <SidebarItem title={''} icon={'ellipsis horizontal'}/>
              </Link>

              <Divider/>
              <Link to="/video/library">
                <SidebarItem title={''} icon={'th list'}/>
              </Link>
              <Link to="/video/history">
                <SidebarItem title={''} icon={'history'}/>
              </Link>
              <Link to="/video/likes">
                <SidebarItem title={''} icon={'thumbs up'}/>
              </Link>
              <Divider/>

            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}