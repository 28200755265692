import React, {Component} from "react";
import {Button, Checkbox, Container, Divider, Form, Grid, Icon, Image, Input, Label, Loader} from "semantic-ui-react";
import "./signup.css";
import axios from "axios";
import RoundProfile from "../utills/profile";
import {Link} from "react-router-dom";

class SignupVenturePage extends Component {

  constructor(props) {
    super(props);
    this.query = new URLSearchParams(this.props.location.search);
    this.state = {name: '', email: '', isLoaded: false, agreement: false, pathname: props.location.pathname}
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleClick = (e, { checked }) => this.setState(({ agreement }) => ({ agreement: !agreement }))

  signUp = () => {
    if (this.state.name.trim() === '') {
      alert("이름을 입력해주세요.")
      return;
    }

    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(this.state.email)) {
      alert("이메일 형식이 올바르지 않습니다.")
      return;
    }

    if (!this.state.agreement) {
      alert("이용약관 및 개인정보처리방침에 동의해주세요.")
      return;
    }

    // axios.post(`${process.env.REACT_APP_API_URL}/user/register-normal-venture`, {
    //   venture_name: this.state.ventureName,
    //   email: this.state.email,
    //   name: this.state.name,
    // }).then((res) => {
    //   alert("신청 성공! 이메일을 확인해주세요.");
    //   window.location.href="/";
    // }).catch((e) => {
    //   alert("이미 존재하는 이메일입니다.")
    //   console.log(e);
    // });
  }

  render() {
    return(
      <div className='p-container'>
        <Container>
          <Grid columns={2} stackable>
            <Grid.Column className='mt-8'>
              <Image size='tiny' src='/img/ssp-logo-blue.svg' />

              <h1 className='mb-5'>벤처 가입을 환영합니다!</h1>
              <p className='mt-0'>POSCO SSP 서비스에 벤처(기업) 계정을 신청하려면 아래 정보를 모두 입력해주세요.</p>
              <p>입력하신 기업 이름으로 그룹을 생성하여 해당 이메일로 임시 비밀번호를 보내드립니다.</p>
              <p>만약 <i>APGC 회원사, IMP Alumni, 포스코그룹 사내벤처팀, 포스코벤처펀드 투자유치기업, 예비창업 패키지 참여기업, 벤체밸리 기업협의회 회원사, 포항/서울/광양 체인지업 그라운드 입주기업</i> 소속이이라면 <b>poscossp@poapper.com</b>으로 연락주시면 추가로 권한을 부여해드립니다.</p>
              <div className='mt-7'>
                <Link to={this.state.pathname === '/m/signup' ? '/m/policy' : '/policy'}><span className='color-main fw-b'>이용약관 및 개인정보처리방침</span></Link>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className='has-item-centered is-col mt-7' style={{width: "100%"}}>
                <Form style={{width: "100%", maxWidth: "36rem"}}>
                  <Form.Input fluid required label='기업 이름' name='ventureName' placeholder='기업 이름을 입력해주세요.' value={this.state.venterName} onChange={this.handleChange}/>
                  <Form.Input fluid required label='대표자 이름' name='name' placeholder='대표자 이름을 입력해주세요.' value={this.state.name} onChange={this.handleChange}/>
                  <Form.Input fluid required label='이메일 주소' name='email' placeholder='이메일 주소를 입력해주세요.' value={this.state.email} onChange={this.handleChange}/>
                  <Form.Field>
                    <Checkbox onChange={this.handleClick} label={'이용약관 및 개인정보처리방침에 동의합니다.'}/>
                  </Form.Field>
                  <div className='pv-8'>
                    <span className='p-button fw-b' onClick={this.signUp}><Icon name='signup'/> 가입 신청하기</span>
                  </div>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default SignupVenturePage;
