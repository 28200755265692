import {Tab, Icon, Menu} from 'semantic-ui-react'
import {Component } from "react";
import NoticeCard from './widget/notice.card';
import "./more.css";

const panes = [
    {
         menuItem: (<Menu.Item key='notice'>  <h3> <Icon name='thumbtack'/> 공고 </h3>  </Menu.Item>),
         render: () => 
         <div>
                <NoticeCard itemNum={5} type={'notice'}/>
         </div>,
    },
    {
        menuItem: (<Menu.Item key='news'>  <h3> <Icon name='newspaper outline'/> 뉴스 </h3>  </Menu.Item>),
        render: () => {
          return (
            <div>
              <NoticeCard itemNum={5} type={'news'}/>
            </div>
          )
        }
    },
    {
        menuItem: (<Menu.Item key='sharing'>  <h3> <Icon name='gift'/> 정보 공유 </h3>  </Menu.Item>),

        render: () => 
         <div>
                <NoticeCard itemNum={5} type={'sharing'}/>
         </div>,
    },
  ];

export default class NoticeList extends Component {
    render() {
        return (
            <div className=''>
                
             <h1>SSP 공지사항 </h1>
                <Tab id='notice-tab' menu={{secondary: true}} panes={panes} />
            </div>
        );
    }
}   
