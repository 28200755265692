import React, {Component} from "react";
import {Icon} from "semantic-ui-react";
import '@toast-ui/editor/dist/toastui-editor.css';
import {Viewer} from "@toast-ui/react-editor";
import './channel.css';
import UserContext from "../UserContext";

export default class ChannelIntro extends Component {
  editorRef = React.createRef();

  refreshViewer = (content) => {
    this.editorRef.current.getInstance().setMarkdown(content);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps);
    this.refreshViewer(nextProps.description);
  }

  static contextType = UserContext;

  isOk(group) {
    if (group === null) return false;
    if (group.uuid === this.props.group.uuid) return true;
    else {
      if (group.type === "ADMIN") return true;
      for (const subtype of group.subtype) {
        if (subtype.id === 'par1') return true;
      }
    }
    return false;
  }
  decodeFileName(file) {
    const tmp = file.split('/');
    return decodeURIComponent(tmp[tmp.length - 1]);
  }

  render() {
    const user = this.context;

    return (
      <div>
        <div className='p-card pd-1 mv-6'>
          <Icon className='color-main' name='quote left'/>
          <p className={`mv-3 fs-1 ph-6 has-text-centered ${this.props.intro == null ? 'color-gray' : 'fw-b'}`}>{this.props.intro ?? '등록된 소개가 없습니다.'}</p>
          <Icon className='color-main' style={{float: 'right'}} name='quote right'/>
        </div>
        <div className='p-card pd-2 mv-6'>
          <div>
            <p className='fw-b fs-2'>자세한 소개</p>
            {
              this.props.description == null && <p className={'color-gray mb-0'}>등록된 내용이 없습니다.</p>
            }
            <Viewer
              ref={this.editorRef}
              initialValue={this.props.description}
            />
          </div>
        </div>

        {
          this.props.group.type === 'VENTURE' && <>
            <div className='p-card pd-2 mv-6'>
              <p className='fw-b fs-2'>IR 자료</p>
              {
                user ? <>
                    {
                      this.isOk(user.group) ? <>
                        {
                          this.props.attachments && this.props.attachments.length !== 0 ? this.props.attachments.map((file, idx) =>
                            <div>
                              <a className='color-black' href={file} key={idx}>
                                <Icon className='color-main mv-3' name='paperclip'/>
                                {
                                  this.decodeFileName(file)
                                }
                              </a>
                            </div>
                          ) : <p className='color-gray'>등록된 파일이 없습니다.</p>
                        }
                      </> : <p className='color-gray'>권한이 없습니다.</p>
                    }
                  </> :
                  <p className='color-gray'>로그인 후 확인할 수 있습니다.</p>
              }
            </div>
          </>
        }
      </div>
    )
  }
}