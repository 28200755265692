import React, {useEffect} from 'react'
import {Button, Icon, Input, Modal} from 'semantic-ui-react'
import axios from "axios";

export default function ArchiveModal(props) {
    const [archiveList, setArchiveList] = React.useState([])
    const [archiveCheckList, setArchiveCheckList] = React.useState([])
    const [archiveName, setArchiveName] = React.useState('')
    const [secondOpen, setSecondOpen] = React.useState(false)

    useEffect(async () => {
        if (props.open) await getMyArchive()
    }, [props.open])

    const getMyArchive = async () => {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/archive`, {withCredentials: true});

        setArchiveList(res.data)

        await getExistedArchive(res.data)
    }
    const getExistedArchive = async (archives) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/archive/video/${props.videoUuid}`, {withCredentials: true});

        let tempCheckList = Array.from({length: archives.length}, () => false);

        for (let i = 0; i < archives.length; i++) {
            for (let archive of res.data) {
                if (archives[i].uuid == archive.uuid) {
                    tempCheckList[i] = true;
                    break;
                }
            }
        }

        setArchiveCheckList(tempCheckList);
    }


    const addVideo = async (archiveUuid, videoUuid) => {
        return await axios.post(`${process.env.REACT_APP_API_URL}/archive/video_archive`, {
            archive_uuid: archiveUuid,
            video_uuid: videoUuid,
        }, {withCredentials: true});
    }

    const deleteVideo = async (archiveUuid, videoUuid) => {
        return await axios.delete(`${process.env.REACT_APP_API_URL}/archive/${archiveUuid}/video/${videoUuid}`, {withCredentials: true});
    }

    async function handleClick(idx) {
        try {
            let tempList = [...archiveCheckList];

            if (tempList[idx]) {
                await deleteVideo(archiveList[idx].uuid, props.videoUuid);
            } else {
                await addVideo(archiveList[idx].uuid, props.videoUuid);
            }

            tempList[idx] = !tempList[idx];

            setArchiveCheckList(tempList);


        } catch (e) {
            alert('오류가 발생했습니다. 문제가 계속된다면 문의해주세요.')
            console.log(e)
        }
    }

    const archiveNameChange = (e, data) => {
        setArchiveName(data.value)
    }

    const postArchive = async () => {
        if (archiveName.length < 1) {
            alert('보관함 이름을 작성해주세요.')
            return;
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/archive`, {
            name: archiveName,
        }, {withCredentials: true});

        await getMyArchive()
        setSecondOpen(false)
    }

    return (
      <>
          <Modal
            onClose={() => props.handleModal(false)}
            onOpen={() => props.handleModal(true)}
            open={props.open}
            size='tiny'
          >
              <Modal.Header>보관함에 추가하기</Modal.Header>
              <Modal.Content>
                  {
                      archiveList.length < 1 ? <p className={'color-gray'}>보관함이 없습니다. 새로운 보관함을 생성해 주세요.</p> :
                        archiveList.map((item, idx) => (
                          <div className={`pointer mb-3 border-a p-round pd-1 ${archiveCheckList[idx] && 'bg-lgray'}`} onClick={() => handleClick(idx)}>
                              {archiveCheckList[idx] && <Icon className={'mr-3'} name='check'/>}
                              {item.name}
                          </div>
                        ))
                  }
              </Modal.Content>
              <Modal.Actions>
                  <Button onClick={() => setSecondOpen(true)} primary>
                      <Icon name='add'/> 새로운 보관함 만들기
                  </Button>
              </Modal.Actions>

              <Modal
                onClose={() => setSecondOpen(false)}
                open={secondOpen}
                size='mini'
              >
                  <Modal.Header>새로운 보관함 생성하기</Modal.Header>
                  <Modal.Content>
                      <Input className={'full-w'} placeholder={'보관함 이름을 입력해주세요.'} onChange={archiveNameChange}/>
                  </Modal.Content>
                  <Modal.Actions>
                      <Button
                        primary
                        icon='check'
                        content='생성'
                        onClick={postArchive}
                      />
                      <Button
                        content='취소'
                        onClick={() => setSecondOpen(false)}
                      />
                  </Modal.Actions>
              </Modal>
          </Modal>
      </>
    )
}