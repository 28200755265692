import RoundProfile from "../../utills/profile";
import {Component} from "react";
import {Icon, Image, Popup} from "semantic-ui-react";

export default class GroupCard extends Component {
  render() {
    const group = this.props.group;
    return (
        <div className={'flip-in-hor-bottom'}>
      <div className={'p-card hover-zoom pointer pd-1 has-text-centered'}>
        <RoundProfile slug={group.channel_info.slug} size={80} src={group.profile_url}/>
        <div className='has-text-centered'>
          <p className='fw-n fs-1 mv-3 text-ellipsis has-text-centered color-black'>{group.name} {
            group.is_certified && <Popup
              inverted
              trigger={<Icon className='color-main fs-m2' name={'check circle'}/>}
              content='인증됨'
              position='right center'
            />
          }</p>
          {/*<span className='fs-0 color-gray'><Icon name='linkify'/>{group.channel_info.slug}</span>*/}
        </div>
          <div className={'has-text-centered text-ellipsis color-gray'}>
              {
                  group.subtype.length > 0 &&
                  group.subtype.map((tag) => {
                      return <span className="ui label fs-m1 mt-1">{tag.name}</span>
                  })
              }
          </div>
        <div className={'mt-6 mb-2 text-ellipsis mh-6'} onClick={(e) => e.stopPropagation()}>
            ㅤ
        {
          group.tags.length > 0 &&
            group.tags.map((tag) => {
              return <a href={`/search/?search_query=${window.encodeURIComponent(tag.name)}`}
                        className=" fs-m1 mt-1 mr-4 hover-gray ">#{tag.name}</a>
            })
          }
            <a href={`/search/?search_query=${window.encodeURIComponent(group.name)}`}
               className=" fs-m1 mt-1 mr-4 hover-gray ">#{group.name}</a>
        </div>
        {/*<p className={'fw-b color-main'}>구독자 수: {group.channel_info.subscriber_num} 명</p>*/}
      </div>
        </div>
    );
  }
}
