import React, {Component} from "react";
import {Button, Container, Divider, Icon, Input, Label, Radio} from "semantic-ui-react";
import axios from "axios";
import UserContext from "../UserContext";
import RoundProfile from "../utills/profile";
import FileUpload from "../utills/file_upload";
import {S3UploadFile} from "../utills/s3-file-upload";

class ProfilePage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      registerList: [],
      groupUserList: [],
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleProfileImage = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let image = event.target.files[0];
      fileReader.onloadend = async () => {
        const {file, url} = await S3UploadFile(image, event.target.name);
        axios.patch(`${process.env.REACT_APP_API_URL}/user/profile_url`, {profile_url: url}, {
          withCredentials: true,
        }).then(() => {
          alert('프로필 사진을 변경했습니다!');
          window.location.reload();
        }).catch((e) => {
          alert("프로필 사진 변경에 실패했습니다. " + e.response.data.message);
        });
      }
      fileReader.readAsDataURL(image);
    }
  }

  handleGroupProfileImage = (event) => {
    if (event.target.files.length) {
      const fileReader = new FileReader();
      let image = event.target.files[0];
      fileReader.onloadend = async () => {
        const {file, url} = await S3UploadFile(image, event.target.name);
        axios.patch(`${process.env.REACT_APP_API_URL}/group/profile_url`, {profile_url: url}, {
          withCredentials: true,
        }).then(() => {
          alert('프로필 사진을 변경했습니다!');
          window.location.reload();
        }).catch((e) => {
          alert("프로필 사진 변경에 실패했습니다. " + e.response.data.message);
        });
      }
      fileReader.readAsDataURL(image);
    }
  }

  addUser = () => {
    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(this.state.newEmail)) {
      alert('이메일 형식이 올바르지 않습니다.')
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/group/register/user`, {email: this.state.newEmail}, {
      withCredentials: true,
    }).then((res) => {
      this.getRegisterList();
      this.setState({
        newEmail: ''
      });
    }).catch((e) => {
      console.log(e);
      alert("이미 가입된 이메일입니다.");
    });
  }

  resendInvite = (event) => {
    axios.post(`${process.env.REACT_APP_API_URL}/group/register/resend/${event.target.getAttribute("data-uuid")}`, null, {
      withCredentials: true,
    }).then(() => {
      this.getRegisterList();
      alert("초대 링크를 재전송하였습니다.");
    }).catch((e) => {
      console.log(e);
      alert("이미 가입을 완료하였습니다.");
    });
  }

  getRegisterList() {
    axios.get(`${process.env.REACT_APP_API_URL}/group/register/list`, {
      withCredentials: true,
    }).then((res) => {
      this.setState({
        registerList: res.data.users
      })
    }).catch((e) => {
      console.log(e);
    });
  }

  getUserList() {
    axios.get(`${process.env.REACT_APP_API_URL}/group/user/list`, {
      withCredentials: true,
    }).then((res) => {
      this.setState({
        groupUserList: res.data.users
      })
    }).catch((e) => {
      console.log(e);
    });
  }


  componentDidMount() {
    this.getRegisterList();
    this.getUserList();

    // 푸시알림 설정 가져오기
    axios.get(`${process.env.REACT_APP_API_URL}/user/notification_setting`, {withCredentials: true}).then(res => {
      this.setState({
        email_subscription: res.data.email_subscription,
        push_notice: res.data.push_notice,
        push_group_chat: res.data.push_group_chat,
      })
    })
  }

  changePassword = () => {
    window.open(`${process.env.REACT_APP_KEYCLOAK_URL}/password`, '_self');
  }

  otpSetting = () => {
    window.open(`${process.env.REACT_APP_KEYCLOAK_URL}/totp`, '_self');
  }

  setNotification = (e, data) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/user/notification_setting`, {
      [data.name]: !this.state[data.name]
    }, { withCredentials: true }).then((res) => {
      this.setState({
        [data.name]: !this.state[data.name]
      })
    });
  }

  render() {
    const user = this.context;

    return (
      <div className='p-container'>
        <Container>
          <h1>프로필</h1>
          {
            user ?
              <div>
                <h3>내 정보</h3>
                <div className='p-card pd-2 has-item-centered is-col mt-7'>
                  <RoundProfile size={80} src={user.profile_url}/>

                  <FileUpload
                    className='mt-5'
                    label="프로필 사진 변경"
                    accept="image/*"
                    name="user_profile"
                    handleChange={this.handleProfileImage}
                  />

                  <p className='fw-b fs-1 mt-5 mb-0'>{user.name}</p>
                  <p className='color-gray'>{user.email}</p>
                  {
                    user.group && <p className='fw-b'>
                      <Label>{(user.user_type === 'ADMIN' || user.user_type === 'OWNER') ? '관리자' : '맴버'}</Label> {user.group.name}
                    </p>

                  }

                  <div className='is-flex mt-5' style={{flexWrap: 'wrap', justifyContent: 'center'}}>
                    <Button onClick={this.changePassword} basic className='mb-2'><Icon name='key'/> 패스워드 변경</Button>
                    <Button onClick={this.otpSetting} basic icon='settings' className='mb-2'><Icon name='lock'/> OTP 설정</Button>
                  </div>

                  <div className='mt-8' style={{
                    width: "100%",
                    maxWidth: "480px",
                  }}>
                    <div className='is-flex is-vcentered' style={{justifyContent: "space-between"}}>
                      <div className='mr-5'>
                        <p className='fw-b mb-0'>공지 이메일 구독</p>
                        <p className='fs-m1 color-gray'>공지 게시판에 새로운 글이 등록되면 이메일로 알림을 받습니다.</p>
                      </div>
                      <Radio toggle name='email_subscription' checked={this.state.email_subscription} onChange={this.setNotification}/>
                    </div>

                    <div className='is-flex is-vcentered mt-5' style={{justifyContent: "space-between"}}>
                      <div className='mr-5'>
                        <p className='fw-b mb-0'>공지 푸시 알림</p>
                        <p className='fs-m1 color-gray'>공지 게시판에 새로운 글이 등록되면 앱으로 푸시 알림을 받습니다.</p>
                      </div>
                      <Radio toggle name='push_notice' checked={this.state.push_notice} onChange={this.setNotification}/>
                    </div>

                    <div className='is-flex is-vcentered mt-5' style={{justifyContent: "space-between"}}>
                      <div className='mr-5'>
                        <p className='fw-b mb-0'>그룹 채팅 알림</p>
                        <p className='fs-m1 color-gray'>본인이 속한 그룹으로 채팅이 오면 앱으로 푸시 알림을 받습니다.</p>
                      </div>
                      <Radio toggle name='push_group_chat' checked={this.state.push_group_chat} onChange={this.setNotification}/>
                    </div>
                  </div>
                </div>

                {
                  user.group && <>
                    <h3>내 그룹 정보</h3>
                    <div className='p-card pd-2 has-item-centered is-col mt-7'>
                      <RoundProfile size={80} src={user.group.profile_url}/>
                      {
                        (user.user_type === 'ADMIN' || user.user_type === 'OWNER') &&
                        <FileUpload
                          className='mt-5'
                          label="프로필 사진 변경"
                          accept="image/*"
                          name="group_profile"
                          handleChange={this.handleGroupProfileImage}
                        />
                      }
                      <p className='fw-b fs-1 mt-5 mb-0'>{user.group.name}</p>
                      <p className='color-gray'>{user.group.type}</p>

                      <div style={{textAlign: 'center'}}>
                        {user.group.subtype.map((type, idx) => <Label key={idx}
                                                                      className='mb-1'>{type.name}</Label>)}
                      </div>

                    </div>

                  </>
                }

                {
                  (user.user_type === 'ADMIN' || user.user_type === 'OWNER') &&
                  <>
                    <h3>내 그룹 관리</h3>
                    <div className='p-card pd-2 has-item-centered is-col mt-7'>
                      <p className='fs-1 fw-b'>그룹 사용자를 초대해보세요.</p>

                      <div className='is-flex' style={{width: "100%", maxWidth: "480px"}}>
                        <Input style={{flexGrow: 1}} fluid icon='at' iconPosition='left'
                               placeholder='Email' name='newEmail'
                               value={this.state.newEmail}
                               onChange={this.handleChange}/>
                        <Button icon='add' className='ml-2' onClick={this.addUser}/>
                      </div>

                      {
                        this.state.registerList.map((user) =>
                          <div className='is-flex mt-5' style={{
                            width: "100%",
                            maxWidth: "480px",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}>
                            <span className='color-gray pl-5' style={{marginLeft: '40px'}}><i>{user.email}</i></span>
                            <Button data-uuid={user.uuid} onClick={this.resendInvite}>초대 링크
                              재전송</Button>
                          </div>
                        )
                      }

                      {
                        this.state.groupUserList.map((user) => <div
                          className='mt-6 is-flex p-item-vcentered'
                          style={{width: "100%", maxWidth: "480px"}}>
                          <RoundProfile src={user.profile_url} size={45}/>
                          <div className='ml-5' style={{flexGrow: 1}}>
                            <p className='mb-1 fw-b'>{user.name} <Label>{user.user_type === 'NORMAL' ? '맴버' : '관리자'}</Label></p>
                            <p>{user.email}</p>
                          </div>
                        </div>)
                      }
                    </div>
                  </>
                }
              </div>
              : <p className='color-gray'>로그인 후 이용가능</p>
          }
        </Container>
      </div>
    )
  }
}

export default ProfilePage;
