import React, {Component} from "react";
import {
  Container, Icon, Label, Loader, Tab,
} from "semantic-ui-react";
import "../bizday.css";
import MatchedNeedsTab from "./tabs/matched.needs.tab";
import MyNeedsTab from "./tabs/my.needs.tab";
import OthersNeedsTab from "./tabs/others.needs.tab";
import RequestNeedsTab from "./tabs/request.needs.tab";
import axios from "axios";
import UserContext from "../../UserContext";


class BizdayPage extends Component {
  static contextType = UserContext;
  state = {isLoaded: false, groupName: '', bizId: '', status: false};

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        bizId: res.data.uuid,
        bizday: res.data,
        groupName: res.data.biz_group.name,
        slug: res.data.biz_group.slug,
        status: res.data.status
      })
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const user = this.context;

    const panes = [
      {
        menuItem: '매칭된 사업',
        render: () => <MatchedNeedsTab bizId={this.state.bizId}/>,
      },
      {
        menuItem: '내 제안 사업',
        render: () => <MyNeedsTab slug={this.state.slug} bizId={this.state.bizId} status={this.state.status}/>,
      },
      {
        menuItem: `${user ? (user.group.type === 'VENTURE' ? this.state.groupName : '벤처') : ''} 사업`,
        render: () => <OthersNeedsTab slug={this.state.slug} bizId={this.state.bizId} needsType={user ? (user.group.type === 'VENTURE' ? 'PARTNER' : 'VENTURE') : ''}/>,
      },
      {
        menuItem: '내가 보낸 매칭 요청',
        render: () => <RequestNeedsTab bizId={this.state.bizId}/>,
      },
    ];
    return (
      <div className='p-container'>
          <Container>
            {
              this.state.isLoaded ?
                <div>
                  <h1>{this.state.bizday.title}</h1>
                  <div className='p-card pv-5 ph-6 mv-6'>
                    <div className='is-flex'>
                      <p className='fw-b' style={{flexGrow: 1}}><Icon name='bullhorn'/> {this.state.bizday.notice ?? ''}</p>
                      <div style={{flexShrink: 0, textAlign: 'center'}}>
                        <Icon className={`fs-3 ${this.state.bizday.status ? 'color-main' : 'color-gray'}`} name={this.state.bizday.status ? 'toggle on' : 'toggle off'}/>
                        <p className={`fw-b ${this.state.bizday.status ? 'color-main' : 'color-gray'}`}>{this.state.bizday.status ? '모집 중' : '모집 마감'}</p>
                      </div>
                    </div>
                    <p className='fw-b'><Icon name='pin'/> 참고 사항</p>
                    <p style={{wordBreak: 'break-word', whiteSpace: 'pre-wrap'}}>{this.state.bizday.notes}</p>

                    <p className='fw-b'><Icon name='tag'/>모집 카테고리</p>
                    <div>
                      {
                        this.state.bizday.category_1.map((cat) => <Label className='mb-1'>{cat}</Label>)
                      }
                    </div>
                    <div>
                      {
                        this.state.bizday.category_2.map((cat) => <Label className='mb-1'>{cat}</Label>)
                      }
                    </div>
                  </div>

                  <Tab id='biz-tap' menu={{secondary: true}} panes={panes}/>

                </div>
                : <Loader active inline='centered' />
            }
          </Container>
      </div>
    );
  }
}

export default BizdayPage;