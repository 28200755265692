import {Component} from "react";
import RoundProfile from "../utills/profile";

export default class ChannelItem extends Component {
  render() {
    return (
      <div className='pv-3' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <RoundProfile slug={this.props.channel.slug} size={30} src={this.props.channel.profile_url}/>
        <p className='fw-n pl-5 text-ellipsis mb-0'>{this.props.channel.name}</p>
        {/*<span className='fs-1 color-main' >•</span>*/}
      </div>
    );
  }
}
