import React, {Component} from "react";
import {Grid, Icon, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default class VCInvestFestivalTab extends Component {
  state = {isLoaded: false, openInvList: [], closeInvList: []};

  async componentDidMount() {
    const openRes = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival?status=open`, {withCredentials: true});
    const closeRes = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival?status=close`, {withCredentials: true});

    this.setState({
      openInvList: openRes.data,
      closeInvList: closeRes.data,
      isLoaded: true,
    });
  }

  render() {
    return (
      <div>

        <h3 className='mt-7'>진행 중인 투자 행사</h3>
        <Link to={'/investment/vc/festival/always'}>
          <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
            <p className='fw-b fs-1 color-black'>상시 모집</p>
            <p className='color-main mb-3'><Icon name='group'/> 모든 투자자</p>
            <p className='color-main'><Icon name='time'/> 상시 접수 중</p>
          </div>
        </Link>
        {
          this.state.openInvList.map((e) =>
            <Link to={`/investment/vc/festival/${e.uuid}`}>
              <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
                <p className='fw-b fs-1 color-black'>{e.title}</p>
                <p className='color-main mb-3'><Icon name='group'/> 참여 VC - {e.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < e.host_groups.length ? ', ' : ''}</span>)}</p>
                <p className='color-main'><Icon name='time'/> 신청기간 - {moment(e.start_at).format('MM월 DD일 HH:mm')} ~ {moment(e.end_at).format('MM월 DD일 HH:mm')}</p>
              </div>
            </Link>
          )
        }

        <h3 className='mt-7'>종료된 투자 행사</h3>
        {
          this.state.isLoaded && this.state.closeInvList.length === 0 && <p className='color-gray pd-1'>종료된 투자 행사가 없습니다.</p>
        }
        {
          this.state.closeInvList.map((e) =>
            <Link to={`/investment/vc/festival/${e.uuid}`}>
              <div className='p-card pv-5 ph-7 hover-zoom pointer mv-6'>
                <p className='fw-b fs-1 color-black'>{e.title}</p>
                <p className='color-gray mb-3'><Icon name='group'/> 참여 VC - {e.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < e.host_groups.length ? ', ' : ''}</span>)}</p>
                <p className='color-gray'><Icon name='time'/> 신청기간 - {moment(e.start_at).format('YYYY년 MM월 DD일')} ~ {moment(e.end_at).format('YYYY년 MM월 DD일')}</p>
              </div>
            </Link>
          )
        }

      </div>
    );
  }
}