import MarketList from "../Market/market.list";
import CategoryList from "../Market/category.list";
import {Container} from "semantic-ui-react";
import axios from "axios";
import {useState, useEffect} from "react";
import MarketItemLoadingList from "../Main/widget/market.item.loading.list";

function clone(obj) {
  if (obj === null || typeof(obj) !== 'object') return obj;
  var copy = obj.constructor();
  for (var attr in obj) if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  return copy;
}

function MarketPage() {
  const marketLists = [
    {
      title: '최신 상품',
      url: `${process.env.REACT_APP_API_URL}/market/item?`,
    },
    {
      title: '인기 상품',
      url: `${process.env.REACT_APP_API_URL}/market/item/orderBy/likes?`,
    }
  ];


  return (
    <div className='p-container'>
      <div style={{maxWidth: '1400px', margin: '0 auto', padding: '1rem'}}>
        <CategoryList/>
        {
          marketLists.map((item) => {
            return (
                <>
                <h1>{item.title}</h1>
                <MarketItemLoadingList url={item.url} take={6} moreBtn/>
                </>
            )
          })
        }
      </div>
    </div>
  );
}

export default MarketPage;