import {Component} from "react";
import YoutubeCard from "../../Youtube/youtube.card";
import axios from "axios";
import {Link} from "react-router-dom";
import GroupCard from "./group.card";
import {Loader} from "semantic-ui-react";


export default class GroupLoadingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoList: [],
            isLoad: true,
            prevY: 0,
        }
        this.skip = 0;
        this.videoEnd = false; // 모든 영상을 가져왔는지 확인
        this.startTime = 0; // 마지막으로 스크롤이 맨 끝에 닿은 시간
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
    }

    trackScrolling=()=> {
        if(new Date() - this.startTime < 700) return

        const wrappedElement = document.getElementById('videoListContainer');
        if (this.isBottom(wrappedElement)) {
            console.log('is bottom!')
            this.startTime = new Date();
            this.getData()
        }
    };

    componentDidMount() {

        this.initialize()

        if(this.props.moreBtn) return
        document.addEventListener('scroll', this.trackScrolling);
    }

    initialize() {
        this.startTime = new Date();
        this.videoEnd = false;
        this.skip = 0;
        this.setState({
            videoList: [],
            isLoad: true,
            prevY: 0,
        }, () => this.getData())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.url == this.props.url) return
        this.initialize()
    }

    componentWillUnmount() {
        if(this.props.moreBtn) return
        document.removeEventListener('scroll', this.trackScrolling);
    }

    getData = async () => {
        if(this.videoEnd) return;

        this.setState({
            isLoad: true,
        })
        const res = await axios.get(`${this.props.url}take=${this.props.take}&skip=${this.skip}`)
        if(res.data.length < this.props.take) this.videoEnd = true
        this.skip = this.skip + this.props.take;
        this.setState({
            videoList: this.state.videoList.concat(res.data),
            isLoad: false,
        })
    }

    render() {

        return (
            <div id={'videoListContainer'}>
                {this.state.isLoad && <Loader className={'mt-9'} active inline='centered'/>}
                {
                    this.state.videoList.length > 0 ?
                        <div>
                            <div className='auto-grid'>
                                {this.state.videoList.map((group, idx) => (
                                    <Link to={`/channel/${group.channel_info.slug}`}>
                                        <GroupCard group={group}/>
                                    </Link>
                                ))}
                            </div>
                            {this.props.moreBtn && !this.videoEnd &&
                            <div className="color-main has-text-right fs-1 pv-6 pr-3">
                                <span className="color-main ph-3 pv-1 hover-gray p-round pointer"
                                      onClick={this.getData}>
                                    {this.props.title} 더보기
                                </span>
                            </div>
                            }
                        </div>
                        :
                        !this.state.isLoad && <p className='color-gray'>등록된 컨텐츠가 없습니다.</p>
                }
            </div>
        );
    }

}