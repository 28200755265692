import YoutubePage from "../Main/widget/YoutubeList";
import Carousel from "../Main/widget/carousel";
import {useEffect, useState} from "react";
import axios from "axios";
import ResizedCarousel from "../Main/widget/carousel";
import {Button, Container, Divider, Icon, Image} from "semantic-ui-react";
import MainVideoList from "../Main/main.video.list";
import GroupList from "../Main/widget/group.list";
import {Link} from "react-router-dom";
import '../utills/transition.css'
import CountUp from "react-countup";
import MediaQuery from "react-responsive/src";

function MainPage() {
    const [keywords, setKeywords] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupCount, setGroupCount] = useState({ venture: 0, partner: 0 });

    useEffect(async () => {
        try {
            const groupCount = await axios.get(`${process.env.REACT_APP_API_URL}/group/count/details`);
            setGroupCount(groupCount.data);

            const keywordRes = await axios.get(`${process.env.REACT_APP_API_URL}/keyword`, {withCredentials: true});
            setKeywords(keywordRes.data)

            const groupsRes = await axios.get(`${process.env.REACT_APP_API_URL}/group/random?take=10`, {withCredentials: true});
            setGroups(groupsRes.data)
        } catch(e) {
            console.log(e);
        }
    }, []);

  return (
      <>
        {/*나중에 오픈...*/}
        <MediaQuery minWidth={900}>
          <div className='pv-7 mb-8'>
            <div className='p-container is-flex is-centered p-item-vcentered' style={{maxWidth:'1600px'}}>
              <div style={{flexGrow: 1}}>
                <Image className='ph-5' size='small' src='/img/ssp-logo-blue.svg'/>
                <div className='fs-2 fw-b mb-7 mt-7 ph-5' style={{lineHeight: '2.5rem'}}>
                  <div className='mb-8 color-main'>벤처들의 플레이그라운드, POSCO Digital SSP에 오신 것을 환영합니다!</div>
                  <span className='fs-3 fw-b'>
                <CountUp
                  duration={1}
                  separator=","
                  start={0}
                  end={groupCount.venture}/>
              </span> 개 벤처와 <span className='fs-3 fw-b'>
                <CountUp
                  duration={1}
                  separator=","
                  start={0}
                  end={groupCount.partner}/>
              </span> 개 파트너를 한 곳에서 만나보세요!
                </div>
                <div className='fs-m1 color-gray ph-5'>
                  잘못 입력된 기업 정보, 부정한 방법의 서비스 이용, 상호간의 커뮤니케이션 부재 등으로 발생한 손해 또는 투자 손실 발생시 (주)포애퍼는 절대 책임지지 않습니다.
                </div>
              </div>
              <Image size='large' src='/img/start-up.svg' className='is-centered'/>
            </div>
          </div>
        </MediaQuery>

        <div style={{maxWidth: '1600px'}} className='mt-7 is-centered'>
              <ResizedCarousel keywords={keywords}/>
          </div>
          <div className='p-container is-centered pt-9' style={{maxWidth:'1600px'}}>
              <h1 className='fw-b ph-4'>원하는 벤처들을 찾아보세요 👀</h1>
              <div className={'pd-1'} style={{height:'500px', overflow:'hidden'}}>
                  <GroupList groups={groups}>
                      <Link to={'group/search'}>
                      <div className={'p-card flip-in-hor-bottom has-item-centered is-col hover-zoom pointer full-wh pd-1'} style={{height:'228.44px'}}>
                          <Icon className={'color-main box bounce-distortion'} name={'right arrow circle'} size={'huge'}/>
                          <p className={'mt-5 fw-b fs-1 color-black'}>더 많은 기업 알아보기</p>
                      </div>
                      </Link>
                  </GroupList>
              </div>
              <MainVideoList/>
          </div>
      </>

  );
}

export default MainPage;