import React, {Component} from "react";
import {
  Container, Tab,
} from "semantic-ui-react";
import MyInvestTab from "./my.invest.tab";
import InvestFestivalTab from "./invest.festival.tab";
import UserContext from "../UserContext";
import VCInvestFestivalTab from "./vc.invest.festival.tab";
import VCMyInvestTab from "./vc.my.invest.tab";

class InvestmentPage extends Component {
  static contextType = UserContext;
  state = {isLoaded: true, activeIndex: 0};

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
    this.props.history.push(`/investment/${activeIndex === 0 ? '#festival' : '#my-invest'}`);
  }

  render() {
    const user = this.context;
    if (!user) return <div>Forbidden</div>

    this.state.activeIndex = this.props.location.hash === '#my-invest' ? 1 : 0

    const panes = [
      {
        menuItem: '진행 중인 모집',
        render: () => user.group.type === 'VENTURE' ? <InvestFestivalTab/> : <VCInvestFestivalTab/>,
      },
      {
        menuItem: '내 투자 현황',
        render: () => user.group.type === 'VENTURE' ? <MyInvestTab/> : <VCMyInvestTab/>,
      },
    ];

    return (
      <div className='p-container'>
        {
          this.state.isLoaded ?
            <Container>
              <h1>투자유치</h1>
              {
                user.group.type === 'VENTURE' ?
                  <>
                    <p className='color-gray'><b>'내 투자 현항'</b> 탭에 투자 니즈를 올린뒤 <b>'진행 중인 모집'</b> 탭에서 원하는 투자자에게 투자 요청을 할 수 있습니다.</p>
                    <p className='color-gray fw-b'>투자 니즈를 올려둔 경우 상시 매칭이 생길 수도 있습니다.</p>
                  </>
                  :
                  <>
                    <p className='color-gray'><b>'진행 중인 모집'</b> 탭에서 투자 매칭을 요청할 수 있으며, <b>'내 투자 현항'</b> 탭에서 매칭된 벤처 니즈들을 확일할 수 있습니다.</p>
                    <p className='color-gray fw-b'>상시 모집은 모든 투자자 그룹이 가능하며, 행사 모집의 경우 포스코 SSP 담당자에게 요청한 그룹만 매칭 요청이 가능합니다.</p>
                  </>
              }
              <Tab className='mv-7' onTabChange={this.handleTabChange} activeIndex={this.state.activeIndex} id='biz-tap' menu={{secondary: true}} panes={panes}/>

            </Container>
            : null
        }
      </div>
    );
  }
}

export default InvestmentPage;