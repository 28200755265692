import {Component} from "react";
import {Container, Divider} from "semantic-ui-react";

export default class SectionTitle extends Component {
  render() {
    return (
      <div className='mb-7' style={{paddingTop: this.props.pt}}>
        <h1>{this.props.title}</h1>
        <p className='color-gray'>{this.props.subtitle}</p>
      </div>
    );
  }
}