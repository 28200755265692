import {Component} from "react";
import {Grid} from "semantic-ui-react";
import CitizenCard from "./CitizenCard";
import axios from "axios";

export default class PreviewCitizen extends Component {
  state = {posts: []}

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen?skip=0&take=3`);
      this.setState({
        posts: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Grid doubling stackable columns={3}>
        {this.state.posts.map((post, idx) =>
          <Grid.Column>
            <CitizenCard post={post}/>
          </Grid.Column>
        )}
      </Grid>
    )
  }
}