import { Card, Divider, Placeholder, Pagination, Icon } from "semantic-ui-react";
import { Component } from "react";
import axios from "axios";
import moment from "moment";

export default class SurveyCard extends Component {
  total = 0;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      board: [],
      activePage: 1,
    };
  }

  getData() {
    this.setState({ isLoading: true });
    console.log((this.state.activePage - 1) * this.props.itemNum);
    axios.get(`${process.env.REACT_APP_API_URL}/board/survey?skip=${(this.state.activePage - 1) * this.props.itemNum}&take=${this.props.itemNum}`).then((res) => {
        setTimeout(
          function () {
            //Start the timer
            this.setState({
              isLoading: false,
              board: res.data,
            });
          }.bind(this),
          500
        );
      });
  }

  getCount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/board/survey/item_num`)
      .then((res) => {
        this.total = parseInt(res.data);
        // console.log(this.total);
        this.getData();
      });
  }

  componentDidMount() {
    this.getCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.total = 0;
      this.state.activePage = 1;
      // console.log(this.props.type);
      this.getCount();
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.state.activePage = activePage;
    // this.setState({ activePage });
    this.getData();
  };

  render() {
    return (
      <div>
        <div className='p-card'>
          {!this.state.isLoading ? (
            <div>
              {this.state.board.map((post, idx) => (
                <a href={`${post.link_url}`} className='color-black'>
                  <div className='is-row p-item-vcentered hover-gray pv-5 ph-6' style={{justifyContent:'space-between'}}>
                    <div>
                      <div className="color-main fs-m1 mb-2">
                        <Icon name="calendar check" />
                        {moment(post.start_at).format("MM월 DD일")}{" ~ "}
                        {moment(post.end_at).format("MM월 DD일 HH:mm")}
                      </div>

                      {post.title}
                    </div>

                    <Icon className='color-main' name='external'/>
                  </div>
                  <Divider fitted style={{ borderBottom: "none" }} />
                </a>
              ))}

              <div className="has-item-centered">
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={this.state.activePage}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={2}
                  totalPages={parseInt(
                    (this.total - 1) / this.props.itemNum + 1
                  )}
                  pointing
                  secondary
                  onPageChange={this.handlePaginationChange}
                />
              </div>
            </div>
          ) : (
            <div>
              {[...Array(this.props.itemNum)].map((n, index) => {
                return (
                  <div>
                    <div className="pv-6 ph-6" style={{ height: "67px" }}>
                      <Placeholder>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </div>
                    <Divider fitted style={{ borderBottom: "none" }} />
                    {/*{ index !== this.props.itemNum - 1 ? <Divider fitted style={{ borderBottom: "none" }} /> : null }*/}
                  </div>
                );
              })}
              <div
                style={{ height: 40 }}
                className="has-item-centered is-vcentered is-flex"
              >
                <span>⟨</span>
                <Placeholder
                  style={{ height: 20, width: 20 }}
                  className="ml-7 mr-5"
                >
                  <Placeholder.Image />
                </Placeholder>
                <span> </span>
                <Placeholder style={{ height: 20, width: 20 }} className="mr-7">
                  <Placeholder.Image />
                </Placeholder>
                <span>⟩</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
