import React from 'react';
import YouTube from "react-youtube";

export default class YoutubePlayer extends React.Component {
  render() {
    const opts = {
      playerVars: {
        autoplay: 0,
        controls: 1
      },
    };

    return (
      <div className="videoWrapper"> 
        <YouTube videoId={this.props.videoId} opts={opts}/> 
      </div>
    );
  }
}
