import React, {Component} from "react";
import {Button, Container, Form, Grid, Icon, Label, Modal} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import RoundProfile from "../utills/profile";
import moment from "moment";
import InvestNeedsCard from "./widgets/invest.needs.card";

export default class InvestFestivalPage extends Component {
  state = {isLoaded: false, isLoaded2: false, needs: [], requestedNeeds: [], myNeedsId: '', opinion: ''};

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/festival/${this.props.match.params.uuid}`, {withCredentials: true});
    const needs = await axios.get(`${process.env.REACT_APP_API_URL}/invest/needs`, {withCredentials: true});

    this.getMyRequestNeeds();

    this.setState({
      festival: res.data,
      needs: needs.data,
      isLoaded: true,
    });
  }

  getMyRequestNeeds() {
    axios.get(`${process.env.REACT_APP_API_URL}/invest/apply/festival/${this.props.match.params.uuid}`, {withCredentials: true}).then((res) => {
      this.setState({
        isLoaded2: true,
        requestedNeeds: res.data
      })
    });
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  requestMatching = async () => {
    console.log(this.state.opinion)
    if (this.state.myNeedsId === '') {
      alert('투자 요청할 내 니즈를 선택해주세요.');
      return;
    }
    if (this.state.opinion === '') {
      alert('의견을 입력해주세요.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/apply/festival/`, {
        festival_id: this.props.match.params.uuid,
        needs_id: this.state.myNeedsId,
        opinion: this.state.opinion
      }, {withCredentials: true});
      alert('투자 요청을 했습니다.')
      this.close();
      this.getMyRequestNeeds();
    } catch (e) {
      alert(e.response.data.message);
    }
  }

  render() {
    const labelOpt = (items) => {
      const opt = [];
      for (const e of items) {
        opt.push({
          key: e.uuid,
          text: `${e.invest_phase} | ${e.invest_amount}억원 이상`,
          value: e.uuid,
          description: `${moment(e.created_at).format('YYYY년 MM월 DD일')}`,
          image: {avatar: true, src: e.group.profile_url},
        })
      }
      return opt;
    }

    return (
      <div className='p-container'>
        <Container>
          {
            this.state.isLoaded &&
            <>
              <h1>{this.state.festival.title}</h1>
              {
                this.state.festival.state ?
                  <>
                    <p className='color-main mb-3'><Icon name='group'/> 참여 VC - {this.state.festival.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < this.state.festival.host_groups.length ? ', ' : ''}</span>)}</p>
                    <p className='color-main'><Icon name='time'/> 신청기간 - {moment(this.state.festival.start_at).format('MM월 DD일 HH:mm')} ~ {moment(this.state.festival.end_at).format('MM월 DD일 HH:mm')}</p>
                  </>
                  :
                  <>
                    <p className='color-gray mb-3'><Icon name='group'/> 참여 VC - {this.state.festival.host_groups.map((h, idx) => <span>{h.name}{idx + 1 < this.state.festival.host_groups.length ? ', ' : ''}</span>)}</p>
                    <p className='color-gray'><Icon name='time'/> 신청기간 - {moment(this.state.festival.start_at).format('YYYY년 MM월 DD일')} ~ {moment(this.state.festival.end_at).format('YYYY년 MM월 DD일')}</p>
                  </>
              }
              <p className='color-gray mb-6'>
                {
                  this.state.festival.description.split('\n').map((str) => {
                    return (<div>{str}</div>)
                  })
                }
              </p>

              <h3>내가 요청한 투자 니즈</h3>
              {
                this.state.isLoaded2 && this.state.requestedNeeds.length === 0 ?
                  <p className='color-gray pd-1'>요청한 투자 니즈가 없습니다.</p> : null
              }
              <Grid columns={3} doubling stackable>
                {
                  this.state.requestedNeeds.map((needs) =>
                    <Grid.Column>
                      <InvestNeedsCard needs={needs.inv_needs} opinion={needs.venture_opinion}/>
                    </Grid.Column>
                  )
                }
              </Grid>

              {
                this.state.festival.status &&
                <div className='mv-8' onClick={this.open}>
                  <span className='p-button fw-b'><Icon name='add'/> 투자 요청</span>
                </div>
              }
            </>
          }
        </Container>

        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>투자 요청하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Dropdown
                  required label='내 니즈' name='myNeedsId'
                  placeholder="내 니즈를 선택해주세요."
                  fluid
                  search
                  selection
                  options={labelOpt(this.state.needs)}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='투자 요청 의견' name='opinion'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  row={2}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="요청하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.requestMatching}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}