import React, {Component} from 'react'
import {Container, Divider, Icon, Tab} from "semantic-ui-react";
import axios from "axios";
import RoomCard from "./room.card";
import ChatListTab from "./list.tab";
import ChatListGroupTab from "./list.group.tab";

export default class ChatListPage extends Component {
  state = {
    rooms: [], panes: [
      {
        menuItem: '내가 보낸 채팅',
        render: () => <ChatListTab/>
      },
      {
        menuItem: '우리 그룹으로 온 채팅',
        render: () => <ChatListGroupTab/>
      },
    ]
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/rooms`, {withCredentials: true});
      console.log(response.data);
      this.setState({
        rooms: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <div className='p-container'
             style={{background: '#fff', paddingBottom: '60px', borderBottom: '2px solid #eaeaea'}}>
          <Container>
            <h1 className='pt-7'>채팅방 목록</h1>
          </Container>

        </div>
        <div className='p-container' style={{paddingTop: 0, marginTop: '-40px'}}>
          <Container>
            <Tab menu={{inverted: false, secondary: true, pointing: true}} panes={this.state.panes}/>
          </Container>
        </div>
      </div>
    )
  }
}