import {Component} from "react";
import SubsChannelItem from "./subs.channel.item";
import axios from "axios";

export default class SubsChannelList extends Component {
  state = {channels: []}

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/subscribe`, {withCredentials: true});
    this.setState({
      channels: res.data
    })
  }

  render() {
    return (
      <div style={{display: 'grid', gridGap: '1.5rem'}}>
        {this.state.channels.map((sub, idx) => (
          <SubsChannelItem key={idx} channel={sub.channel}/>
        ))}
      </div>
    );
  }
}