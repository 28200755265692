import React, {Component} from "react";
import {Button, Container, Form, Icon, Label, Loader, Modal} from "semantic-ui-react";
import axios from "axios";
import {S3UploadFile} from "../../utills/s3-file-upload";
import {investPhaseOpt} from "../../investment/create.invest.needs";
import UserContext from "../../UserContext";
import RoundProfile from "../../utills/profile";
import {Editor} from "@toast-ui/react-editor";
import FileUploadButton from "../../utills/file_upload_button";

class CreateNeedsPage extends Component {
  static contextType = UserContext;
  editorRef = React.createRef();
  state = {open: false, isLoaded: false, isUploading: false, groupName: '', attachments: [], attachments_edit: [], attachments_file: [], attachments_name: []};

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.match.params.bizId}`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        bizday: res.data,
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleSubmit = async () => {

    if (this.state.isUploading) return;
    this.setState({
      isUploading: true
    })
    this.close();

    try {
      const editorInstance = this.editorRef.current.getInstance();

      // processing attachment files
      for (let idx = 0; idx < this.state.attachments_edit.length; idx++) {
        if (this.state.attachments_file[idx]) {
          const {file, url} = await S3UploadFile(this.state.attachments_file[idx], `bizday/${this.state.bizday.uuid}/needs/attachments`);
          this.state.attachments.push(url);
        }
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/needs`, {
        'bizday_id': this.props.match.params.bizId,
        'venture_type': this.state.venture_type,
        'invest_phase': this.state.invest_phase,
        'title': this.state.title,
        'description': editorInstance.getMarkdown(),
        'attachments': this.state.attachments,
        'category_1': this.state.category_1,
        'category_2': this.state.category_2
      }, {withCredentials: true});
      alert('신청을 완료하였습니다!')
      window.location.replace(`/bizday/${this.props.match.params.slug}/${this.props.match.params.bizId}`)
    } catch (e) {
      console.log(e);
      this.setState({
        isUploading: false,
        attachments: []
      })
      alert("신청에 실패했습니다. 내용을 모두 입력했는지 확인해주세요.");
    }
  }

  handleUpload = async (e) => {
    const {file, url} = await S3UploadFile(e.target.files[0], "bizday");
    this.setState({
      [e.target.name]: url,
    })
  }

  handleMultiFileUpload = async (event) => {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.state.attachments_file.push(file); // this will be used for uploading file to s3
      this.state.attachments_edit.push(file.name); // we will traverse this when submit
      this.state.attachments_name.push(file.name); // will be revealed in the list
      this.setState({
        attachments_file: this.state.attachments_file,
        attachments_edit: this.state.attachments_edit,
        attachments_name: this.state.attachments_name
      });
    }
  }

  render() {
    const user = this.context;

    return (
      <div className='p-container'>
        <Container>
          {
            this.state.isLoaded ?
              <div>
                <Label className='mv-1'>{this.state.bizday.biz_group.name}</Label>
                <Label className='mv-1'>{this.state.bizday.title}</Label>
                <h1 className='mt-1 mb-6'>새로운 사업 제안하기</h1>
                {
                  user ?
                    <Form>
                      <h3 className='color-main mt-7 mb-6'><Icon name='group'/> 신청자 정보</h3>

                      <div className='p-card-overflow pd-2'>
                        <div style={{display: 'flex', flexDirection: 'column'}} className='has-item-centered mt-6'>
                          <RoundProfile src={user.group.profile_url} size={80}/>
                          <p className='fw-b fs-1 mt-5 mb-0'>{user.group.name}</p>
                          <p className='color-gray'>{user.group.type}</p>
                        </div>

                        {
                          user.group.type === 'VENTURE' ? <div>
                              <Form.Dropdown
                                required label='소속 단체' name='venture_type'
                                placeholder="신청할 소속 단체을 선택해주세요."
                                fluid
                                selection
                                options={user.group.subtype.map((subtype) => {
                                  return {key: subtype.name, text: subtype.name, value: subtype.name}
                                })}
                                onChange={this.handleChange}
                              />
                              <Form.Dropdown
                                required label='투자 단계' name='invest_phase'
                                placeholder="현재 투자 단계를 선택해주세요."
                                fluid
                                selection
                                options={investPhaseOpt}
                                onChange={this.handleChange}
                              />
                            </div>
                            : null
                        }
                      </div>

                      <h3 className='color-main mt-7 mb-6'><Icon name='suitcase'/> 사업 정보</h3>

                      <div className='p-card-overflow pd-2'>
                        <Form.Input
                          required label='사업 제목' name='title'
                          placeholder="제목을 작성해주세요."
                          onChange={this.handleChange}
                        />

                        <div className='ui field required'>
                          <label>사업 설명</label>
                        </div>

                        <Editor
                          ref={this.editorRef}
                          height="400px"
                          placeholder="간단한 설명을 작성해주세요. 마크다운 문법을 지원합니다."
                          initialEditType="markdown"
                          useCommandShortcut={true}
                          hideModeSwitch={true}
                          hooks={{
                            addImageBlobHook: async (blob, callback) => {
                              const {file, url} = await S3UploadFile(blob, `bizday/${this.state.bizday.uuid}/needs/description`);
                              callback(encodeURI(url), file);
                              return false;
                            }
                          }}
                        />

                        <Form.Dropdown
                          className='mt-5'
                          required label='1차 카테고리' name='category_1'
                          placeholder="1차 카테고리를 선택해주세요."
                          fluid
                          selection
                          options={this.state.bizday.category_1.map((cat) => {
                            return {key: cat, text: cat, value: cat}
                          })}
                          onChange={this.handleChange}
                        />
                        <Form.Dropdown
                          required label='2차 카테고리' name='category_2'
                          placeholder="2차 카테고리를 선택해주세요."
                          fluid
                          selection
                          options={this.state.bizday.category_2.map((cat) => {
                            return {key: cat, text: cat, value: cat}
                          })}
                          onChange={this.handleChange}
                        />

                        <div className='ui field required'>
                          <label>PDF 파일 첨부</label>
                        </div>

                        <p>최대 3개의 파일을 첨부할 수 있습니다.</p>
                        {
                          this.state.attachments_edit.length ?
                            <div>
                              {
                                this.state.attachments_edit.map((attachment, idx) => {
                                  if (!attachment) return null;
                                  return (
                                    <div key={idx} className='pd-1 mv-5 p-item-vcentered' style={{border: '1px solid rgba(34,36,38,.15)', borderRadius: '15px'}}>
                                      <p className='color-main mb-0 fw-b' href={attachment}
                                         style={{flexGrow: 1}}>{this.state.attachments_name[idx]}</p>
                                      <Button size="tiny" icon="close" basic
                                              onClick={() => {
                                                this.state.attachments_file[idx] = null;
                                                this.state.attachments_edit[idx] = null;
                                                this.state.attachments_name[idx] = null;
                                                this.setState({
                                                  attachments_file: this.state.attachments_file,
                                                  attachments_edit: this.state.attachments_edit,
                                                  attachments_name: this.state.attachments_name
                                                })
                                              }}
                                      />
                                    </div>
                                  );
                                })
                              }
                              {
                                (this.state.attachments_edit.filter(
                                  (elt) => elt != null
                                ).length <= 2) ?
                                  <FileUploadButton
                                    label="파일 추가"
                                    accept=".pdf"
                                    name="attachments"
                                    handleChange={this.handleMultiFileUpload}
                                  /> :
                                  null
                              }
                            </div> :
                            <div>
                              <p className='color-gray'>등록된 첨부파일이 없습니다.</p>
                              <FileUploadButton
                                label="파일 추가"
                                accept=".pdf"
                                name="attachments"
                                handleChange={this.handleMultiFileUpload}
                              />
                            </div>
                        }


                      </div>

                      <div className='mt-7 pv-6 has-text-centered'>
                        {
                          this.state.isUploading ? <Loader active inline='centered'/> : <span className='p-button fw-b' onClick={this.open}><Icon name='send'/> 신청하기</span>
                        }
                      </div>
                      <p className='color-main has-text-centered'><Icon name='exclamation triangle'/> 신청 후 내용 수정이 불가능합니다.</p>
                    </Form>
                    : null
                }
              </div>
              : <Loader active inline='centered'/>
          }
        </Container>

        <Modal
          size={'mini'}
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>신청하기</Modal.Header>
          <Modal.Content>
            <p>작성하신 내용으로 비즈데이 참가신청을 합니다.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="확인"
              labelPosition='right'
              icon='check'
              onClick={this.handleSubmit}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default CreateNeedsPage;