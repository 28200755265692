import React, {Component} from "react";
import VideoExplain from "../VideoInfo/video-explain";
import YoutubeDetail from "../Youtube/YoutubeDetail";
import Comments from "../VideoInfo/comments.section"
import {Grid} from 'semantic-ui-react'
import axios from "axios";
import {Viewer} from "@toast-ui/react-editor";
import CommentsSection from "../VideoInfo/comments.section";

class VideoPage extends Component {
  state = {isLoading: true}

  constructor(props) {
    super(props)
    this.query = new URLSearchParams(this.props.location.search)
  }

  async componentDidMount() {
    try {
      const res = (await axios.get(`${process.env.REACT_APP_API_URL}/video/${this.query.get('v')}`, {withCredentials: true})).data;

      this.setState({
        isLoading: false,
        video: res
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        {
          (!this.state.isLoading) ?
            <div style={{maxWidth: '1600px', margin: '0 auto', padding: '1rem'}}>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={12}>
                  <div>
                    <YoutubeDetail video={this.state.video}/>
                    <VideoExplain channel={this.state.video.channel}/>
                    <div className={'mt-5 pl-5 pr-5'}>
                      <Viewer
                        referenceDefinition={true}
                        initialValue={this.state.video.content}
                      />
                    </div>
                  </div>
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={4}>
                  <div className={'pt-5'}>
                    <CommentsSection parentId={this.state.video.uuid}/>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
            : null
        }
      </div>
    );

  }
}

export default VideoPage;