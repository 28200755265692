import React, {Component} from "react";
import {
  Button,
  Container, Form, Icon, Loader, Modal,
} from "semantic-ui-react";
import axios from "axios";
import UserContext from "../UserContext";
import RoundProfile from "../utills/profile";

export const investPhaseOpt = [
  {key: 'Pre-Seed', text: 'Pre-Seed', value: 'Pre-Seed'},
  {key: 'Seed', text: 'Seed', value: 'Seed'},
  {key: 'Series A', text: 'Series A', value: 'Series A'},
  {key: 'Series B', text: 'Series B', value: 'Series B'},
  {key: 'Series C', text: 'Series C', value: 'Series C'},
  {key: 'Series D', text: 'Series D', value: 'Series D'},
  {key: 'M&A', text: 'M&A', value: 'M&A'},
  {key: 'IPO', text: 'IPO', value: 'IPO'},
];

class CreateInvestNeedsPage extends Component {
  static contextType = UserContext;
  state = { open: false, isLoaded: false, isUploading: false, shareholders: [{ type: 'other', name: '', percent: '' }],
    shareholder_ceo_percent: '', shareholder_team_percent: '',
    exp1_percent: '', exp2_percent: '', exp3_percent: '', exp4_percent: '',
    exp1_content: '', exp2_content: '', exp3_content: '', exp4_content: ''
  };
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  getMyChannel(groupId) {
    if (this.state.isLoaded) return;
    axios.get(`${process.env.REACT_APP_API_URL}/group/channel/info/${groupId}`, {withCredentials: true}).then((res) => {
      this.setState({
        isLoaded: true,
        channel: res.data
      })
    });
  }

  handleChange = (e, data) => {
    if (data.name === 'invest_time') {
      this.setState({
        invest_time: data.value.toString().slice(0,6)
      })
      return;
    }
    this.setState({
      [data.name]: data.value
    })
  }

  handleSubmit = async () => {
    if (this.state.isUploading) return;
    this.setState({
      isUploading: true
    })
    this.close();

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/needs`, {
        'prev_invest_phase': this.state.prev_invest_phase,
        'prev_invest_amount': this.state.prev_invest_amount,
        'prev_post_value': this.state.prev_post_value,
        'shareholder': [
          { type: 'ceo', name: 'CEO', percent: this.state.shareholder_ceo_percent },
          { type: 'team', name: '창업자(CEO 제외)', percent: this.state.shareholder_team_percent },
          ...this.state.shareholders],
        'invest_phase': this.state.invest_phase,
        'invest_amount': this.state.invest_amount,
        'invest_time': this.state.invest_time,
        'expense_list': [
          { name: '운전자금', percent: this.state.exp1_percent, content: this.state.exp1_content },
          { name: '연구(R&D)', percent: this.state.exp2_percent, content: this.state.exp2_content },
          { name: '시설투자', percent: this.state.exp3_percent, content: this.state.exp3_content },
          { name: '기타', percent: this.state.exp4_percent, content: this.state.exp4_content },
        ]
      }, {withCredentials: true});
      alert('투자 니즈 신청을 완료하였습니다!')
      window.location.replace(`/investment#my-invest`)
    } catch (e) {
      if (e.response.status === 403) {
        alert(`투자 니즈 신청에 실패했습니다. 다시 로그인 후 시도해주세요.`);
        window.location.replace('/');
      }
      else if (e.response.status === 400)
        alert(`투자 니즈 신청에 실패했습니다. ${e.response.data.message}`);
      else
        alert(`투자 니즈 신청에 실패했습니다. 빈칸의 내용을 모두 채워주세요!`);

      this.close();
      this.setState({
        isUploading: false
      })
    }
  }

  render() {
    const user = this.context;
    if (user) {
      this.getMyChannel(user.group.uuid);
    }

    return (
      <div className='p-container'>
        <Container>
          <h1 className='mt-1 mb-6'>새로운 투자 니즈 올리기</h1>
          {
            user ?
              <Form>
                <h3 className='color-main'><Icon name='building'/> 기업 정보</h3>
                <div style={{display: 'flex', flexDirection: 'column'}} className='has-item-centered mt-6'>
                  <img style={{
                    height: '80px',
                    width: '80px',
                    borderRadius: '40px',
                    objectFit: 'cover',
                    border: '1px solid #eaeaea'
                  }}
                       src={user.group.profile_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}/>

                  <p className='fw-b fs-1 mt-5 mb-0'>{user.group.name}</p>
                  <p className='color-gray'>{user.group.type}</p>
                </div>
                {
                  this.state.isLoaded &&
                  <>
                    <div className='p-card pd-1 is-flex is-vcentered mv-6'>
                      <RoundProfile
                        src={this.state.channel.profile_url ?? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'}
                        size={80}
                        slug={this.state.channel.slug}
                      />
                      <div className='ml-6'>
                        <p className='fs-1 fw-b mb-2'>{this.state.channel.name}</p>
                        <p className='color-gray text-ellipsis2L'>{this.state.channel.intro}</p>
                      </div>
                    </div>
                    <p className='color-main has-text-centered'><Icon name='exclamation triangle'/> 위 채널에 게시된 소개, IR 자료, 영상 등의 내용을 바탕으로 투자 매칭을 합니다.</p>
                  </>
                }

                <h3 className='color-main mt-8'><Icon name='pie graph'/> 직전 투자 정보</h3>
                <Form.Dropdown
                  className='mt-6'
                  required label='직전 투자 단계' name='prev_invest_phase'
                  placeholder="직전 투자 단계를 선택해주세요."
                  fluid
                  selection
                  options={investPhaseOpt}
                  onChange={this.handleChange}
                />

                <Form.Input
                  className='mt-6'
                  required label='직전 투자 금액 (단위: 억원)' name='prev_invest_amount'
                  placeholder="직전 투자 금액을 입력해주세요. (단위: 억원)"
                  type='number'
                  onChange={this.handleChange}
                />

                <Form.Input
                  className='mt-6'
                  required label='직전 밸류 (Post, 단위: 억원)' name='prev_post_value'
                  placeholder="직전 투자 밸류를 입력해주세요. (단위: 억원)"
                  type='number'
                  onChange={this.handleChange}
                />

                <div className='ui field required mt-6'>
                  <label>주요 주주 (직전 펀드레이징 기준)</label>

                  <Form.Group style={{marginTop: '-1rem', marginBottom: 0}}>
                    <Form.Input
                      readOnly
                      icon={'user'}
                      iconPosition={'left'}
                      className={'mt-5'}
                      width={4}
                      value="CEO"
                    />
                    <Form.Input
                      className={'mt-5'}
                      width={10}
                      icon='percent'
                      name='shareholder_ceo_percent'
                      placeholder="주식 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group style={{marginBottom: 0}}>
                    <Form.Input
                      readOnly
                      icon={'users'}
                      iconPosition={'left'}
                      className={'mt-5'}
                      width={4}
                      value="창업자(CEO 제외)"
                    />
                    <Form.Input
                      className={'mt-5'}
                      width={10}
                      icon='percent'
                      name='shareholder_team_percent'
                      placeholder="주식 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  {
                    this.state.shareholders.map(((item, idx) => {
                      return <Form.Group style={{marginBottom: 0}}>
                        <Form.Input
                          className={'mt-5'}
                          width={4}
                          placeholder="기타"
                          value={item.name}
                          onChange={(e, data) => {
                            this.state.shareholders[idx].name = data.value;
                            this.setState({});
                          }}
                        />
                        <Form.Input
                          className={'mt-5'}
                          width={10}
                          icon='percent'
                          placeholder="주식 비율을 입력해주세요. (단위: %)"
                          value={item.percent}
                          type='number'
                          onChange={(e, data) => {
                            this.state.shareholders[idx].percent = data.value;
                            this.setState({});
                          }}
                        />
                      </Form.Group>
                    }))
                  }
                </div>

                {
                  this.state.shareholders.length < 10 &&
                  <Button basic className='mr-5' onClick={(e) => {
                    e.preventDefault();
                    this.state.shareholders.push({type: 'other', name: '', percent: ''});
                    this.setState({});
                  }}><Icon name={'add'}/> 기타 주주 추가</Button>
                }
                {
                  this.state.shareholders.length > 1 &&
                  <Button basic onClick={(e) => {
                    e.preventDefault();
                    this.state.shareholders.pop();
                    this.setState({});
                  }}><Icon name={'minus'}/> 주주 삭제</Button>
                }

                <h3 className='color-main mt-8'><Icon name='line graph'/> 투자 유치 정보</h3>

                <Form.Dropdown
                  required label='투자 단계' name='invest_phase'
                  placeholder="투자 단계를 선택해주세요."
                  fluid
                  selection
                  options={investPhaseOpt}
                  onChange={this.handleChange}
                />
                <Form.Input
                  className='mt-6'
                  required label='투자 금액 (단위: 억원)' name='invest_amount'
                  placeholder="원하는 투자 금액을 입력해주세요. (단위: 억원)"
                  type='number'
                  onChange={this.handleChange}
                />

                <Form.Input
                  className='mt-6'
                  required label='자금 필요 시기 (년월, 6자리 숫자만)' name='invest_time'
                  placeholder="년월을 입력해주세요. (ex. 202109)"
                  value={this.state.invest_time}
                  type='number'
                  onChange={this.handleChange}
                />

                <h3 className='color-main mt-8 mb-0'><Icon name='money'/> 투자 자금 소요 내역</h3>

                  <Form.Group>
                    <Form.Input
                      required
                      width={6}
                      label={'운전자금 비율'}
                      className={'mt-5'}
                      icon='percent'
                      name='exp1_percent'
                      placeholder="소요 자금 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                    <Form.TextArea
                      width={10}
                      label={'운전자금 내용'}
                      className={'mt-5'}
                      name='exp1_content'
                      placeholder="소요 내용을 간단히 입력해주세요."
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Input
                      required
                      width={6}
                      label={'연구(R&D) 비율'}
                      className={'mt-5'}
                      icon='percent'
                      name='exp2_percent'
                      placeholder="소요 자금 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                    <Form.TextArea
                      width={10}
                      label={'연구(R&D) 내용'}
                      className={'mt-5'}
                      name='exp2_content'
                      placeholder="소요 내용을 간단히 입력해주세요."
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Input
                      required
                      width={6}
                      label={'시설투자 비율'}
                      className={'mt-5'}
                      icon='percent'
                      name='exp3_percent'
                      placeholder="소요 자금 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                    <Form.TextArea
                      width={10}
                      label={'시설투자 내용'}
                      className={'mt-5'}
                      name='exp3_content'
                      placeholder="소요 내용을 간단히 입력해주세요."
                      onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Input
                      required
                      width={6}
                      label={'기타 비율'}
                      className={'mt-5'}
                      icon='percent'
                      name='exp4_percent'
                      placeholder="소요 자금 비율을 입력해주세요. (단위: %)"
                      type='number'
                      onChange={this.handleChange}
                    />
                    <Form.TextArea
                      width={10}
                      label={'기타 내용'}
                      className={'mt-5'}
                      name='exp4_content'
                      placeholder="소요 내용을 간단히 입력해주세요."
                      onChange={this.handleChange}
                    />
                  </Form.Group>


                <div className='mt-8 pv-6 has-text-centered'>
                  {
                    this.state.isUploading ? <Loader active inline='centered'/> : <span className='p-button fw-b' onClick={this.open}><Icon name='send'/> 신청하기</span>
                  }
                </div>
                <p className='color-main has-text-centered'><Icon name='exclamation triangle'/> 신청 후 내용 수정이 불가능합니다.
                </p>
              </Form>
              : null
          }
        </Container>

        <Modal
          size={'mini'}
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>신청하기</Modal.Header>
          <Modal.Content>
            <p>작성하신 내용으로 투자 니즈를 올립니다.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="확인"
              labelPosition='right'
              icon='check'
              onClick={this.handleSubmit}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default CreateInvestNeedsPage;