import React, {Component} from "react";
import {Button, Form, Grid, Icon, Label, Loader, Modal} from "semantic-ui-react";
import axios from "axios";
import RoundProfile from "../../../utills/profile";
import OthersNeedsCard from "../../others.needs.card";

export default class OthersNeedsTab extends Component {
  state = {isLoaded: false, needs: [], myNeeds: [], myNeedsId: '', opinion: '', selectedNeeds: {uuid: '', title: '', description: '', group: {profile_url: '', name: ''}, category_1: '', category_2: ''}}

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  openMatching = (needs) => {
    console.log(needs);
    console.log(this);
    this.state.selectedNeeds = needs;
    this.state.myNeedsId = ''
    this.open();
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  async componentDidMount() {
    try {
      const myNeeds = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.bizId}/needs`, {withCredentials: true});

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/${this.props.bizId}/needs/type/${this.props.needsType}`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        myNeeds: myNeeds.data,
        needs: res.data,
      })
    } catch (e) {
      console.log(e);
    }
  }

  requestMatching = async () => {
    if (this.state.myNeedsId === '') {
      alert('매칭 요청할 내 니즈를 선택해주세요.');
      return;
    }
    if (this.state.opinion === '') {
      alert('의견을 입력해주세요.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/bizday/matching/request/`, {
        bizday_id: this.props.bizId,
        partner_needs_id: this.props.needsType === 'VENTURE' ? this.state.myNeedsId : this.state.selectedNeeds.uuid,
        venture_needs_id: this.props.needsType === 'VENTURE' ? this.state.selectedNeeds.uuid : this.state.myNeedsId,
        opinion: this.state.opinion
      }, {withCredentials: true});
      alert('니즈 매칭을 요청했습니다.')
      this.close();
    } catch (e) {
      alert(e.response.data.message);
    }
  }

  render() {
    const labelOpt = (items) => {
      const opt = [];
      for (const e of items) {
        opt.push({
          key: e.uuid,
          text: e.title,
          value: e.uuid,
          description: `${e.category_1} | ${e.category_2}`,
          image: {avatar: true, src: e.group.profile_url},
        })
      }
      return opt;
    }

    return (
      <div>
        {
          this.state.isLoaded && this.state.needs.length === 0 && <p className='color-gray pd-1'>등록된 사업이 없습니다.</p>
        }
        {
          !this.state.isLoaded && <Loader active inline='centered'/>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((needs) =><Grid.Column>
              <OthersNeedsCard needs={needs} openMatching={this.openMatching}/>
            </Grid.Column>)
          }
        </Grid>

        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>
            매칭 요청하기 : <span className='color-gray'>{this.state.selectedNeeds.title}</span>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <Form.Dropdown
                  required label='내 니즈' name='myNeedsId'
                  placeholder="내 니즈를 선택해주세요."
                  fluid
                  search
                  selection
                  options={labelOpt(this.state.myNeeds)}
                  onChange={this.handleChange}
                />
                <Form.TextArea
                  required label='매칭 요청 의견' name='opinion'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  row={2}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <div className='is-flex is-vcentered' style={{float: 'left'}}>
              <RoundProfile src={this.state.selectedNeeds.group.profile_url} size={35}/>
              <p className='color-black mb-0 ml-5 fw-b'>{this.state.selectedNeeds.group.name}</p>
            </div>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="확인"
              labelPosition='right'
              icon='checkmark'
              onClick={this.requestMatching}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}