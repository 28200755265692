import {Component} from "react";
import {Form, Button, Icon} from "semantic-ui-react";
import axios from "axios";
import VideoComment from "./comment";

export default class CommentsSection extends Component {
  state = {comments: [], msg: ""};

  constructor(props) {
    super(props)
    this.getComments = this.getComments.bind(this)
  }

  async componentDidMount() {
    this.getComments();
  }

  getComments() {
    if (this.props.isCommunity) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/comment/community/${this.props.parentId}`
        )
        .then((res) => {
          console.log(res.data);
          this.setState({
            comments: res.data,
          });
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/comment/video/${this.props.parentId}`
        )
        .then((res) => {
          console.log(res.data);
          this.setState({
            comments: res.data,
          });
        });
    }
  }

  uploadComment = () => {
    if (this.state.msg === "") return;

    if (this.props.isCommunity) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/comment/community`,
          {
            parent_id: this.props.parentId,
            comment_body: this.state.msg,
          },
          {withCredentials: true}
        )
        .then(() => {
          this.setState({
            msg: "",
          });
          this.getComments();
        }).catch(e => {
        console.log(e);
        alert("로그인 후 이용 가능합니다.")
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/comment/video`,
          {
            parent_id: this.props.parentId,
            comment_body: this.state.msg,
          },
          {withCredentials: true}
        )
        .then(() => {
          this.setState({
            msg: "",
          });
          this.getComments();
        }).catch(e => {
        console.log(e);
        alert("로그인 후 이용 가능합니다.")
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      msg: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <h3>댓글 {this.state.comments.length}개</h3>
        {this.state.comments.map((comment, idx) => (
          <VideoComment key={idx} comment={comment} isCommunity={this.props.isCommunity}
                        getComments={this.getComments}/>
        ))}
        <Form className="mv-5">
          <Form.TextArea
            style={{borderRadius: "20px"}}
            placeholder="여기에 댓글을 입력하세요."
            onChange={this.handleChange}
            value={this.state.msg}
          />
        </Form>
        <div className="pv-5">
          <span className="p-button fw-b" onClick={this.uploadComment}>
            <Icon name="comment"/> 댓글 달기
          </span>
        </div>
      </div>
    );
  }
}
