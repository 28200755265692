import React, {Component} from "react";
import {Grid, Icon, Label} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import InvestMatchedCard from "./widgets/invest.matched.card";
import InvestNeedsCard from "./widgets/invest.needs.card";

export default class MyInvestTab extends Component {
  state = {isLoaded: false, needs: [], matched: []}

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/needs`, {withCredentials: true});
      const matched = await axios.get(`${process.env.REACT_APP_API_URL}/invest/matched/needs`, {withCredentials: true});

      this.setState({
        isLoaded: true,
        needs: res.data,
        matched: matched.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <h3 className='mt-7'>매칭된 투자 니즈</h3>
        {
          this.state.isLoaded && this.state.matched.length === 0 && <p className='color-gray pd-1'>매칭된 투자 니즈가 없습니다.</p>
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.matched.map((matched) =>
              <Grid.Column>
                <InvestMatchedCard matched={matched}/>
              </Grid.Column>
            )
          }
        </Grid>

        <h3 className='mt-7'>업로드한 니즈</h3>
        {
          this.state.isLoaded && this.state.needs.length === 0 ?
            <p className='color-gray pd-1'>업로드한 투자 니즈가 없습니다.</p> : null
        }
        <Grid columns={3} doubling stackable>
          {
            this.state.needs.map((needs) =>
              <Grid.Column>
                <InvestNeedsCard needs={needs}/>
              </Grid.Column>
            )
          }
        </Grid>

        <Link to={`/investment/new-needs`}>
          <div className='mv-8'>
            <span className='p-button fw-b'><Icon name='add'/> 새로운 투자 니즈 올리기</span>
          </div>
        </Link>

      </div>
    );
  }
}