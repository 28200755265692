import {Component} from "react/cjs/react.production.min";
import {Icon} from "semantic-ui-react";

export default class SidebarMobileItem extends Component {
  render() {
    return (
      <div className="p-item p-item-mobile fs-1">
        <Icon name={this.props.icon}/>
      </div>
    );
  }
}