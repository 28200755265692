import {Component} from "react";
import EtcCard from './widget/etc.card';

export default class Etcetera extends Component {
  render() {
    return (
      <div className='mt-6'>
        <h2 className='mt-4'> 시스템 공지 </h2>
        <EtcCard itemNum={3}/>
      </div>
    );
  }
}
