import axios from "axios";
import {Component} from "react";
import {Container, Loader} from "semantic-ui-react";
import RoundProfile from "../utills/profile";
import CommentsSection from "../VideoInfo/comments.section";
import {displayedAt} from "../utills/date";

export default class CommunityPostDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/community/${this.props.match.params.id}`);
      this.setState({
        isLoading: false,
        post: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return this.state.isLoading ? <Loader active inline='centered'/> : (
      <Container>
        <div className={"p-container"}>
          <div
            className="p-card pd-1"
            style={{overflow: "hidden", maxWidth: "700px"}}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <div className="mr-5 ">
                <RoundProfile
                  slug={this.state.post.channel.slug}
                  size={35}
                  src={this.state.post.channel.profile_url}
                />
              </div>
              <div>
                <p className="fw-b mb-0">{this.state.post.channel.name}</p>
                <p className="color-gray">
                  {displayedAt(this.state.post.created_at)}
                </p>
              </div>
            </div>
            <div className="pd-1">
              {this.state.post.content.split("\n").map((str) => {
                return <div>{str}</div>;
              })}
            </div>

            {this.state.post.img_url && (
              <div
                className="pd-1 pt-0"
                style={{width: "100%", maxWidth: "500px"}}
              >
                <div className="p-box-1by1">
                  <div className="p-box-ch">
                    <img
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                      src={this.state.post.img_url}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='mt-6' style={{maxWidth: '700px'}}>
            <CommentsSection parentId={this.state.post.uuid} isCommunity/>
          </div>
        </div>
      </Container>
    );
  }
}
