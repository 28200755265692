import {Component} from "react/cjs/react.production.min";
import {Icon} from "semantic-ui-react";

export default class SidebarItem extends Component {
  render() {
    return (
      <div className="p-item">
        <Icon name={this.props.icon} style={{marginRight: '1.5rem'}}/>
        {this.props.title}
      </div>
    );
  }
}