import React, {Component} from "react";
import {Button, Checkbox, Container, Divider, Form, Grid, Icon, Image, Input, Label, Loader} from "semantic-ui-react";
import "./signup.css";
import axios from "axios";
import RoundProfile from "../utills/profile";
import {Link} from "react-router-dom";

class SignupPage extends Component {

  constructor(props) {
    super(props);
    this.query = new URLSearchParams(this.props.location.search);
    this.state = {name: '', email: '', isLoaded: false, agreement: false, pathname: props.location.pathname}
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleClick = (e, { checked }) => this.setState(({ agreement }) => ({ agreement: !agreement }))

  componentDidMount() {
    if (this.query.get('code')) {
      axios.get(`${process.env.REACT_APP_API_URL}/user/register/info?code=${this.query.get('code')}`).then((res) => {
        this.setState({
          email: res.data.email,
          group: res.data.group,
          isLoaded: true
        })
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  signUp = () => {
    if (this.state.name.trim() === '') {
      alert("이름을 입력해주세요.")
      return;
    }

    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(this.state.email)) {
      alert("이메일 형식이 올바르지 않습니다.")
      return;
    }

    if (this.state.password !== this.state.confirm_password) {
      alert("비밀번호가 일치하지 않습니다.")
      return;
    }

    if (!this.state.agreement) {
      alert("이용약관 및 개인정보처리방침에 동의해주세요.")
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/user/register`, {
      code: this.query.get('code') ?? null,
      email: this.state.email,
      email_verified: !!this.query.get('code'),
      name: this.state.name,
      password: this.state.password,
      group_uuid: this.state.group ? this.state.group.uuid : null,
    }).then((res) => {
      alert("가입 성공!");
      window.location.href="/";
    }).catch((e) => {
      alert("이미 존재하는 이메일입니다.")
      console.log(e);
    });
  }

  render() {
    return(
      <div className='p-container'>
        <Container>
          <Grid columns={2} stackable>
            <Grid.Column className='mt-8'>
              <Image size='tiny' src='/img/ssp-logo-blue.svg' />

              <h1 className='mb-5'>환영합니다!</h1>
              <p className='mt-0'>POSCO SSP 서비스를 이용하려면 아래 정보를 모두 입력해주세요.</p>
              <p>이미 소속된 그룹이 있는 경우, 꼭 이메일로 온 초대 링크를 통해 가입을 진행해주세요.<br/>벤처(기업) 계정 가입을 원하는 그룹은 <b>poscossp@poapper.com</b>으로 연락주세요!</p>
              <div className='mt-7'>
                <Link to={this.state.pathname === '/m/signup' ? '/m/policy' : '/policy'}><span className='color-main fw-b'>이용약관 및 개인정보처리방침</span></Link>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className='has-item-centered is-col mt-7' style={{width: "100%"}}>
                {
                  this.query.get('code') != null && <>
                    {
                      this.state.isLoaded ? <>
                        <RoundProfile src={this.state.group.profile_url} size={60}/>
                        <p className='fs-1 fw-b mt-5 mb-7'>{this.state.group.name}</p>
                      </> : <Loader active inline='centered'/>
                    }
                  </>
                }

                <Form style={{width: "100%", maxWidth: "36rem"}}>
                  <Form.Input fluid required label='이름' name='name' placeholder='이름을 입력해주세요.' value={this.state.name} onChange={this.handleChange}/>
                  <Form.Input fluid required readOnly={this.query.get('code')} label='이메일 주소' name='email' placeholder='이메일 주소를 입력해주세요.' value={this.state.email ?? ''} onChange={this.handleChange}/>
                  <Form.Input fluid required label='비밀번호' type='password' name='password' placeholder='비밀번호를 입력해주세요.' value={this.state.password} onChange={this.handleChange}/>
                  <Form.Input fluid required label='비밀번호 확인' type='password' name='confirm_password' placeholder='비밀번호를 다시 한번 입력해주세요.' value={this.state.confirm_password} onChange={this.handleChange}/>
                  <Form.Field>
                    <Checkbox onChange={this.handleClick} label={'이용약관 및 개인정보처리방침에 동의합니다.'}/>
                  </Form.Field>
                  <div className='pv-8'>
                    <span className='p-button fw-b' onClick={this.signUp}><Icon name='signup'/> 가입하기</span>
                  </div>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    )
  }
}

export default SignupPage;
