import { Divider, Container, Icon } from "semantic-ui-react";
import React, { Component } from "react";
import "./market.css";
import ReviewModal from "./review.modal";
import RatingForm from "./rating.form";
import axios from "axios";
import moment from "moment";
import { Viewer } from "@toast-ui/react-editor";
import CopyToClipboard from "react-copy-to-clipboard";
import RoundProfile from "../utills/profile";
import {Link} from "react-router-dom";

export default class MarketItemDetail extends Component {
  state = { isOpen: false, tags: [], isLoading: true, isReviewLoaded: false };

  constructor(props) {
    super(props)
  }

  like = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/like/marketItem`, {uuid: this.props.match.params.id}, {withCredentials: true}).then((res) => {
      console.log(res);
      if (res.data === "Liked") {
        this.setState({
          isLiked: true,
        })
      } else {
        this.setState({
          isLiked: false,
        })
      }
    }).catch((e) => {
      console.log(e);
      alert("로그인 후 이용 가능합니다.");
    })
  }

  checkLiked = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/like/marketItem/${this.props.match.params.id}`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      if (res.data) this.setState({isLiked: true});
    });
  }

  componentDidUpdate() {
    this.state.isOpen = false;
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.id;

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/market/item/${id}`
      );
      const res = response.data;
      console.log(res);

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/market/review?item=${this.props.match.params.id}`
        )
        .then((res) => {
          this.setState({
            isReviewLoaded: true,
            reviews: res.data,
          });
        });

      this.setState({
        item: res,
        isLoading: false,
        itemId: res.uuid,
        title: res.title,
        subtitle: res.subtitle,
        icon_url: res.icon_url,
        preview_url: res.preview_url,
        category_emoji: res.category.emoji,
        category_name: res.category.name,
        description: res.description,
        price: res.price,
        original_price: res.original_price,
        group: res.group.name,
        groupId: res.group.uuid,
        tags: res.tags,
      });
    } catch (e) {
      console.log(e);
    }
    this.checkLiked();
  }

  openChat = () => {
    window.open(
      `/chat/group/${this.state.groupId}`,
      "target",
      "top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no"
    );
  };

  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  render() {
    const { active } = this.state;

    return (
      <div className="p-container">
        {this.state.isOpen ? <ReviewModal itemId={this.state.itemId} /> : null}

        {!this.state.isLoading ? (
          <div>
            <Container>
              <div className="p-box-16by9" style={{ borderRadius: "2rem" }}>
                <img
                  className="p-box-ch"
                  style={{ objectFit: "cover" }}
                  src={this.state.preview_url ?? "https://react.semantic-ui.com/images/wireframe/square-image.png"}
                />
              </div>

              <div style={{ width: "150px", margin: "-75px auto 0 auto" }}>
                <div
                  className="p-box-1by1"
                  style={{
                    borderRadius: "30%",
                    overflow: "hidden",
                    border: "1px solid rgba(0,0,0,.1)",
                  }}
                >
                  <img
                    className="p-box-ch"
                    style={{ objectFit: "cover" }}
                    src={
                      this.state.item.icon_url ??
                      "https://react.semantic-ui.com/images/wireframe/square-image.png"
                    }
                  />
                </div>
              </div>

              <div>
                <h2 className="mt-5 mb-1 has-text-centered">
                  {" "}
                  {this.state.title}{" "}
                </h2>
                <p className="fs-1 color-gray has-text-centered">
                  {this.state.subtitle}
                </p>
              </div>

              <div className="pt-7 mb-5">
                <p className="color-gray mb-0 has-text-centered">
                  <del>{this.state.original_price}</del>
                </p>
                <p className="color-main fs-2 fw-b has-text-centered">
                  {this.state.price}
                </p>
              </div>

              <div
                className="has-text-centered pd-1"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <span
                  className={`hover-gray mr-1 no-drag ${
                    this.state.isLiked ? "color-main" : "color-gray"
                  }`}
                  style={{
                    borderRadius: "10px",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                  }}
                  onClick={this.like}
                >
                  <Icon name={this.state.isLiked ?'heart' : 'heart empty'} /> 좋아요
                </span>
                <CopyToClipboard text={window.location.href} onCopy={() => alert('URL 주소가 복사 되었습니다!')}>
                  <span
                    className="color-gray hover-gray mr-1 no-drag"
                    style={{
                      borderRadius: "10px",
                      padding: "0.5rem 1rem",
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="share" /> 공유하기
                  </span>
                </CopyToClipboard>
                <span
                  className="color-gray hover-gray no-drag"
                  style={{
                    borderRadius: "10px",
                    padding: "0.5rem 1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => alert("준비 중 입니다...😢")}>
                  <Icon name="horizontal ellipsis" /> 더보기
                </span>
              </div>

              <Divider />
              <h3>상품 설명</h3>
              <Viewer
                referenceDefinition={true}
                initialValue={this.state.description}
              />

              <Divider />

              <h3>
                평가 및 리뷰{" "}
                <span
                  className="color-main"
                  style={{
                    float: "right",
                    fontWeight: "normal",
                    fontSize: "17.5px",
                  }}
                >

                  <Link to={`/market/item/${this.state.itemId}/reviews`}>
                    <a
                      className="color-main ph-3 pv-2 hover-gray p-round"
                    >
                      모두 보기
                    </a>
                  </Link>
                </span>
              </h3>

              <div className="">
                {this.state.isReviewLoaded ? (
                  <RatingForm
                    score={this.state.reviews.total}
                    total={this.state.reviews.reviews.length}
                    star={this.state.reviews.star}
                  />
                ) : null}

                <div className="color-main has-text-centered fs-1 pt-7 pb-5">
                  <a
                    className="color-main ph-3 pv-2 hover-gray p-round pointer"
                    onClick={() =>
                      this.setState({ isOpen: !this.state.isOpen })
                    }
                  >
                    <Icon name="edit" /> 리뷰 작성하기
                  </a>
                </div>
              </div>

              <Divider />

              <h3>정보</h3>

              <div
                className="has-text-centered"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <div
                  className="p-card pv-5 hover-zoom pointer mb-5 mh-3"
                  style={{ width: "220px" }}
                >
                  <div className="color-gray has-text-centered">카테고리</div>
                  <div className="fs-5 pv-6 has-text-centered">
                    {this.state.category_emoji}
                  </div>
                  <div className="fs-1 fw-b has-text-centered">
                    {this.state.category_name}
                  </div>
                </div>

                <Link to={`/channel/${this.state.item.group.channel_info.slug}`}>
                  <div
                    className="p-card pv-5 hover-zoom pointer mb-5 mh-3"
                    style={{ width: "220px" }}
                  >
                    <div className="color-gray has-text-centered">판매자</div>
                    <div className="fs-5 pv-3 has-text-centered">
                      <RoundProfile size={50} src={this.state.item.group.profile_url}/>
                    </div>
                    <div className="color-black fs-1 fw-b has-text-centered">
                      {this.state.item.group.name}
                    </div>
                  </div>
                </Link>
              </div>

              <h4 className="color-gray">
                <Icon name="tag" /> 검색 태그
              </h4>
              <div className="mb-7">
                {this.state.item.tags.map((tag, i) => {
                  return (
                    <a
                      style={{
                        color: "#005386",
                        marginRight: "0.5rem",
                        fontSize: "1.1em",
                      }}
                      href="#"
                      key={i}
                    >
                      #{tag.name}
                    </a>
                  );
                })}
              </div>

              <h4 className="color-gray">
                <Icon name="calendar" /> 등록일
              </h4>
              <p>
                {moment(this.state.item.created_at).format(
                  "YYYY년 MM월 DD일 HH:mm"
                )}
              </p>

              <h4 className="color-gray">
                <Icon name="time" /> 수정일
              </h4>
              <p>
                {moment(this.state.item.updated_at).format(
                  "YYYY년 MM월 DD일 HH:mm"
                )}
              </p>
            </Container>

            <div className="hover-zoom fab-market-chat" onClick={this.openChat}>
              <p className="mg-0 pl-7 pr-5">채팅으로 거래하기</p>
              <div className="fab-chat-icon">
                <Icon
                  style={{ margin: "3.5px 0 0 -5px" }}
                  name="commenting"
                  size="large"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
