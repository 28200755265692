import React, {Component} from "react";
import axios from "axios";
import CitizenCard from "../../CitizenCard";

export class CitizenList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }

  async getData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/board/corpoCitizen/${this.props.type ?? ''}`);
      this.setState({
        posts: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.getData();
    }
  }

  render() {
    return (
      <div className='auto-grid' style={{padding: 0}}>
        {this.state.posts.map((post, idx) =>
          <CitizenCard post={post}/>
        )}
      </div>
    )
  }
}