import React, {Component} from "react";
import {Button, Container, Form, Grid, Icon, Label, Modal} from "semantic-ui-react";
import axios from "axios";
import RoundProfile from "../utills/profile";
import moment from "moment";
import InvestNeedsCard from "./widgets/invest.needs.card";

export default class VCInvestAlwaysPage extends Component {
  state = {
    isLoaded: false,
    needs: [],
    opinion: '',
    selectedNeeds: {uuid: '', group: {profile: '', name: ''}, invest_phase: '', invest_amount: ''}
  };

  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/invest/all-needs`, {withCredentials: true});
    this.setState({
      needs: res.data,
      isLoaded: true,
    });
  }

  openMatching(needs) {
    this.state.opinion = ''
    this.state.selectedNeeds = needs;
    this.open();
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  requestMatching = async () => {
    if (this.state.opinion === '') {
      alert('의견을 입력해주세요.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/invest/matching-request/always`, {
        needs_id: this.state.selectedNeeds.uuid,
        opinion: this.state.opinion
      }, {withCredentials: true});
      alert('투자 매칭을 요청했습니다.')
      this.close();
    } catch (e) {
      alert(e.response.data.message);
    }
  }

  render() {
    return (
      <div className='p-container'>
        <Container>
          <h1>상시 모집</h1>
          <p className='color-main mb-3'><Icon name='group'/> 모든 투자자</p>
          <p className='color-main'><Icon name='time'/> 상시 접수 중</p>
          <p className='color-gray'>모든 벤처들의 투자 니즈를 상시 확인하고 매칭을 요청할 수 있습니다.</p>
          <p className='color-gray fw-b mb-6'>이미 매칭 되었거나, 행사에서 요청한 니즈는 요청할 수 없습니다.</p>
          {
            this.state.isLoaded &&
            <>

              <h3>벤처 투자 니즈</h3>
              {
                this.state.isLoaded2 && this.state.needs.length === 0 ?
                  <p className='color-gray pd-1'>벤처 투자 니즈가 없습니다.</p> : null
              }
              <Grid columns={3} doubling stackable>
                {
                  this.state.needs.map((needs) => <Grid.Column>
                    <InvestNeedsCard needs={needs} isVC={true} isFestivalOpen={true} needsId={needs.uuid}/>
                  </Grid.Column>)
                }
              </Grid>
            </>
          }
        </Container>
        <Modal
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>매칭 요청하기</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form>
                <div className='is-flex is-vcentered'>
                  <img style={{
                    border: '1px solid #eaeaea',
                    objectFit: 'cover',
                    width: `60px`,
                    height: `60px`,
                    borderRadius: `30px`
                  }} src={this.state.selectedNeeds.group.profile}
                       alt='group profile'/>
                  <p className='color-black mb-0 ml-5 fw-b'>{this.state.selectedNeeds.group.name}</p>
                </div>
                <div className='mt-5 mb-5'>
                  <Label className='mb-1'>{this.state.selectedNeeds.invest_phase}</Label>
                  <Label className='mb-1'>{this.state.selectedNeeds.invest_amount}억원 이상</Label>
                </div>
                <Form.TextArea
                  required label='매칭 요청 의견' name='opinion'
                  placeholder="의견을 간단히 작성해주세요."
                  fluid
                  row={2}
                  onChange={this.handleChange}
                />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              primary
              content="요청하기"
              labelPosition='right'
              icon='checkmark'
              onClick={this.requestMatching}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}