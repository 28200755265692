import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import ChatRoom from "./chat.room";
import {Loader} from "semantic-ui-react";

const Chat = ({match}) => {
  const [currentSocket, setCurrentSocket] = useState();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setCurrentSocket(socketIOClient(process.env.REACT_APP_CHAT_URL, {path: '/socket.io'}));
    } else {
      setCurrentSocket(socketIOClient(process.env.REACT_APP_CHAT_URL, {path: '/chat/socket.io'}));
    }
  }, []);

  return (
    <div>
      {currentSocket ? (
        <div>
          <ChatRoom type={match.params.type} id={match.params.id} socket={currentSocket}/>
        </div>
      ) : (
        <div className='pv-8'>
          <Loader active inline='centered'/>
        </div>
      )}
    </div>)
};

export default Chat;