import {Component} from "react";
import UserContext from "../UserContext";
import {Button, Icon, Modal} from "semantic-ui-react";
import axios from "axios";
import RoundProfile from "../utills/profile";
import {displayedAt} from "../utills/date";

export default class VideoComment extends Component {
  static contextType = UserContext;
  state = {open: false}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  getCommentData = (event) => {
    if (this.props.disable) return;
    this.props.getComments();
  };

  deleteComment = async (e, data) => {
    if (this.props.isCommunity) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/comment/community/${this.props.comment.uuid}`,
          {withCredentials: true}
        )
        .then(() => {
          this.close();
          this.getCommentData(e)
        });
    } else {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/comment/video/${this.props.comment.uuid}`,
          {withCredentials: true}
        )
        .then(() => {
          this.close();
          this.getCommentData(e)
        });
    }
  };

  render() {
    const user = this.context ?? 0;
    return (
      <div className="mb-3">
        <div style={{display: "flex"}} className="mt-2 mb-2">
          <RoundProfile size={35} src={this.props.comment.user.profile_url} subProfile={this.props.comment.user.group != null} subSrc={this.props.comment.user.group != null ? this.props.comment.user.group.profile_url : null}/>
          <div
            className="p-card ml-5 pv-4 ph-5 is-flex"
            style={{borderRadius: "20px"}}
          >
            <div>
              <p className="mb-0 fw-b">
                {this.props.comment.user.name}
                <span className="fw-n fs-m1 color-gray"> · {displayedAt(this.props.comment.created_at)}</span>
              </p>
              <p style={{wordBreak: "break-word"}}>{this.props.comment.body}</p>
            </div>
            {
              user.uuid === this.props.comment.user.uuid &&
              <div
                className="ml-5 color-main p-item-vcentered pointer"
                onClick={this.open}
              >
                <Icon name='ellipsis vertical' style={{margin: 0}}/>
              </div>
            }
          </div>
        </div>

        <Modal
          size={'mini'}
          onClose={() => this.close()}
          onOpen={() => this.open()}
          open={this.state.open}
        >
          <Modal.Header>댓글 삭제</Modal.Header>
          <Modal.Content>
            <p>댓글을 삭제합니다.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.close()}>
              취소
            </Button>
            <Button
              negative
              content="삭제"
              labelPosition='right'
              icon='trash'
              onClick={this.deleteComment}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
