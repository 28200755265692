import {Component} from "react";
import axios from "axios";
import {Loader} from "semantic-ui-react";
import RoomCard from "./room.card";

export default class ChatListTab extends Component {
  state = {isLoaded: false, rooms: []}

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/chat/rooms`, {withCredentials: true});
      this.setState({
        isLoaded: true,
        rooms: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className='pt-5'>
        {
          this.state.isLoaded ?
            <>
              {
                this.state.rooms.map((room, idx) =>
                  <RoomCard key={idx} room={room}/>)
              }
            </>
            : <Loader active inline='centered'/>
        }
      </div>
    )
  }
}
