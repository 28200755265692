import {Grid, Icon, Label, Table} from 'semantic-ui-react'
import React, {Component} from "react";
import axios from "axios";
import moment from "moment";
import RoundProfile from "../utills/profile";

export default class ChannelInfo extends Component {

    constructor(props) {
        super(props)
    }

    displayedAt(foundingDate) {
        const milliSeconds = new Date() - new Date(foundingDate)
        const seconds = milliSeconds / 1000
        const minutes = seconds / 60
        const hours = minutes / 60
        const days = hours / 24
        const months = days / 30
        if (months < 12) return `${Math.floor(months)}개월차`
        const years = days / 365
        return `${Math.floor(years)}년차`
    }

    render() {
        return (
            <div>
                <div className='p-card mv-6 pd-2'>
                    <p className='fs-2 fw-b'>그룹 정보</p>
                    <div className='has-text-centered'>
                        <RoundProfile
                            size={80}
                            src={this.props.group.profile_url}
                        />
                        <p className='fw-b mt-5 fs-1 mb-0'>{this.props.group.name}</p>
                        <p className='color-gray'>{this.props.group.type}</p>
                        <div style={{flexWrap: 'wrap'}} className='has-item-centered'>
                            {this.props.group.subtype &&
                            this.props.group.subtype.map(item => <span className={'ui label mb-1 bg-lgray'}>{item.name}</span>)}
                        </div>
                        <div className='mt-5'>
                            {
                                this.props.group.tags.map(item => <a href={`/search?search_query=${window.encodeURIComponent(item.name)}`} className='color-main mr-3'>#{item.name}</a>)
                            }

                        </div>
                    </div>
                </div>
                <div className='p-card mv-6 pd-2'>

                    <p className='fs-2 fw-b'>회사 개요</p>
                    <Grid doubling columns={4}>
                        <Grid.Column>
                            <div className='has-text-centered'>
                                <Icon name='stopwatch' size='huge' style={{color: '#005386'}}/>
                                <h4 className='mt-4 mb-1'>업력 {this.props.info.founding_date ? `${this.displayedAt(this.props.info.founding_date)}` : '? 년차'}</h4>
                                <p className='color-gray'>{this.props.info.founding_date ? `${moment(this.props.info.founding_date).format('YYYY년 MM월 DD일')} 설립` : '?년 ?월 ?일'}</p>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='has-text-centered'>
                                <Icon name='building' size='huge' style={{color: '#005386'}}/>
                                <h4 className='mt-4 mb-1'>{this.props.info.company_type ?? '?'}</h4>
                                <p className='color-gray'>기업형태</p>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='has-text-centered'>
                                <Icon name='users' size='huge' style={{color: '#005386'}}/>
                                <h4 className='mt-4 mb-1'>{this.props.info.employee_num ?? '? '}명</h4>
                                <p className='color-gray'>사원 수</p>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='has-text-centered'>
                                <Icon name='database' size='huge' style={{color: '#005386'}}/>
                                <h4 className='mt-4 mb-1'>{this.props.info.revenue ? `${parseInt(this.props.info.revenue) / 100}억원` : '?'}</h4>
                                <p className='color-gray'>매출액</p>
                            </div>
                        </Grid.Column>
                    </Grid>


                </div>
                <div className='p-card pd-2'>
                    <p className='fs-2 fw-b'>기본 정보</p>

                    <div className='channel-info-box'>
                        <Table basic='very'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>법인명(단체명)</Table.HeaderCell>
                                    <Table.HeaderCell>{this.props.info.corporate_name}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>업종</Table.Cell>
                                    <Table.Cell>{this.props.info.industry}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>대표자명</Table.Cell>
                                    <Table.Cell>{this.props.info.ceo_name}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>홈페이지</Table.Cell>
                                    <Table.Cell><a
                                        href={this.props.info.website_url ? `${this.props.info.website_url.includes('//') ? this.props.info.website_url.trim() : '//' + this.props.info.website_url.trim()}` : '#'}>{this.props.info.website_url}</a></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>기업주소</Table.Cell>
                                    <Table.Cell>{this.props.info.company_address}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>사업내용</Table.Cell>
                                    <Table.Cell>{this.props.info.business_details}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>

                </div>

                <div className='is-flex mv-6' style={{flexWrap: 'wrap', justifyContent: 'center'}}>
                    <p className='color-gray has-text-centered mh-5'>
                        <Icon name='calendar'/>가입일: {moment(this.props.info.created_at).format('YYYY년 MM월 DD일')}
                    </p>
                    <p className='color-gray has-text-centered mh-5'>
                        <Icon name='clock'/>수정일: {moment(this.props.info.updated_at).format('YYYY년 MM월 DD일')}
                    </p>
                </div>

            </div>
        );
    }

}
