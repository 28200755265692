import {Icon} from 'semantic-ui-react'
import React, {Component} from "react";
import moment from "moment";
import RoundProfile from "../utills/profile";

export default class ChannelInfoPartner extends Component {

  render() {
    return (
      <div>
        <div className='p-card mv-6 pd-2'>
          <p className='fs-2 fw-b'>그룹 정보</p>
          <div className='has-text-centered'>
            <RoundProfile
              size={80}
              src={this.props.group.profile_url}
            />
            <p className='fw-b mt-5 fs-1 mb-0'>{this.props.group.name}</p>
            <p className='color-gray'>{this.props.group.type}</p>
            <div style={{flexWrap: 'wrap'}} className='has-item-centered'>
              {this.props.group.subtype &&
              this.props.group.subtype.map(item => <span className={'ui label mb-1 bg-lgray'}>{item.name}</span>)}
            </div>
            <div className='mt-5'>
              {
                this.props.group.tags.map(item => <a
                  href={`/search?search_query=${window.encodeURIComponent(item.name)}`}
                  className='color-main mr-3'>#{item.name}</a>)
              }
            </div>
          </div>
        </div>

        <div className='is-flex mv-6' style={{flexWrap: 'wrap', justifyContent: 'center'}}>
          <p className='color-gray has-text-centered mh-5'>
            <Icon name='calendar'/>가입일: {moment(this.props.group.created_at).format('YYYY년 MM월 DD일')}
          </p>
        </div>
      </div>
    );
  }

}
