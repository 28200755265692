import {Component } from "react";

export default class CategoryItem extends Component {
  render() {
    return (
      <div className='p-card full-h full-w pv-6 hover-gray p-item-vcentered has-text-centered is-col' style={{cursor: 'pointer'}} >
        {/* <Icon name='stethoscope' size='big' color='blue' /> */}
        <span className='fs-3 pb-6'> {this.props.emoji} </span>
        <span className='fs-1 fw-b'> {this.props.cate}</span>
      </div>
    );
  }
}
