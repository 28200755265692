import {Component} from "react";
import {Link} from "react-router-dom";
import YoutubeCard from "../../Youtube/youtube.card";
import GroupCard from "./group.card";

export default class GroupList extends Component {
    render() {
        return (
            <div className='auto-grid'>
                {this.props.children ?? null}
                {this.props.groups.map(group => (
                    <Link to={`/channel/${group.channel_info.slug}`}>
                        <GroupCard group={group}/>
                    </Link>
                ))}
            </div>
        );
    }
}