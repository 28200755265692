import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button, Divider, Grid, Icon, Label, Modal, Progress, Statistic} from "semantic-ui-react";
import RoundProfile from "../../utills/profile";
import moment from "moment";

export default class InvestRequestedCard extends Component {
  state = {open: false}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  constructor(props) {
    super(props);
    this.state.opinion = JSON.parse(this.props.requested.vc_opinion);
  }
  render() {
    return (
      <Modal
        dimmer={'blurring'}
        onClose={() => this.close()}
        onOpen={() => this.open()}
        open={this.state.open}
        trigger={
          <div className='p-card pd-1 pointer hover-zoom'>
            <p className='fs-1 color-black fw-b'>{this.props.requested.inv_festival ? this.props.requested.inv_festival.title : '상시 모집'}</p>

            <div className='is-flex is-vcentered mb-5'>
              <RoundProfile size={60} src={this.props.requested.inv_needs.group.channel_info.profile_url} slug={this.props.requested.inv_needs.group.channel_info.slug}/>
              <div className='ml-5'>
                <p className='fw-b mb-2 fs-1'>{this.props.requested.inv_needs.group.channel_info.name}</p>
                <p className='color-gray fs-m1 text-ellipsis2L'>{this.props.requested.inv_needs.group.channel_info.intro}</p>
              </div>

            </div>
            <div className='mb-5 has-item-centered' style={{flexWrap: 'wrap'}}>
              <Label className='mb-1'>{this.props.requested.inv_needs.invest_phase}</Label>
              <Label className='mb-1'>{this.props.requested.inv_needs.invest_amount}억원 이상</Label>
              <Label className='mb-1'>{(this.props.requested.inv_needs.invest_time / 100).toFixed(0)}년 {this.props.requested.inv_needs.invest_time % 100}월</Label>
            </div>
            {
              this.props.opinion && <div className='pv-3 ph-4 mb-5' style={{border: '1px solid #eaeaea', borderRadius: '10px'}}>
                <p>{this.props.opinion}</p>
              </div>
            }
            {/*<p className='fs-m1 color-gray has-text-centered'>{moment(this.props.needs.created_at).format('YYYY년 MM월 DD일')} · {this.props.needs.group.name}</p>*/}

            <Divider horizontal><span className='color-gray'>REQUESTED</span></Divider>

            <div className='is-flex is-vcentered'>
              <RoundProfile size={60}
                            src={this.props.requested.vc_group.profile_url}/>
              <div className='ml-5'>
                <p className='fw-b mb-0 color-black'>{this.props.requested.vc_group.name}</p>
              </div>
            </div>
          </div>
        }
      >
        <Modal.Header>투자 니즈 상세 정보</Modal.Header>
        <Modal.Content>
          <div className='has-text-centered'>
            <RoundProfile src={this.props.requested.inv_needs.group.profile_url} size={80} slug={this.props.requested.inv_needs.group.channel_info.slug}/>
            <p className='fw-b fs-1 mt-5 mb-0'>{this.props.requested.inv_needs.group.name}</p>
          </div>

          <div className='p-card pd-1 is-flex is-vcentered mt-6 mb-8'>
            <RoundProfile src={this.props.requested.inv_needs.group.channel_info.profile_url} size={60} slug={this.props.requested.inv_needs.group.channel_info.slug}/>
            <div className='ml-6'>
              <p className='color-black fw-b fs-1 mb-2'>{this.props.requested.inv_needs.group.channel_info.name}</p>
              <p className='color-gray text-ellipsis2L'>{this.props.requested.inv_needs.group.channel_info.intro}</p>
            </div>
          </div>

          <Grid columns={2} stackable className={'mb-7'}>
            <Grid.Column>
              <div className='p-card pd-1'>
                <h3 className={'color-main has-text-centered'}><Icon name={'pie graph'}/> 직전 투자</h3>
                <div className={'ph-5'}>
                  <Statistic.Group size={'mini'} horizontal>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{this.props.requested.inv_needs.prev_invest_phase}</Statistic.Value>
                      <Statistic.Label>단계</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{this.props.requested.inv_needs.prev_invest_amount}억원</Statistic.Value>
                      <Statistic.Label>금액</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{this.props.requested.inv_needs.prev_post_value}억원</Statistic.Value>
                      <Statistic.Label>밸류(Post)</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className='p-card pd-1'>
                <h3 className={'color-main has-text-centered'}><Icon name={'line graph'}/> 투자 유치</h3>
                <div className={'ph-5'}>
                  <Statistic.Group size={'mini'} horizontal>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{this.props.requested.inv_needs.invest_phase}</Statistic.Value>
                      <Statistic.Label>단계</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{this.props.requested.inv_needs.invest_amount}억원 이상</Statistic.Value>
                      <Statistic.Label>금액</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value className={'fw-b'}>{(this.props.requested.inv_needs.invest_time / 100).toFixed(0)}년 {this.props.requested.inv_needs.invest_time % 100}월</Statistic.Value>
                      <Statistic.Label>자금 필요 시기</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className='p-card pd-1'>
                <h3 className={'color-main has-text-centered'}><Icon name={'users'}/> 주요 주주 현황 </h3>
                {
                  JSON.parse(this.props.requested.inv_needs.shareholder).map((e) => {
                    return <div className='ph-5'>
                      <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                      <Progress className='mb-5' color='yellow' size='tiny' percent={e.percent}/>
                    </div>
                  })
                }
              </div>
            </Grid.Column>

            <Grid.Column>
              <div className='p-card pd-1'>
                <h3 className={'color-main has-text-centered'}><Icon name={'money'}/> 투자 자금 소요 내역</h3>
                {
                  JSON.parse(this.props.requested.inv_needs.expense_list).map((e, idx) => {
                    let color = ''
                    switch (idx) {
                      case 0: color = 'teal'; break;
                      case 1: color = 'blue'; break;
                      case 2: color = 'violet'; break;
                    }

                    return <div className='mb-5 ph-5'>
                      <p className='mb-3 fw-b'>{e.name} ({e.percent}%)</p>
                      <p className='color-gray mb-3' style={{whiteSpace: 'pre-wrap'}}>{e.content}</p>
                      <Progress className='mb-0' color={color} size='tiny' percent={e.percent} />
                    </div>
                  })
                }
              </div>
            </Grid.Column>
            {
              this.props.opinion && <Grid.Column>
                <div className='p-card pd-1'>
                  <h3 className={'color-main has-text-centered'}><Icon name={'commenting'}/> 벤처 요청 의견</h3>
                  <div className='ph-5 mb-5'>
                    <p>{this.props.opinion}</p>
                  </div>
                </div>
              </Grid.Column>
            }
          </Grid>
          <Divider horizontal><span className={"color-gray"}>REQUESTED</span></Divider>

          <div className='has-text-centered mt-8'>
            <RoundProfile src={this.props.requested.vc_group.profile_url} size={80}/>
            <p className='fw-b fs-1 mt-5 mb-0'>{this.props.requested.vc_group.name}</p>
          </div>

          <h3>매칭 요청 배경</h3>
          <h4>1. 사업 아이디어</h4>
          <p>{this.state.opinion.idea}</p>
          <h4>2. 기술력</h4>
          <p>{this.state.opinion.tech}</p>
          <h4>3. 시장 규모/속도</h4>
          <p>{this.state.opinion.market}</p>
          <h4>4. 수익성</h4>
          <p>{this.state.opinion.profit}</p>
          <h4>5. 기업 지배력</h4>
          <p>{this.state.opinion.control}</p>
          <h4>6. CEO 자질</h4>
          <p>{this.state.opinion.ceo}</p>
          <h4>7. 미래 시장 재편 (Game Changer)</h4>
          <p>{this.state.opinion.future}</p>
          <h4>8. 기타</h4>
          <p>{this.state.opinion.etc}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.close}>확인</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}