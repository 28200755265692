import React, {Component} from "react";
import RoundProfile from "../utills/profile";
import moment from "moment";

export default class NotificationBox extends Component {
  render() {
    return (
      <div>
        <div className='is-flex is-vcentered'>
          {
            this.props.item.sender ?
              <>
                <div className='mr-5' style={{flexShrink: 0}}>
                  <RoundProfile size={50} src={this.props.item.sender.profile_url} subProfile={this.props.item.sender.group != null} subSrc={this.props.item.sender.group != null ? this.props.item.sender.group.profile_url : null}/>
                </div>
                <div>
                  <p className='fw-b mb-0 color-black'>{this.props.item.sender.name} <span className='fs-m1 color-gray fw-n'>{this.props.item.sender.group && ` · ${this.props.item.sender.group.name}`}</span></p>
                  <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis2L'>
                    {this.props.item.content}
                  </p>
                  <p className='color-gray fs-m2 mt-1'>{moment(this.props.item.created_at).format('HH:mm')}</p>
                </div>
              </> :
              <>
                <p style={{whiteSpace: 'pre-wrap'}} className='mb-0 color-black text-ellipsis3L'>
                  {this.props.item.content}
                </p>
                <p className='color-gray fs-m2 mt-1'>{moment(this.props.item.created_at).format('HH:mm')}</p>
              </>
          }
        </div>
      </div>
    )
  }
}