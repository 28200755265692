import axios from "axios";
import { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import MarketList from "./market.list";

export default class MarketSearch extends Component {
  
  constructor(props) {
    super(props);
    this.query = new URLSearchParams(this.props.location.search);

    this.state = {
      isLoading: true,
      category: "",
      searchText: "",
      marketItems: [],
      categoryList: [],
    };
  }

  makeOpt(list) {
    const newList = list.map((cate) => {
      return {
        key: cate.uuid,
        value: cate.uuid,
        text: ` ${cate.emoji} ${cate.name} `
      }
    })
    this.setState({categoryList: newList.concat({
      key: 0,
      value: 0,
      text: '전체 카테고리'
    })});
  }

  async getCategory() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/market/category`
      );
      this.setState({
        categoryList: response.data,
      }, () =>  this.makeOpt(response.data));
    } catch (e) {
      console.log(e);
    }
  }

  async getMarketItem() {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/market/item/search/?c=${this.state.category}&?st=${this.searchText}`
    );
    console.log('getMarketItem', res.data)
    this.setState({
      categoryEmoji: res.data.emoji,

      categoryName: res.data.name,

      marketItems: this.state.category ? res.data.items : res.data,
    });
  }

  searchItems(category, searchText) {

    if(category, searchText) {
      
    }
    console.log('hmm.', category)
    this.setState({isLoading: true}, () =>
    {
      this.setState(
        {
          category: category,
          searchText: searchText,
        },
        () => {
          this.getMarketItem();
        }
      );
      
      this.setState({isLoading:false}) 

    })

  }
  

  componentDidMount() {
    this.getCategory();
    this.searchItems(this.query.get("cate"), this.query.get("search"))
  }

  handleChange = (e, data) => {
    console.log('value', data.value)
    this.searchItems(data.value, '')
  }

  render() {
    return (
      this.state.isLoading ? null :
      <div className="p-container">
        <div style={{ maxWidth: "1400px", margin: "0 auto", padding: "1rem" }}>
          {console.log(this.state)}
          <h2>
            현재 선택한 카테고리:&nbsp;&nbsp;[&nbsp;
            <Dropdown
              inline
              options={this.state.categoryList}
              defaultValue={this.state.category}
              onChange={this.handleChange}
            />
            &nbsp;]
          </h2>
      
          <MarketList
            title={"검색 결과"}
            marketItems={this.state.marketItems}
          />
        </div>
      </div>
    );
  }
}
