import React, {Component} from "react";
import {Modal, Button, Rating, Divider, Icon, TextArea, Input, Form, Loader, Segment} from "semantic-ui-react";
import axios from "axios";

export default class ReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      open: true,
      firstTime: true
    };
  }

  async componentDidMount() {
    console.log('review 불러오기')
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/market/review/previous`, {withCredentials: true});
      if (res.data) {
        this.setState({open: true, firstTime: false, score: res.data.score, isLoaded: true, title: res.data.title, content: res.data.content})
      } else {
        this.setState({
          firstTime: true,
          open: true,
          isLoaded: true,
        })
      }
    } catch (e) {

    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleSubmit = async () => {
    if (!this.state.isLoaded) return;
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/market/review`, {
        'item': this.props.itemId,
        'score': this.state.score,
        'title': this.state.title,
        'content': this.state.content,
      }, {withCredentials: true});
      alert('리뷰를 작성했습니다!');
      this.setState({open: false})
      window.location.href = `/market/item/${this.props.itemId}/reviews`
    } catch (e) {
      alert("리뷰 작성을 실패했습니다.");
      this.setState({open: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.state.open = true;
  }

  render() {
    return (
      <Modal
        dimmer={'inverted'}
        onClose={() => this.setState({open: false})}
        onOpen={() => this.setState({open: true})}
        open={this.state.open}
      >
        <Modal.Header>
          <div>{this.state.firstTime ? '리뷰 작성하기' : '리뷰 수정하기'}</div>
        </Modal.Header>
        <Modal.Content scrolling inverted>
          {
            this.state.isLoaded ?
              <>
                <div className="fs-1 mb-5 p-item-centered p-item-vcentered">
                  <span className="mr-4">상품 평가하기</span>
                  <Rating maxRating={5} clearable icon="star" size="massive"
                          defaultRating={this.state.score}
                          onRate={(e, {rating}) => this.setState({score: rating})}/>
                </div>
                <Form>
                  <Form.TextArea name='title' onChange={this.handleChange} fluid style={{resize: 'none'}} rows='1'
                                 placeholder={'제목을 입력해주세요.'} value={this.state.title}/>
                  <Form.TextArea name='content' onChange={this.handleChange} fluid style={{resize: 'none'}} rows='20'
                                 placeholder={'내용을 입력해주세요.'} value={this.state.content}/>
                </Form>
              </>
              : <Loader active inline='centered'/>
          }
        </Modal.Content>
        <Modal.Actions>

          <Button primary onClick={this.handleSubmit}>
            <Icon name="checkmark"/> 등록
          </Button>

          <Button onClick={() => this.setState({open: false})}>
            <Icon name="remove"/> 취소
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
