import {Component} from "react";
import {Container, Icon} from "semantic-ui-react";
import GroupList from "./widget/group.list";
import YoutubeList from "./widget/YoutubeList";
import axios from "axios";
import MarketList from "../Market/market.list";
import GroupLoadingList from "./widget/group.loading.list";
import VideoLoadingList from "./widget/video.loading.list";
import MarketItemLoadingList from "./widget/market.item.loading.list";

export default class SearchResultPage extends Component {
    constructor(props) {
        super(props);
        this.query = new URLSearchParams(props.location.search)
        this.searchQuery = window.encodeURIComponent(this.query.get('search_query'))

        this.state = {
            closeWords: [],
        }
    }

    async getData() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/search/closeWords?query=${this.searchQuery}`
                , {withCredentials: true});

            this.setState({
                closeWords: res.data
            })
        } catch (e) {
            console.log(e)
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {

        return (
            <div className='p-container'>
                <Container>
                    <h1>"{window.decodeURIComponent(this.searchQuery)}"에 대한 검색 결과</h1>
                    {
                        this.state.closeWords.length > 0 &&
                        <p className={'color-gray fs-1'}>이런 검색어는 어떠세요?ㅤ
                            {this.state.closeWords.map(item => {
                                return <a href={`/search/?search_query=${window.encodeURIComponent(item.name)}`}
                                          className={'color-main'}>{item.name}ㅤ</a>
                            })}
                        </p>
                    }

                    <h2>관련 기업</h2>
                    <div>
                        <GroupLoadingList url={`${process.env.REACT_APP_API_URL}/search/group?query=${this.searchQuery}&`} take={6} moreBtn/>
                    </div>

                    <h2>IR 영상</h2>
                    <div>
                        <VideoLoadingList url={`${process.env.REACT_APP_API_URL}/search/video?query=${this.searchQuery}&`} take={6} moreBtn/>
                    </div>

                    <h2>마켓 상품</h2>
                    <div>
                        <MarketItemLoadingList url={`${process.env.REACT_APP_API_URL}/search/market?query=${this.searchQuery}&`} take={6} moreBtn/>
                    </div>

                </Container>
            </div>
        )
    }
}