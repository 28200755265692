import {Component} from "react";
import {Icon} from 'semantic-ui-react'
import MediaQuery from "react-responsive/src";
import RoundProfile from "../utills/profile";
import axios from "axios";

export default class SubsChannelItem extends Component {
  state = {isSubscribed: true}

  subscribe = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, {channelId: this.props.channel.uuid}, {withCredentials: true}).then((res) => {
      console.log(res);
      if (res.data === "Subscribed") {
        this.setState({
          isSubscribed: true
        })
      } else {
        this.setState({
          isSubscribed: false
        })
      }
    }).catch((e) => {
      alert(e);
    })
  }

  render() {
    return (
      <div className='p-card pd-1'>
        <MediaQuery maxWidth={767}>
          <div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
              <RoundProfile slug={this.props.channel.slug} size={80} src={this.props.channel.profile_url}/>
              <p className='has-text-centered fw-n fs-2 mt-2 mb-0 text-ellipsis'>{this.props.channel.name}</p>
              <span className='fs-0 color-gray'><Icon name='linkify'/>{this.props.channel.slug}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem'}}>
              <div className='p-subscribe-btn fw-b' style={{
                flexShrink: 0,
                backgroundColor: this.state.isSubscribed ? '#eeeeee' : '#00a4e5',
                color: this.state.isSubscribed ? '#005386' : '#fff',
              }} onClick={this.subscribe}>
                <Icon name='youtube play'/> {this.state.isSubscribed ? '구독 중' : '구독하기'}
              </div>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={767}>
          <div style={{display: 'flex', padding: '1rem'}}>
            <div style={{flexShrink: 0}}>
              <RoundProfile slug={this.props.channel.slug} size={80} src={this.props.channel.profile_url}/>
            </div>
            <div style={{paddingLeft: '1.5rem'}}>
              <p className='fw-n fs-2 mb-2 text-ellipsis'>{this.props.channel.name}</p>
              <span className='fs-0 color-gray'><Icon name='linkify'/>{this.props.channel.slug}</span>
              <p className='mt-4 text-ellipsis2L' style={{fontSize: '1.1em'}}>{this.props.channel.intro}</p>
            </div>
            <div style={{flexShrink: 0, marginLeft: 'auto', alignSelf: 'center', paddingLeft: '1.5rem'}}>
              <div className='p-subscribe-btn fw-b' style={{
                backgroundColor: this.state.isSubscribed ? '#eeeeee' : '#00a4e5',
                color: this.state.isSubscribed ? '#005386' : '#fff',
              }} onClick={this.subscribe}>
                <Icon name='youtube play'/>{this.state.isSubscribed ? '구독 중' : '구독하기'}
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

