import React, {Component} from "react";
import {Icon} from "semantic-ui-react";
import RoundProfile from "../utills/profile";

export default class PartnerCard extends Component {

  // gitple = () => {
  //   window.GitpleConfig = {
  //     appCode: this.props.partner.gitple_id
  //   }
  //   window.Gitple('update');
  //   window.Gitple('open')
  // }

  openChat = () => {
    window.open(`/chat/group/${this.props.partner.group_uuid}`, 'target', 'top=100, left=100, width=500, height=600, toolbar=no, menubar=no, location=no, status=no, scrollbars=no, resizable=no');
  }

  render() {
    return (
      <div>
        <div className='p-card pd-1'>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{flexBasis: '80px', flexShrink: 0}}>
              <a href={this.props.partner.website_url ?? '#'}>
                <RoundProfile size={80} src={this.props.partner.profile_img_url}/>
              </a>
            </div>
            <div className='ml-5'>
              <p className='fs-1 mb-1' style={{color: '#000000DE'}}>{this.props.partner.name}</p>
              <p className='color-gray'>{this.props.partner.description}</p>
            </div>
            {
              this.props.partner.group_uuid ?
                <div className='pl-5' style={{marginLeft: 'auto', flexShrink: 0}}>
                  <div className='p-chat-btn' onClick={this.openChat}><Icon name='commenting'/></div>
                </div>
                : null
            }
          </div>
        </div>
      </div>
    );
  }
}