import { Component } from "react";
import {
  Progress,
  Grid,
  Statistic,
  Rating,
} from "semantic-ui-react";

export default class RatingForm extends Component {
    render() {
      console.log(this.props.star);
      console.log(this.props.total);
        return (
            <div
            className="is-row p-item-vcentered pv-3"
            style={{ justifyContent: "space-around" }}
          >
            <Statistic>
              <Statistic.Value
                style={{ fontWeight: "900", paddingTop: "1.5rem" }}
              >
                {this.props.score ? this.props.score.toFixed(1) : '0.0'}
              </Statistic.Value>
              <Statistic.Label
                style={{
                  color: "gray",
                  fontWeight: "normal",
                  fontSize: "1.1em",
                }}
              >
                (최고 5점)
              </Statistic.Label>
            </Statistic>

            <div className="half-w color-main">
              <Grid columns="equal" verticalAlign="middle">
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ maxWidth: "100px" }}>
                    <Rating defaultRating={5} maxRating={5} disabled />
                  </Grid.Column>
                  <Grid.Column>
                    <Progress
                      className="mg-0"
                      style={{}}
                      value={this.props.star[4]}
                      total={this.props.total}
                      color="blue"
                      size="tiny"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ maxWidth: "100px" }}>
                    <Rating defaultRating={4} maxRating={5} disabled />
                  </Grid.Column>
                  <Grid.Column>
                    <Progress
                      className="mg-0"
                      style={{}}
                      value={this.props.star[3]}
                      total={this.props.total}
                      color="blue"
                      size="tiny"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ maxWidth: "100px" }}>
                    <Rating defaultRating={3} maxRating={5} disabled />
                  </Grid.Column>
                  <Grid.Column>
                    <Progress
                      className="mg-0"
                      style={{}}
                      value={this.props.star[2]}
                      total={this.props.total}
                      color="blue"
                      size="tiny"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ maxWidth: "100px" }}>
                    <Rating defaultRating={2} maxRating={5} disabled />
                  </Grid.Column>
                  <Grid.Column>
                    <Progress
                      className="mg-0"
                      style={{}}
                      value={this.props.star[1]}
                      total={this.props.total}
                      color="blue"
                      size="tiny"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ maxWidth: "100px" }}>
                    <Rating defaultRating={1} maxRating={5} disabled />
                  </Grid.Column>
                  <Grid.Column>
                    <Progress
                      className="mg-0"
                      style={{}}
                      value={this.props.star[0]}
                      total={this.props.total}
                      color="blue"
                      size="tiny"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        );
    }
}