import {Component} from "react";
import {Icon} from 'semantic-ui-react'
import RoundProfile from "../utills/profile";
import axios from "axios";

export default class VideoExplain extends Component {
  constructor(props) {
    super(props)
    this.state = {isSubscribed: false, subscribers: this.props.channel.subscriber_num}
  }

  async componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}/subscribe/channel/${this.props.channel.uuid}`, {withCredentials: true}).then((res) => {
      console.log(res.data);
      if (res.data) this.setState({isSubscribed: true});
    });
  }

  subscribe = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/subscribe`, {channelId: this.props.channel.uuid}, {withCredentials: true}).then((res) => {
      console.log(res);
      if (res.data === "Subscribed") {
        this.setState({
          isSubscribed: true,
          subscribers: this.state.subscribers + 1
        })
      } else {
        this.setState({
          isSubscribed: false,
          subscribers: this.state.subscribers - 1
        })
      }
    }).catch((e) => {
      console.log(e);
      alert("로그인 후 이용 가능합니다.");
    })
  }

  render() {
    return (
      <div className='p-card pd-1 mv-6'>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <RoundProfile slug={this.props.channel.slug} size={60} src={this.props.channel.profile_url}/>
              <div className='pl-5'>
                <p className='fw-b fs-2 mb-0'>{this.props.channel.name}</p>
                <p className='color-gray'>{this.state.subscribers}명 구독 중</p>
              </div>
            </div>
          </div>

          <div className='p-subscribe-btn fw-b' style={{
            flexShrink: 0,
            backgroundColor: this.state.isSubscribed ? '#eeeeee' : '#00a4e5',
            color: this.state.isSubscribed ? '#005386' : '#fff',
          }} onClick={this.subscribe}>
            <Icon name='youtube play'/> {this.state.isSubscribed ? '구독 중' : '구독하기'}
          </div>
        </div>
      </div>
    );
  }
}