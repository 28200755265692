import React, {Component} from "react";
import {Grid, Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import axios from "axios";
import UserContext from "../UserContext";

export default class InfoBizdayCards extends Component {
  static contextType = UserContext;

  state = {bizGroup: []}

  async componentDidMount() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/bizday/group`, {withCredentials: true});
    this.setState({
      bizGroup: res.data
    });
  }

  render() {
    const user = this.context;

    return (
      <Grid doubling columns={4}>
        {
          this.state.bizGroup.map((biz, idx) => {
            console.log(biz);

          return <Grid.Column key={idx}>
              <Link to={`/bizday/${biz.slug}`}>
                <div className='p-card hover-zoom'>
                  <div className='pd-1'>
                    <p style={{fontWeight: 'bold', fontSize: '1.3em', color: '#000000DE'}}>{biz.name} <span
                      style={{color: '#005386', float: 'right'}}><Icon
                      name='arrow circle right'/></span></p>
                  </div>
                  <div className='p-box-1by1'>
                    <div className='p-box-ch is-flex is-vcentered'>
                      <div className='pd-1' style={{width: '100%'}}>
                        <img style={{width: '100%', maxHeight: '300px'}} src={biz.thumbnail_url}/>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Grid.Column>
          }
        )}
      </Grid>
    );
  }
}