import React, {Component} from "react";
import {Container, Grid} from 'semantic-ui-react'
import CategoryItem from "../Market/category.item";
import axios from "axios";

class CategoryListPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: []
    }
  }

  async componentDidMount() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/market/category`);
      this.setState({
        categories: response.data
      })
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className='p-container'>
        <Container className='pv-7'>
          <h1 className='has-text-centered mb-7'> 모든 카테고리 </h1>

          <Grid stackable doubling columns={2}>
            {
              this.state.categories.map((category, idx) =>
                <Grid.Column key={idx}>
                  <Grid.Column>
                    <CategoryItem emoji={category.emoji} cate={category.name}/>
                  </Grid.Column>
                </Grid.Column>
              )
            }
          </Grid>
        </Container>
      </div>
    );
  }
}

export default CategoryListPage;
