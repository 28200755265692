import React, {Component} from "react";
import {Accordion, Button, Divider, Form, Icon, Label, Modal} from "semantic-ui-react";
import RoundProfile from "../utills/profile";
import {Viewer} from "@toast-ui/react-editor";

export default class RequestNeedsCard extends Component {
  state = {open: false}
  open = () => this.setState({open: true})
  close = () => this.setState({open: false})

  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    return (
      <Modal
        onClose={() => this.close()}
        onOpen={() => this.open()}
        open={this.state.open}
        trigger={
          <div className='p-card pv-5 ph-6 hover-zoom pointer'>
            <p className='fs-1 fw-b color-black'>{this.props.venture_needs.title}</p>
            <div className='is-flex is-vcentered' style={{justifyContent: "flex-end"}}>
              <p className='color-black mb-0 mr-5 fw-b'>{this.props.venture_needs.group.name}</p>
              <RoundProfile src={this.props.venture_needs.group.profile_url} size={35}/>
            </div>

            <Divider horizontal className='mv-6'><span className={`${this.props.is_matched ? "color-main" : "color-gray"}`}>{this.props.partner_opinion !== null ? <Icon name='arrow alternate circle up'/> : ''} {this.props.is_matched ? 'MATCHED' : 'REQUESTED'} {this.props.venture_opinion !== null ? <Icon name='arrow alternate circle down'/> : ''}</span></Divider>

            <p className='fs-1 fw-b color-black'>{this.props.partner_needs.title}</p>
            <div className='is-flex is-vcentered' style={{justifyContent: "flex-end"}}>
              <p className='color-black mb-0 mr-5 fw-b'>{this.props.partner_needs.group.name}</p>
              <RoundProfile src={this.props.partner_needs.group.profile_url} size={35}/>
            </div>
          </div>
        }
      >
        <Modal.Header>요청 내용</Modal.Header>
        <Modal.Content>
            <Form>
              <div className='is-flex is-vcentered'>
                <RoundProfile src={this.props.venture_needs.group.profile_url} size={35}/>
                <p className='color-black mb-0 ml-5 fw-b'>{this.props.venture_needs.group.name}</p>
              </div>
              {
                this.props.venture_opinion ? <p className='mt-0 pd-1' style={{border: '1px solid #eaeaea', borderRadius: '10px', marginLeft: '45px'}}>{this.props.venture_opinion}</p> : null
              }
              <div className='mt-5'>
                <Label className='mb-1'>{this.props.venture_needs.venture_type}</Label>
                <Label className='mb-1'>{this.props.venture_needs.invest_phase}</Label>
                <Label className='mb-1'>{this.props.venture_needs.category_1}</Label>
                <Label className='mb-1'>{this.props.venture_needs.category_2}</Label>
              </div>
              <Accordion className='mt-5'>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  <span className='fs-1 fw-b'>{this.props.venture_needs.title}</span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <Viewer
                    referenceDefinition={true}
                    initialValue={this.props.venture_needs.description}
                  />
                  <h3>첨부 파일</h3>
                  {
                    this.props.venture_needs.attachments && this.props.venture_needs.attachments.map((file) =>
                      <div className='mt-5'>
                        <a href={file} className='color-main'><Icon name='file'/> {window.decodeURIComponent(file.split('/')[file.split('/').length - 1])}</a>
                      </div>
                    )
                  }
                </Accordion.Content>
              </Accordion>

              <Divider horizontal><span className={`${this.props.is_matched ? "color-main" : "color-gray"}`}>{this.props.partner_opinion !== null ? <Icon name='arrow alternate circle up'/> : ''} {this.props.is_matched ? 'MATCHED' : 'REQUESTED'} {this.props.venture_opinion !== null ? <Icon name='arrow alternate circle down'/> : ''}</span></Divider>

              <div className='is-flex is-vcentered'>
                <RoundProfile src={this.props.partner_needs.group.profile_url} size={35}/>
                <p className='color-black mb-0 ml-5 fw-b'>{this.props.partner_needs.group.name}</p>
              </div>
              {
                this.props.partner_opinion ? <p className='mt-0 pd-1' style={{border: '1px solid #eaeaea', borderRadius: '10px', marginLeft: '45px'}}>{this.props.partner_opinion}</p> : null
              }
              <div className='mt-5'>
                <Label className='mb-1'>{this.props.partner_needs.category_1}</Label>
                <Label className='mb-1'>{this.props.partner_needs.category_2}</Label>
              </div>

              <Accordion className='mt-5'>
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  <span className='fs-1 fw-b'>{this.props.partner_needs.title}</span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <Viewer
                    referenceDefinition={true}
                    initialValue={this.props.partner_needs.description}
                  />
                  <h3>첨부 파일</h3>
                  {
                    this.props.partner_needs.attachments && this.props.partner_needs.attachments.map((file) =>
                      <div className='mt-5'>
                        <a href={file} className='color-main'><Icon name='file'/> {window.decodeURIComponent(file.split('/')[file.split('/').length - 1])}</a>
                      </div>
                    )
                  }
                </Accordion.Content>
              </Accordion>
            </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.close()}>
            확인
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}