import {Button, Container, Grid, Icon, Input, Modal} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import VideoArchiveCard from "../VideoInfo/video.archive.card";
import SectionTitle from "../Info/SectionTitle";

function VideoLibrary() {
    const [archiveList, setArchiveList] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [archiveName, setArchiveName] = React.useState('')

    useEffect(() => {
        getData().then(()=> setIsLoaded(true));
    }, [])

    const getData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/archive`, {withCredentials: true});
        setArchiveList(res.data);
    }

    const postArchive = async () => {
        if (archiveName.length < 1) {
            alert('보관함 이름을 작성해주세요.')
            return;
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/archive`, {
            name: archiveName,
        }, {withCredentials: true});

        await getData()
        setOpenModal(false)
    }
    const archiveNameChange = (e, data) => {
        setArchiveName(data.value)
    }


    return (
      <div className='p-container'>
          <Modal
            onClose={() => setOpenModal(false)}
            open={openModal}
            size='mini'
          >
              <Modal.Header>새로운 보관함 생성하기</Modal.Header>
              <Modal.Content>
                  <Input className={'full-w'} placeholder={'보관함 이름을 입력해주세요.'} onChange={archiveNameChange}/>
              </Modal.Content>
              <Modal.Actions>
                  <Button
                    primary
                    icon='check'
                    content='생성'
                    onClick={postArchive}
                  />
                  <Button
                    content='취소'
                    onClick={() => setOpenModal(false)}
                  />
              </Modal.Actions>
          </Modal>

          <Container>
              <h1><Icon className='mr-5' name='th list'/>영상 보관함</h1>
              <p className='color-gray mb-7'>보관함을 만들고, 흥미로운 영상들을 보관하세요!</p>
              <div>
                  {isLoaded &&
                  <div className='auto-grid'>
                      {archiveList.map(item => (
                        <Grid.Column>
                            <div>
                                <VideoArchiveCard archive={item}/>
                            </div>
                        </Grid.Column>
                      ))}
                      <Grid.Column>
                          <div className={'pointer hover-zoom'} onClick={() => setOpenModal(true)}>
                              <div className={'p-round-big bg-lgray p-box-16by9'}>
                                  <div className={'p-box-ch has-item-centered '}>
                                      <Icon inverted name={'add'} size={'huge'} color={'white'}/>
                                  </div>
                              </div>
                          </div>
                      </Grid.Column>
                  </div>
                  }
              </div>
          </Container>
      </div>
    );
}

export default VideoLibrary