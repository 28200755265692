import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import './utills/utill.css';
import './App.css'
import MainPage from "./Pages/MainPage"
import VideoPage from "./Pages/VideoDetailPage";
import BizdayPageOld from "./bizday/pages/bizday.page.old";
import InfoPage from "./Pages/InfoPage";
import ChannelPage from "./channel/channel.page"
import SubscribePage from "./Pages/subscribe.page";
import MarketPage from "./Pages/MarketPage";
import BizdayDetailPage from "./bizday/pages/bizday.detail.page";
import CreateNeedsPage from "./bizday/pages/create.needs.page";
import InfoIncubatorPage from "./Info/pages/info.incubator.page";
import ProgramPage from "./Info/pages/program.page";
import CategoryListPage from "./Pages/CategoryListPage";
import Navbar from "./navbar/navbar";
import Sidebar from "./navbar/sidebar";
import MobileSidebar from "./navbar/mobile.sidebar";
import OfficePage from "./Info/pages/office.page";
import InfoMarketPage from "./Info/pages/info.market.page";
import InfoInvestmentPage from "./Info/pages/info.investment.page";
import InfoNetworkPage from "./Info/pages/info.network.page";
import CitizenPage from "./Info/pages/citizen.page";
import ScrollToTop from "./utills/scroll.to.top";
import CitizenContentPage from "./Info/pages/citizen.content.page";
import {MobileProgramViewer} from "./mobile/program.viewer";
import MorePage from './Pages/MorePage';
import VideoLibrary from './Pages/VideoLibrary';
import VideoHistory from './Pages/VideoHistory';
import VideoLikes from './Pages/VideoLikes';
import ProfilePage from "./account/profile.page";
import SignupPage from "./account/signup.page";
import ReviewPage from './Market/review.page';
import Chat from "./chat/chat";
import MarketItemDetail from "./Market/market-item-detail";
import ChatListPage from "./chat/chat.list.page";
import UserContext from "./UserContext";
import axios from "axios";
import MarketSearch from './Market/market.search';
import BizdayListPage from "./bizday/pages/bizday.list.page";
import BizdayPage from "./bizday/pages/bizday.page";
import CommunityPostDetail from './channel/community.post.detail';
import InvestModule from "./investment/invest.module";
import ActivityDetailPage from './activity/pages/activity.detail.page';
import SearchResultPage from './Main/search.result'
import KeywordPage from "./Main/keyword";
import NotificationPage from "./notification/notification.page";
import PolicyPage from "./More/policy.page";
import ArchiveDetail from "./VideoInfo/archive.detail";
import SearchGroupPage from "./Pages/search.group.page";
import SignupVenturePage from "./account/signup-venture.page";

class App extends Component {
  state = {user: null}

  async componentDidMount() {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/verifyToken`, {withCredentials: true});
      const user = res.data;
      this.setState({
        user: user
      });
    } catch (err) {
      // 로그인 안 됨
    }
  }

  render() {

    return (
      <UserContext.Provider value={this.state.user}>
        <div>
          <Switch>
            <Route exact path='/m/:theme/program/:id' component={MobileProgramViewer}/>
            <Route exact path='/m/signup' component={SignupPage}/>
            <Route exact path='/m/policy' component={PolicyPage}/>
            <Route exact path='/chat/:type/:id' component={Chat}/>
            <Route exact path='*'>
              <Navbar/>
              <Sidebar/>
              <div className='app-container'>
                <ScrollToTop>
                  <Switch>
                    <Route exact path="/" component={MainPage}/>
                    <Route path="/detail" component={VideoPage}/>
                    <Route exact path="/search/" component={SearchResultPage}/>
                    <Route path="/keyword/" component={KeywordPage}/>

                    <Route exact path="/group/search" component={SearchGroupPage}/>

                    <Route exact path="/bizday/:slug" component={BizdayListPage}/>
                    <Route exact path="/bizday/:slug/:bizId" component={BizdayPage}/>
                    <Route exact path="/bizday/:slug/:bizId/needs" component={CreateNeedsPage}/>
                    <Route exact path="/bizday/matched/detail/:matchId" component={BizdayDetailPage}/>
                    <Route path={"/investment"} component={InvestModule}/>
                    <Route exact path="/info" component={InfoPage}/>
                    <Route exact path="/info/incubator" component={InfoIncubatorPage}/>
                    <Route exact path="/info/market" component={InfoMarketPage}/>
                    <Route exact path="/info/investment" component={InfoInvestmentPage}/>
                    <Route exact path="/info/network" component={InfoNetworkPage}/>
                    <Route exact path={"/program/detail/:id"} component={ProgramPage}/>
                    <Route exact path={"/program/office/:id"} component={OfficePage}/>
                    <Route exact path="/info/citizen" component={CitizenPage}/>
                    <Route exact path="/info/citizen/:id" component={CitizenContentPage}/>
                    <Route exact path="/info/activity/:id" component={ActivityDetailPage}/>

                    <Route exact path="/channel/:slug" component={ChannelPage}/>
                    <Route exact path="/channel/post/:id" component={CommunityPostDetail}/>

                    <Route path="/subscribe" component={SubscribePage}/>
                    <Route exact path="/market" component={MarketPage}/>
                    <Route path="/market/category" component={CategoryListPage}/>
                    <Route exact path="/market/search/" component={MarketSearch}/>
                    <Route exact path="/market/item/:id" component={MarketItemDetail}/>
                    <Route exact path="/market/item/:id/reviews" component={ReviewPage}/>

                    <Route exact path="/more" component={MorePage}/>

                    <Route exact path="/video/library" component={VideoLibrary}/>
                    <Route exact path="/video/library/:id" component={ArchiveDetail}/>

                    <Route path="/video/history" component={VideoHistory}/>
                    <Route path="/video/likes" component={VideoLikes}/>
                    <Route exact path="/notification" component={NotificationPage}/>
                    <Route exact path="/account/profile" component={ProfilePage}/>
                    <Route exact path='/signup' component={SignupPage}/>
                    <Route exact path='/policy' component={PolicyPage}/>

                    <Route exact path="/chat" component={ChatListPage}/>
                    {/* <Route path="/users" component={NotFound} /> */}
                  </Switch>
                </ScrollToTop>
              </div>
              <MobileSidebar/>
            </Route>
          </Switch>
        </div>
      </UserContext.Provider>
    )
  }
}

export default App;