import { Grid } from "semantic-ui-react";
import { Component } from "react";
import CategoryItem from "./category.item";
import axios from "axios";
import { Link } from "react-router-dom";

export default class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/market/category`
      );
      this.setState({
        categories: response.data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div className="mb-5">
        <h1 className="mb-7"> 인기 카테고리 </h1>

        <Grid doubling columns={7}>
          {this.state.categories.map((category, idx) => (
            <Grid.Column key={idx}>
              <Grid.Column>
                <Link to={`market/search/?cate=${category.uuid}`}  className="color-black" >
                  <CategoryItem emoji={category.emoji} cate={category.name} />
                </Link>
              </Grid.Column>
            </Grid.Column>
          ))}
        </Grid>

        <div className="color-main has-text-right fs-1 pv-6 pr-3">
          <a
            className="color-main ph-3 pv-2 hover-gray p-round"
            href="/market/category"
          >
            모든 카테고리 보기
          </a>
        </div>
      </div>
    );
  }
}
